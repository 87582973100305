import React from "react";
import Slider from "react-slick";
import "./Slider.css";
import "./Slider-theme.css";

function PropertySlide({ slideData }) {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    prev: true,
    next: true,
    speed: 600,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="mb-10 mt-10 h-80">
      <Slider {...settings}>
        {slideData.map((image, index) => (
          <div key={index} className="bg-bgColor-400">
            <div className="max-w-xl mx-auto h-86 h-80 flex items-center justify-center">
              <img className="w-full h-full object-cover" src={image} alt={`slide ${index}`} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default PropertySlide;
