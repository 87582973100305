import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase.config';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import NoTransfer from './Components/NoTranfer';
import { Helmet } from 'react-helmet-async';

const Transfer = () => {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState();
  const [isCryptoSelected, setIsCryptoSelected] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const [transferAmount, setTransferAmount] = useState();
  const [transferAccount, setTransferAccount] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        fetchUserData(user.uid);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const fetchUserData = async (userId) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const data = userDoc.data();
      setTransferAccount(data.balance);
      setBalance(data.profit);
    }
    setLoading(false);
  };

  const handleContinue = async () => {
    if (!isCryptoSelected || balance <= 0) {
      setError('Please ensure you have a positive balance and have selected cryptocurrency.');
      return;
    }
    setProcessing(true);
    await new Promise((resolve) => setTimeout(resolve, 6000));
    setProcessing(false);
    setStep(2);
  };

  const handleStep2Continue = async () => {
    if (Number(transferAmount) <= 0) {
      setError('Transfer amount must be greater than zero.');
      return;
    }
    setProcessing(true);
    await new Promise((resolve) => setTimeout(resolve, 6000));
    setProcessing(false);
    setStep(3);
  };

  const generateTransactionId = () => {
    return `TNX${Math.floor(10000000 + Math.random() * 90000000)}`;
  };

  const handleConfirm = async () => {
    setProcessing(true);

    setTimeout(async () => {
      const transactionId = generateTransactionId();
      const transferTime = new Date();
      const amount = Number(transferAmount);
      const userDetails = {
        transferTime,
        transferAmount: amount,
        details: `Received from Investment Account`,
        transactionId,
        status: 'completed',
        date: new Date()
      };

      try {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const withdrawRef = await addDoc(collection(db, 'users', user.uid, 'transfer'), userDetails);
            await updateDoc(userDocRef, {
                balance: transferAccount + amount,
                profit: balance - amount,
            });
          } else {
            console.error("User is not authenticated.");
          }
        });
      } catch (error) {
        console.error("Error adding document: ", error);
      } finally {
        setProcessing(false);
        setStep(4);
      }
    }, 6000);  // Delay submission by 6 seconds
  };

  if (loading) {
    return <div className='bgh'>Loading...</div>;
  }

  if (balance === 0) {
    return <NoTransfer />;
  }

  return (
    <div className=" bgh max-w-xl mx-auto text-slate-500 p-4">
      <Helmet><title>Transfer</title></Helmet>
      {step === 1 && (
        <>
          <div className='flex justify-center items-center pb-4'>
            <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
          </div>
          <h2 className="text-xl font-bold mb-4">Transfer Funds</h2>
          <p className='font-medium text-lg mb-1'>Select from withdrawal options below</p>
          <p className='text-sm'>Transfer funds from your Investment account to your main Wallet</p>
          <div className='flex justify-between p-2 mt-4 bg-white rounded border-2 items-center'>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="crypto"
                checked={isCryptoSelected}
                onChange={(e) => setIsCryptoSelected(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="crypto">Main Wallet</label>
            </div>
            <div><AccountBalanceWalletIcon /></div>
          </div>
          <div className="mb-4 text-start mt-4 text-slate-400">
            <label className="block text-small mb-2">Transfer From</label>
            <div className="border text-sm rounded p-2 bg-white">
              <p className='font-semibold text-neutral-700'>Investment Account</p>
              <p className=''>Available Balance: <span className='font-medium'>({balance} USD)</span></p>
            </div>
          </div>
          <div>
            <button
              className="bg-green-500 w-full text-white py-2 px-4 rounded"
              onClick={handleContinue}
              disabled={processing}
            >
              {processing ? <CircularProgress size={24} /> : 'Transfer Now'}
            </button>
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            className="text-red-400 py-2 px-4 rounded mt-2"
            onClick={() => navigate('/dashboard')}
          >
            Cancel
          </button>
        </>
      )}

      {step === 2 && (
        <>
          <div className='flex justify-center items-center pb-4'>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
          </div>
          <h2 className="text-xl font-bold mb-4">Transfer Funds from Investment Account</h2>
          <p className='text-sm'>Transfer your funds to your main wallet</p>
          
          <div className="grid grid-cols-1 mt-4 gap-4 text-start">
            <div className="">
              <label className="block mb-2">Withdraw Amount</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={transferAmount}
                onChange={(e) => setTransferAmount(e.target.value)}
              />
              <span className='text-xs'>Profit Balance: ({balance} USD)</span>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Amount to Transfer</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={Number(transferAmount)}
                readOnly
              />
              <span className='text-xs'>1 USD = 1.00 USDT</span>
            </div>
          </div>
          <div className="mb-4 text-start">
            <label className="block mb-2">Description (Optional)</label>
            <input type="text" className="w-full px-2 py-3 border rounded" />
          </div>
          <div>
            <button
              className="bg-green-500 w-full text-white py-2 px-4 rounded mb-2"
              onClick={handleStep2Continue}
              disabled={processing}
            >
              {processing ? <CircularProgress size={24} /> : 'Continue to Withdraw'}
            </button>
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            className=" text-red-500 py-2 px-4 rounded"
            onClick={() => setStep(1)}
          >
            Back to prev page
          </button>
        </>
      )}
      
      {step === 3 && (
        <>
          <div className='flex justify-center items-center pb-4'>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
          </div>
          <h2 className="text-xl font-bold mb-4">Confirm Your Transfer</h2>
          <p className='text-base font-medium'>You are about to transfer {transferAmount} USDT via investment account</p>
          <p className="text-sm mb-4"> Please review the information and confirm.</p>
          <div className="bg-white rounded shadow py-4  mb-4">
            <div className="mb-2 grid grid-cols-3 p-2 border-b-2">
              <label className="block text-xs font-bold text-start">Transfer Account </label>
              <p className="text-sm col-span-2 text-right font-semibold">Main Account</p>
            </div>
            <div className="mb-2 flex justify-between p-2 border-b-2 items-center">
              <label className="block font-semibold">Transfer amount</label>
              <p className="text-sm">{transferAmount} USD</p>
            </div>
            <div className="mb-2 flex justify-between px-2 pt-2 items-center">
              <label className="block ">Equivalent to</label>
              <p className="text-sm font-semibold">{transferAmount} USDT</p>
            </div>
            <div className="mb-2 flex justify-between px-2 border-b-2 items-center">
              <label className="block ">Exchange rate</label>
              <p className="text-sm">1 USD = 1.00 USDT</p>
            </div>
            <div className=" flex justify-between px-2 items-center">
              <label className="block   font-semibold">Total amount to transfer</label>
              <p className="text-sm font-semibold">{transferAmount} USD</p>
            </div>
          </div>
          <div className="mb-2 flex border-2 justify-between bg-white rounded-md mt-2 p-2 items-center">
              <label className="block   font-semibold">Amount transferred to main wallet</label>
              <p className="text-sm  font-semibold">{transferAmount} USDT</p>
            </div>
            <div>
            <button
              className="bg-green-500 w-full text-white py-2 px-4 rounded"
              onClick={handleConfirm}
              disabled={processing}
            >
              {processing ? <CircularProgress size={24} /> : 'Transfer Now'}
            </button>
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            className=" text-red-500 py-2 px-4 rounded"
            onClick={() => setStep(2)}
          >
            Back to prev page
          </button>
        </>
      )}
      {step === 4 && (
        <>
          <div className=" flex p-6 flex-col items-center justify-center">
            <div className='w-20 h-20 rounded-full flex justify-center  bg-green-500 items-center text-white font-bold'><CheckIcon /></div>
            <h2 className="text-xl mt-2 font-bold mb-3">Transfer Successful!</h2>
            <p className="mb-4">Check your dashboard for update</p>
            <button onClick={() => navigate('/dashboard')} className="mt-1 text-sm font-semibold px-6 py-2  text-green-400 hover:text-green-600 ml-2">Go to Dashboard</button>
            <p className="mt-4">Please feel free to contact us if you have any question.</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Transfer;
