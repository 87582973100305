import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ReferralCard = ({ users = [] }) => {
  const navigate = useNavigate();
  const [visibleOptions, setVisibleOptions] = useState(null);

  const getInitials = (name) => {
    if (!name) return ''; // Return an empty string if the name is undefined
    const initials = name.split(' ').map((word) => word[0]).join('');
    return initials.toUpperCase();
  };

  const handleMoreClick = (id) => {
    setVisibleOptions(visibleOptions === id ? null : id);
  };

  const getRandomColor = () => {
    const colors = ['bg-green-500', 'bg-blue-500', 'bg-orange-500', 'bg-yellow-500'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Find users with referrer and create a map of referrers to their referred users
  const referrerMap = users.reduce((map, user) => {
    if (user.referrer) {
      if (!map[user.referrer]) {
        map[user.referrer] = [];
      }
      map[user.referrer].push(user);
    }
    return map;
  }, {});
  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = new Date(timestamp.toDate());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div>
      <div className="overflow-x-auto min-h-screen hide-scrollbar">
        <table className="min-w-full mt-4">
          <thead>
            <tr className="bg-gray-100 border-b border-gray-200">
              <th className="py-2 px-4 border-b whitespace-nowrap">Referrer</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Date of Referred</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Referred User</th>
              
            </tr>
          </thead>
          <tbody>
            {Object.entries(referrerMap).map(([referrerId, referredUsers]) => {
              const referrer = users.find((user) => user.uid === referrerId);
              if (!referrer) {
                console.error(`Referrer not found for id: ${referrerId}`); // Debugging: Log if referrer not found
              }
              return referredUsers.map((user) => (
                <tr key={user.id} className="border-b text-xs border-gray-200">
                  <td className="py-2 px-4 flex items-center whitespace-nowrap">
                    <div className={`${getRandomColor()} w-12 h-12 text-lg font-semibold rounded-full flex items-center justify-center`}>
                      {getInitials(referrer?.name || "Unknown")}
                    </div>
                    <div className="flex flex-col ml-4">
                      <p className="text-sm font-bold">{referrer?.name || "Unknown Referrer"}</p>
                      <p className="text-xs">{referrer?.email || "Unknown Email"}</p>
                    </div>
                  </td>
                 
                  <td className="py-2 px-4 whitespace-nowrap">
                    {formatDate(referrer?.referrals.find(r => r.email === user.email)?.timestamp) || "N/A"}
                  </td>
                  <td className="py-2 px-4 flex items-center whitespace-nowrap">
                    <div className={`${getRandomColor()} w-12 h-12 text-lg font-semibold rounded-full flex items-center justify-center`}>
                      {getInitials(user.name)}
                    </div>
                    <div className="flex flex-col ml-4">
                      <p className="text-sm font-bold">{user.name}</p>
                      <p className="text-xs">{user.email}</p>
                    </div>
                  </td>
                </tr>
              ));
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReferralCard;
