import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import HalfCircleProgressBar from '../User/Components/HalfCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

const InvestmentDetails = () => {
    const { userId, investmentId } = useParams();
    const [investment, setInvestment] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchInvestmentData = async () => {
        const investmentRef = doc(db, 'users', userId, 'investment', investmentId);
        const investmentSnap = await getDoc(investmentRef);
  
        if (investmentSnap.exists()) {
          setInvestment(investmentSnap.data());
        } else {
          console.log('No such document!');
        }
        setLoading(false);
      };
  
      fetchInvestmentData();
    }, [investmentId, navigate, userId]);
  
    const handleCancelInvestment = async () => {
      if (investment.status !== 'running') return;
      
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();
  
      try {
        await updateDoc(doc(db, 'users', userId, 'investment', investmentId), {
          status: 'cancelled',
          'adjustProfit.daysPassed': 0
        });
  
        await updateDoc(userRef, {
          balance: userData.balance + investment.investmentAmount
        });
  
        setInvestment(prevState => ({
          ...prevState,
          status: 'cancelled',
          adjustProfit: {
            ...prevState.adjustProfit,
            daysPassed: 0
          }
        }));
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    };
  
    if (loading) {
      return <p>Loading...</p>;
    }
  
    if (!investment) {
      return (
        <div className='bgh p-6 flex flex-col justify-center items-center'>
          <p>No investment data found.</p>
          <button className='p-3 text-red-500 mt-4' onClick={() => navigate(-1)}>Back to List</button>
        </div>
      );
    }

    const startDate = new Date(investment.termStartAt.seconds * 1000).toLocaleString();
    const endDate = new Date(investment.termEndAt.seconds * 1000).toLocaleString();
    const dailyProfit = investment.investmentAmount * (investment.dailyInterest / 100);
    const totalProfit = dailyProfit * investment.termDays;
    const totalReturn = (investment.investmentAmount + totalProfit).toFixed(2);
    const totalReturnPercentage = ((investment.adjustProfit.daysPassed / investment.adjustProfit.totalDays) * 100).toFixed(0);
  
    const formatDate = (timestamp) => {
      if (!timestamp || !timestamp.toDate) return 'N/A';
      const date = new Date(timestamp.toDate());
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
      <div className="p-3">
        <div className="lg:px-5 p-2">
          <p className="text-start cursor-pointer"><ArrowBackIcon onClick={() => navigate(-1)} /> Investment</p>
          <h3 className="text-3xl text-slate-600 font-normal capitalize mb-2 text-start">{investment.planName} - Daily {investment.dailyInterest}% for {investment.termDays} Days</h3>
          <div className="cancel flex mb-1 text-slate-600">
            <p className="text-sm">{investment.invoiceNo}</p>
            <p className={` text-sm ml-2 ${investment.status === 'running' ? 'text-yellow-400' : 'cancelled' ? 'text-red-500' : 'text-green-500'}`}>
              {investment.status}
            </p>
          </div>
          <div className='flex justify-start mb-5'>
            {investment.status === 'running' && (
              <button className='inline-block text-sm text-red-500' onClick={handleCancelInvestment}>Cancel</button>
            )}
          </div>
        </div>
        <div className="bg-white text-slate-600 lg:px-5 py-3 px-4 rounded-md mb-5 border-2">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex text-start items-center">
              <div className="p-2">
                <p className="text-xl md:text-2xl text-slate-600 font-bold capitalize mb-1 text-start">{investment.investmentAmount.toFixed(2)} <span className="font-light textxl">USD</span></p>
                <p className="text-base">Invested</p>
              </div>
              <AddIcon />
              <div className="p-2">
                <p className="text-xl md:text-2xl text-slate-600 font-bold capitalize mb-1 text-start">{totalProfit.toFixed(2)} <span className="font-light textxl">USD</span></p>
                <p className="text-base">Profit</p>
              </div>
            </div>
            <div className="p-2 mt-1 md:mt-0 lg:mr-14">
              <p className="text-xl md:text-2xl text-slate-600 font-bold capitalize mb-1 text-start">{totalReturn} <span className="font-light textxl">USD</span></p>
              <p className="text-base text-start">Total Return (inc. cap)</p>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 px-2 mt-2">
            <div className="mb-4">
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Term Basis:</p>
                <p>Daily</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Term Duration:</p>
                <p>{investment.termDays} Days</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Term Start:</p>
                <p>{formatDate(investment.orderedDate)}</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Term End:</p>
                <p>{endDate}</p>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Interest (daily)</p>
                <p>{investment.dailyInterest}%</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Total net profit:</p>
                <p>{totalReturn} USD</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Daily profit (inc. cap):</p>
                <p>{dailyProfit.toFixed(2)}</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Adjust profit:</p>
                <p>{investment.adjustProfit.daysPassed} / {investment.adjustProfit.totalDays} Times</p>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Ordered date:</p>
                <p>{formatDate(investment.orderedDate)}</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Payment source</p>
                <p>{investment.paymentSource || 'N/A'}</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Payment reference</p>
                <p>{investment.paymentReference || 'N/A'}</p>
              </div>
              <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
                <p>Paid amount</p>
                <p>{investment.paidAmount || 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-2xl text-slate-500 mb-4 font-semibold">Graph View</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 bg-white rounded p-4">
            <div className='ml-4 md:ml-0 w-full'><HalfCircleProgressBar day={investment.adjustProfit.daysPassed} term={investment.termDays} path="green" header1="Overview" text1={`${totalReturnPercentage}%`} text2={`${investment.dailyInterest}% / per day`} left="0.00 USD" right={`${totalReturn} USD`} /></div>
            <div className='ml-4 md:ml-0 w-full'>
            <HalfCircleProgressBar  day={investment.adjustProfit.daysPassed} term={investment.termDays} path="blue" header1="Net Profit" header2={`Earn so far ${investment.investmentProfit.toFixed(2)} USD`} text1={`${investment.dailyInterest}%`} text2="daily profit" left="0.00 USD" right={`${dailyProfit.toFixed(2)} USD`} />
            </div>
            <div className='ml-4 md:ml-0 w-full'>
            <HalfCircleProgressBar day={investment.adjustProfit.daysPassed} term={investment.termDays} header1="Remain" text1={`${investment.termDays - investment.adjustProfit.daysPassed}`} text2="remain to adjust" left="0 Time" right={`${investment.adjustProfit.totalDays} Times`} header2={`Adjusted so far ${investment.adjustProfit.daysPassed} Times`} />
            </div>
          </div>
        </div>
      </div>
    );
};

export default InvestmentDetails;
