import React from 'react'
import { Container } from 'react-bootstrap'
import Heading from '../Headings/Heading'
import quality from '../../Assets/Datas/qualities'
import SimpleBtn from '../Buttons/SimpleBtn'


const Qualities = () => {
  return (
    <div className='pb-5'>
      <Container>
      <Heading class1='text-center' classname1='text-center' class5='text-center' text5='OUR QUALITIES' text1='Some of Our Qualities' classname2='' classname3='pt-5'/>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
        {
            quality.map((data, index) => (
                <div key={index} className='bg-bgColor-300 flex flex-col justify-center items-center gap-4 py-5 px-3 rounded-md'>
                    <div className='w-16 h-16 bg-accent1 rounded-sm flex justify-center items-center transform rotate-45'>
                        {data.icon}
                    </div>
                    <h3 className='text-2xl font-semibold text-textColor-100'>{data.header}</h3>
                    <p className=''>{data.text}</p>
                </div>
            ))
        }
                <div  className='bg-accent1 flex flex-col justify-center items-center gap-4 py-5 px-3 rounded-md'>
                    <h3 className='text-4xl font-semibold text-textColor-100'>Ready to Start?</h3>
                    <p className='text-base'>Let's get you started</p>
                    <SimpleBtn  text='Create Account' link='/auth/register' classname='text-sm font-medium'/>
                </div>
      </div>
      </Container>
    </div>
  )
}

export default Qualities
