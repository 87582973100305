// src/components/PropertyDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import PropertySlide from '../Components/Slider/PropertySlide';
import MapView from '../Components/MapView';
import estate from '../Assets/Images/housing.jpeg';
import { Container } from 'react-bootstrap';
import { db } from '../firebase.config';
import { Helmet } from 'react-helmet-async';


const PropertyDetail = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      const docRef = doc(db, 'properties', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProperty(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchProperty();
  }, [id]);

  if (!property) {
    return <div>loading....</div>;
  }

  const formatDescription = (description) => {
    const words = description.split(' ');
    let formattedDescription = '';
    let wordCount = 0;
    let periodFound = false;

    words.forEach((word, index) => {
      formattedDescription += word + ' ';
      wordCount++;

      if (word.endsWith('.')) {
        periodFound = true;
      }

      if (wordCount >= 30 && wordCount <= 50 && periodFound) {
        formattedDescription += '<br /> <br />';
        wordCount = 0;
        periodFound = false;
      }
    });

    return formattedDescription;
  };

  return (
    <div className="">
       <Helmet><title>Property</title></Helmet>
      <div className="h-60 mb-5 w-full" style={{ backgroundImage: `url(${estate})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
      <Container className='md:px-10 px-4'>
        <div className="border-l-4 pl-4 py-3 mt-8 border-accent1">
          <h2 className='text-start pb-2 text-4xl text-textColor-100 font-bold'>{property.name}</h2>
        </div>
        <PropertySlide slideData={property.images} />
        <h2 className='text-textColor-100 text-start text-3xl font-semibold '><span className='border-b-4 border-accent1 pb-1 px-1'>Quick</span> Summary</h2>
        <div className="my-10">
          <div className="flex justify-between ">
            <p className="text-lg mb-2"><strong>Location:</strong> </p>
            <p className="text-lg mb-2">{property.location}</p>
          </div>
          <div className="flex justify-between ">
            <p className="text-lg mb-2"><strong>Property Type:</strong> </p>
            <p className="text-lg mb-2">{property.type}</p>
          </div>
          <div className="flex justify-between ">
            <p className="text-lg mb-2"><strong>Status:</strong> </p>
            <p className="text-lg mb-2">{property.status}</p>
          </div>
          <div className="flex justify-between ">
            <p className="text-lg mb-2"><strong>Bedrooms:</strong> </p>
            <p className="text-lg mb-2">{property.beds}</p>
          </div>
          <div className="flex justify-between ">
            <p className="text-lg mb-2"><strong>Baths:</strong> </p>
            <p className="text-lg mb-2">{property.baths}</p>
          </div>
          <div className="flex justify-between ">
            <p className="text-lg mb-2"><strong>Monthly Rent:</strong> </p>
            <p className="text-lg mb-2">{property.rent}</p>
          </div>
          <div className="flex justify-between ">
            <p className="text-lg mb-2"><strong>Purchase Price:</strong> </p>
            <p className="text-lg mb-2">{property.price}</p>
          </div>
          <MapView mapSrc={property.map} />
        </div>
        <h2 className='text-textColor-100 text-start text-3xl font-semibold '><span className='border-b-4 border-accent1 pb-1 px-1'>Property</span> Description</h2>
        <div className="my-10">
          <p className="text-lg mb-2 max-w-2xl text-start" dangerouslySetInnerHTML={{ __html: property.description }}></p>
        </div>
      </Container>
    </div>
  );
};

export default PropertyDetail;
