import React from 'react'
import SimpleBtn from '../Buttons/SimpleBtn'


const ServiceCard = ({header, text, image, link, btnText}) => {
  return (
    <div className='bg-bgColor-300 pb-4'>
                <img src={image} alt="" />
                <h3 className='py-4 font-semibold text-3xl text-textColor-100 capitalize'>{header}</h3>
                <p className='px-4 text-start'>{text}</p>
                <SimpleBtn text={btnText} link={link} classname='block text-accent1 pl-5 pt-4'/>
            </div>
  )
}

export default ServiceCard
