import React, { useState, useEffect, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db, storage } from '../../firebase.config';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

const UpdateProfile = () => {
  const [user, setUser] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setLoading(true);
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
            const data = userDoc.data();
            if (data.dateOfBirth && data.dateOfBirth instanceof Timestamp) {
              data.dateOfBirth = data.dateOfBirth.toDate().toISOString().split('T')[0];
          }
          setProfileData(data);
        }
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSave = async () => {
    if (!user) return;
    setSaving(true);

    let avatarURL = profileData.avatarURL || '';

    if (avatar) {
      const avatarRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(avatarRef, avatar);
      avatarURL = await getDownloadURL(avatarRef);
    }

    const updatedProfileData = {
      ...profileData,
      photoURL: avatarURL,
      name: profileData.name || '',
      email: profileData.email || '',
      phone: profileData.phone || '',
      address: profileData.address || '',
      stateAddress: profileData.stateAddress || '',
      country: profileData.country || '',
      gender: profileData.gender || '',
      dateOfBirth: profileData.dateOfBirth ? Timestamp.fromDate(new Date(profileData.dateOfBirth)) : null,
    };

    try {
      await updateDoc(doc(db, 'users', user.uid), updatedProfileData);
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile: ', error);
      alert('Failed to update profile');
    } finally {
      setSaving(false);
      navigate(-1)
    }
  };

  return (
    <section className='bgh text-slate-500'>
      <Helmet><title>update-profile</title></Helmet>
      <div className='max-w-2xl mx-auto flex mt-4 rounded-xl bg-white justify-center items-center p-2'>
        <div className='py-3 mt-2 self-center w-full'>
            <div className='text-start'>
                <h2 className="text-xl mb-2 font-semibold">Update Profile</h2>
              <div className='grid grid-cols-1 md:grid-cols-2 text-start gap-3'>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>Name</label>
                  <input
                    type='text'
                    className='border p-2 w-full mb-4'
                    value={profileData.name || ''}
                    onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
                  />
                </div>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>Email</label>
                  <input
                    type='email'
                    className='border p-2 w-full mb-4'
                    value={profileData.email || ''}
                    readOnly
                  />
                </div>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>Phone Number</label>
                  <input
                    type='text'
                    className='border p-2 w-full mb-4'
                    value={profileData.phone || ''}
                    onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
                  />
                </div>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>Address</label>
                  <input
                    type='text'
                    className='border p-2 w-full mb-4'
                    value={profileData.address || ''}
                    onChange={(e) => setProfileData({ ...profileData, address: e.target.value })}
                  />
                </div>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>State</label>
                  <input
                    type='text'
                    className='border p-2 w-full mb-4'
                    value={profileData.stateAddress || ''}
                    onChange={(e) => setProfileData({ ...profileData, stateAddress: e.target.value })}
                  />
                </div>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>Country</label>
                  <input
                    type='text'
                    className='border p-2 w-full mb-4'
                    value={profileData.country || ''}
                    onChange={(e) => setProfileData({ ...profileData, country: e.target.value })}
                  />
                </div>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>Gender</label>
                  <select
                    className='border p-2 w-full mb-4'
                    value={profileData.gender || ''}
                    onChange={(e) => setProfileData({ ...profileData, gender: e.target.value })}
                  >
                    <option value=''>Select Gender</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                    <option value='other'>Other</option>
                  </select>
                </div>
                <div className='w-full'>
                  <label className='text-sm font-semibold'>Date of Birth</label>
                  <input
                    type='date'
                    className='border p-2 w-full mb-4'
                    value={profileData.dateOfBirth || ''}
                    onChange={(e) => setProfileData({ ...profileData, dateOfBirth: e.target.value })}
                  />
                </div>
              </div>
              <Button
                
                onClick={handleSave}
                disabled={saving}
                className=' py-2 bg-green-500 mt-2'
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
            </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateProfile;
