import React from 'react'
import SimpleSlider from '../Components/Slider/Slider'
import data from '../Assets/Datas/data'
import About from '../Components/Sections/About'
import Services from '../Components/Sections/Services'
import Plans from '../Components/Sections/Plans'
import Featured from '../Components/Sections/Featured'
import Qualities from '../Components/Sections/Qualities'
import TradingViewWidget from '../Components/Tradeview/TradeView'
import testimonials from '../Assets/Datas/testimonials'
import TestimonialCarousel from '../Components/Sections/Testimonial'
import { Helmet } from 'react-helmet-async'


const Home = () => {
   
  return (
    <>
     <Helmet>
        <title>Home</title>
      </Helmet>
      <SimpleSlider  slideData={data}/>
      <About />
      <Services />
      <Plans />
      <Featured />
      <Qualities />
      <TradingViewWidget />
      <div className="py-7">
      <TestimonialCarousel testimonials={testimonials}/>
      </div>
     
    </>
  )
}
export default Home
