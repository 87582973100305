import React from 'react'
import '../Spinner/Spinner.css'

const Spinner = () => {
  return (
    <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
         <div class="spinner bg-accent1"></div>
      </div>
  )
}

export default Spinner
