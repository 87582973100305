import React from 'react'
import { useNavigate } from 'react-router-dom'
import AddCardIcon from '@mui/icons-material/AddCard';
import { Helmet } from 'react-helmet-async';

const AddWallet = () => {
    const navigate = useNavigate()
  return (
    <div className="bgh max-w-xl text-slate-500 mx-auto">
      <Helmet><title>add-wallet</title></Helmet>
      <div className=" flex p-6 flex-col items-center justify-center">
        <div className='w-20 h-20 rounded-full flex justify-center  bg-blue-400 items-center text-white font-bold'><AddCardIcon style={{}}/></div>
        <h2 className="text-xl mt-2 font-bold mb-3">You're almost ready to withdraw!</h2>
        <p className="">To make a withdraw, please add a withdrawal</p>
        <p className="mb-4">account from your profile (withdrawal accounts).</p>
        <button onClick={() => navigate('/dashboard/user/account/add')} className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md font-bold text-sm hover:bg-green-600">Add Withdrawal Account</button>
        <button onClick={() => navigate('/dashbard')} className="mt-1 text-sm font-semibold px-6 py-2  text-green-400 hover:text-green-600 ml-2">Go to Dashboard</button>
        <p className="mt-4">Please feel free to contact us if you have any question.</p>
      </div>
    </div>
  )
}

export default AddWallet
