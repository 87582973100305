import React from 'react'
import Heading from '../Headings/Heading'
import { Container } from 'react-bootstrap'
import crypto from '../../Assets/Images/crypto.jpeg'
import housing from '../../Assets/Images/housing.jpeg'
import ServiceCard from './ServiceCard'

const Services = () => {
  return (
    <div className='py-4'>
      <Heading class1='text-center' class5='text-center' text5='OUR SERVICES' text1='Best Solutions for Digital' child='Investment and Housing' classname1='text-center' classname2='pt-4'/>
      <Container className="px-2 md:px-10">
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 w-full'>
            <ServiceCard header='Real Estate Investment' text='Real estate has been proven to be one of the best ways to invest and make profit. Apart from leasing property, we also offer our clients the opportunity to invest in real estate projects and gain amazing returns. Such investments are always very lucrative. Real estate is so broad that one can make money out of the simplest sectors in it.' image={housing} link='/services/real-estate' btnText='SEE PROPERTIES &rarr;'/>
            <ServiceCard header='Cryptocurrency Investment' text='Most nations have added cryptocurrency investment to the top 10 list of their economy. We trade at very high volumes to help maximize profit. Investing in cryptocurrency is complex but doable with the right team. We trade and stake cryptocurrencies in large volumes to maximise returns. We use the best method to get best result.' image={crypto} link='/services/cryptocurrency' btnText='READ MORE &rarr;'/>

        </div>
      </Container>
    </div>
  )
}

export default Services
