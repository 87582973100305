import React from 'react'
import slider1 from '../Assets/Images/slider1.jpeg'
import { Container } from 'react-bootstrap'
import Heading from '../Components/Headings/Heading';
import PricingCard from '../Components/PricingCard';
import plansData from '../Assets/Datas/plansData'
import { Helmet } from 'react-helmet-async';

const Pricing = () => {
  return (
    <div>
       <Helmet>
        <title>Pricing</title>
      </Helmet>
      <div className="" >
            <div className="h-full w-full" style={{ backgroundImage: `url(${slider1})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
              <div className="lg:pl-14 h-full w-full  slide-content text-slate-100 bg-overlay">
                <div>
                  <div className="flex justify-center items-center flex-col w-full h-full px-2 py-5">
                    <h2 className="text-2xl pt-10 md:text-4xl font-bold pb-10">Pricing and plan</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Container>
          <Heading text='The broad category of our investment plans and their pricing just goes a long way to show that we have financial solutions for every kind of investor, from the newbie, to the professional, to those who are looking to go long-term in the financial market.' class1='text-center' class5='text-center' text5='PRICING PLANS' text1='Our Investment Plans' classname2='pt-5 ' classname1='text-center' classname3='pt-5 '/>
          
          <div className="grid grid-cols-1 pb-5 lg:grid-cols-2 mx-auto">
          
          {plansData.map((plan, index) => (
        <PricingCard key={index} plan={plan} />
      ))}
            </div>
          </Container>
    </div>
  )
}

export default Pricing
