import React from 'react';
import SimpleBtn from '../Components/Buttons/SimpleBtn'

const PricingCard = ({ plan }) => {
  return (
    <div className={`p-3 text-start rounded-md ${plan.classname}`}>
      <div className='p-3 border-b border-solid'>
        <h3 className='text-2xl text-textColor-100 font-semibold capitalize'>{plan.planName}</h3>
        <p className='text-base capitalize'>{plan.description}</p>
      </div>
      <div className="container mx-auto my-8">
        <h2 className="relative text-2xl font-bold mb-4">
          <span className="absolute text-lg -top-2 -left-3">$</span>
          {plan.entryPrice}
          <span className="text-base absolute font-light mt-1">/ {plan.entryText}</span>
        </h2>
        <table className="min-w-full border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Price</th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">ROI (7 days)</th>
            </tr>
          </thead>
          <tbody>
            {plan.prices.map((row, index) => (
              <tr key={index}>
                <td className="px-6 py-4 border-b border-gray-200">{row.price}</td>
                <td className="px-6 py-4 border-b border-gray-200">{row.roi}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <SimpleBtn text='Choose Plan' classname='bg-accent1 block mt-4 px-4 py-3 rounded-sm' link='/dashboard/user/plans'/>
      </div>
    </div>
  );
}

export default PricingCard
