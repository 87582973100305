import React from 'react'
import Header from '../Components/Header/Header'
import Router from '../Routers/Router'
import TopHeader from '../Components/Header/TopHeader'
import Footer from '../Components/Footer'
import withDelay from '../CustomHooks/withDelay'
import { useLocation } from 'react-router-dom';
import Dashboard from '../User/Dashboard'
import Admindashboard from '../Admin/Admindashboard'





const Layout = () => {

  const location = useLocation()

  return (
    <div className='bg-white'>
      {
        location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/auth') || location.pathname.startsWith('/admin') ? '' :  <TopHeader />
      }

      {
        location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/auth') || location.pathname.startsWith('/admin') ? '' :  <Header />
      }
      {
        location.pathname.startsWith('/dashboard') ? (
          <div className='overflow-hidden'>
            <Dashboard />
          </div>
        ) : (
          location.pathname.startsWith('/admin') ? (
            <div className="overflow-hidden">
              <Admindashboard />
            </div>
          ) : (
            <div className='overflow-hidden'>
              <Router />
            </div>
          )
        )
      }
      
      
      {
        location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/auth') || location.pathname.startsWith('/admin') ? '' :  <Footer />
      }
    
    </div>
  )
}

export default withDelay(Layout)
