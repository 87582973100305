import React from 'react'
import { Container } from 'react-bootstrap'
import crypto from '../Assets/Images/crypto.jpeg'
import slider1 from '../Assets/Images/slider1.jpeg'
import SimpleBtn from '../Components/Buttons/SimpleBtn'
import PricingCard from '../Components/PricingCard'
import plansData from '../Assets/Datas/plansData'
import Heading from '../Components/Headings/Heading'
import { Helmet } from 'react-helmet-async'

const CryptoCurrency = () => {
  return (
    <div>
       <Helmet>
        <title>crypto</title>
      </Helmet>
      <div className="" >
            <div className="h-full w-full" style={{ backgroundImage: `url(${slider1})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
              <div className="lg:pl-14 h-full w-full  slide-content text-slate-100 bg-overlay">
                <div>
                  <div className="flex justify-center items-center flex-col w-full h-full px-2 py-5">
                    <h2 className="text-2xl pt-10 md:text-4xl font-bold pb-10">Cryptocurrency</h2>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <Container className='mt-5 pt-4'>
            <div className="max-w-2xl mx-auto">
                <div className='px-1 '>
                    <img className='w-full object-contain' src={crypto} alt="" />
                </div>
                <h2 className='text-4xl my-3 text-textColor-100 pb-3'>Cryptocurrency Investment</h2>
                <p className='text-base text-start'>
                Most nations have added cryptocurrency investment to the top 10 list of their economy. We trade at very high volumes to help maximize profit. Investing in cryptocurrency is complex but doable with the right team. We trade and stake cryptocurrencies in large volumes to maximise returns. We use the best method to get best result.
                </p> <br />
                <p className='text-base text-start'>
                Cryptocurrency trading has added its own quota to today's economy, we trade at very high volumes to help maximize profit. Cryptocurrencies are digital assets people use as investments and for purchases online. You exchange real currency, like dollars, to purchase “coins” or “tokens” of a given cryptocurrency. Cryptocurrency Transactions includes crypto trading as well as mining. This sector is the future of money and we do well to be part of this wonderful innovation, trading and mining cryptocurrencies.
                </p> <br />
                <p className='text-base text-start'>
                With our investment offer, investors no longer have to deal with all the hurdles, multiple considerations, and uncertainties before entering into the crypto market. At Hillview Housing, we understand the key drivers and market characteristics which are particularly important for succeeding in this space, and we have developed investment strategies in the space.
                </p> <br />
                <p className='text-base text-start pl-2 border-l-4 border-accent1'>
                Cryptocurrencies are exchanged from person to person on the web without a middleman, like a bank or government.
                </p> <br />
                <p className='text-lg text-start font-medium'>Advantages of Cryptocurrency Investments</p>
                <p className='text-base text-start'>
                There are many kinds of cryptocurrencies. Bitcoin is the most famous, but Ether, Bitcoin Cash, Litecoin, and Ripple are a few others.
                </p> <br />
                <p className='text-base border-2 p-2 text-start'>
                &#10004; Cryptocurrencies have been around for a relatively short time, but so far they can be more profitable than most other investments.
                </p>
                <p className='text-base border-2 p-2 text-start'>
                &#10004; With cryptocurrencies, your money is yours only and stays yours forever. You do not rely on financial institutions for holding or transferring it.
                </p>
                <p className='text-base border-2 p-2 text-start'>
                &#10004; One of the primary characteristics of any asset is its liquidity – that is, how easy it is to purchase or sell it at a price close to the market rate. By their very nature, cryptocurrencies have very high liquidity – you can quickly and easily buy and sell them
                </p>
                <p className='text-lg py-2 text-start font-medium'>How our cryptocurrency sector works</p>
                <p className='text-base text-start mb-2'>
                We trade, stake, predict futures and hold tokens in large volumes to maximise profit, after which we spread profit amongst investors at a given ROI.
                </p> <br />
                <SimpleBtn text='Get Started' classname='block px-4 py-2 bg-accent1 font-semibold text-base ' link={`dashboard/user/plans`}/>
            </div>
            <Heading class1='text-center' class5='text-center' text5='PRICING PLANS' text1='Our Investment Plans' classname2='pt-5 ' classname1='text-center' classname3='pt-5 '/>
          <div className='lg:w-2/4 mb-5 mx-auto text-base'>
            <p>The broad category of our investment plans and their pricing just goes a long way to show that we have financial solutions for every kind of investor, from the newbie, to the professional, to those who are looking to go long-term in the financial market.</p>
            </div>
            <div className="grid grid-cols-1 pb-5 lg:grid-cols-2 mx-auto">
          
          {plansData.map((plan, index) => (
             <PricingCard key={index} plan={plan}  />
             ))}
            </div>
        </Container>
    </div>
  )
}

export default CryptoCurrency
