import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import '../Dashboard.css';

const ChartComponent = () => {
    const investment = JSON.parse('{"30 May":0,"31 May":0,"01 Jun":0,"02 Jun":0,"03 Jun":0,"04 Jun":0,"05 Jun":0,"06 Jun":0,"07 Jun":0,"08 Jun":0,"09 Jun":0,"10 Jun":0,"11 Jun":0,"12 Jun":0,"13 Jun":0,"14 Jun":0,"15 Jun":0,"16 Jun":0,"17 Jun":0,"18 Jun":0,"19 Jun":0,"20 Jun":0,"21 Jun":0,"22 Jun":0,"23 Jun":0,"24 Jun":"2659.73","25 Jun":"2659.73","26 Jun":"2659.73","27 Jun":"187734.00","28 Jun":"2954.63","29 Jun":"2954.63"}');
    const profit = JSON.parse('{"30 May":0,"31 May":0,"01 Jun":0,"02 Jun":0,"03 Jun":0,"04 Jun":0,"05 Jun":0,"06 Jun":0,"07 Jun":0,"08 Jun":0,"09 Jun":0,"10 Jun":0,"11 Jun":0,"12 Jun":0,"13 Jun":0,"14 Jun":0,"15 Jun":0,"16 Jun":0,"17 Jun":0,"18 Jun":0,"19 Jun":0,"20 Jun":0,"21 Jun":0,"22 Jun":0,"23 Jun":0,"24 Jun":"279.28","25 Jun":"279.28","26 Jun":"279.28","27 Jun":"84480.3","28 Jun":"310.24","29 Jun":"310.24"}');

    const data = {
        labels: Object.keys(investment).map(label => label.split(' ')[0]), // Display only the day part of the date
        datasets: [
            {
                label: 'Investment',
                data: Object.values(investment).map(value => parseFloat(value) || 0),
                borderColor: '#816bff',
                backgroundColor: 'transparent',
                borderWidth: 2,
                pointRadius: 0, // Remove points
                pointHoverRadius: 0, // Remove hover points
            },
            {
                label: 'Profit',
                data: Object.values(profit).map(value => parseFloat(value) || 0),
                borderColor: '#c4cefe',
                backgroundColor: 'transparent',
                borderWidth: 2,
                pointRadius: 0, // Remove points
                pointHoverRadius: 0, // Remove hover points
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 2, // Reduce legend box width
                },
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                display: false, // Hide x-axis labels
                beginAtZero: true,
            },
            y: {
                display: false, // Hide y-axis labels
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="nk-wgacc-ck lg mb-0" style={{ width: '100%', minWidth: '220px', height: '95px' }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default ChartComponent;
