import React, { useEffect, useRef, useState } from 'react';

const GoogleTranslateWidget = () => {
  const containerRef = useRef(null);
  const scriptRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const handleScriptError = () => {
    console.log('Error loading script');
    // Handle the error here gracefully, e.g., display a message to the user
  };

  useEffect(() => {
    try {
      const container = containerRef.current;
      if (!container) return;
      
      // Define googleTranslateElementInit in the global scope
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          { pageLanguage: 'en' },
          'google_translate_element'
        );
      };

      // Create script element
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;

      script.onload = () => {
        setScriptLoaded(true);
        // Apply CSS to hide "Powered by" text and style the elements inline
        const style = document.createElement('style');
        style.innerHTML = `
          .goog-logo-link {
            display: inline-block !important;
            vertical-align: middle !important;
            padding-right: 5px !important;
          }
          .goog-te-gadget {
            font-size: 0 !important;
            display: inline-flex !important;
            align-items: center !important;
            
          }
          .goog-te-combo {
            background: #fff;
            border: 1px inset #0a1d47;
            padding: 4px 10px;
            border-radius: 4px;
            color: #0a1d47;
            margin-top: 10px!important;
            }
          .goog-te-gadget .goog-te-combo {
            font-size: initial !important;
            vertical-align: middle !important;
          }
        `;
        document.head.appendChild(style);

        return () => {
          document.head.removeChild(style);
        };
      };

      script.addEventListener('error', handleScriptError);

      container.appendChild(script);
      scriptRef.current = script;

      return () => {
        const scriptToRemove = scriptRef.current;
        const containerToRemove = container;

        if (scriptToRemove && containerToRemove && containerToRemove.contains(scriptToRemove)) {
          containerToRemove.removeChild(scriptToRemove);
          scriptRef.current = null;
        }
      };
    } catch (error) {
      console.log(error.message);
      // Handle the error here gracefully, e.g., display a message to the user
    }
  }, []); // Empty dependency array to run this effect only once on mount 

  return (
    <div ref={containerRef}>
      {!scriptLoaded && <div>G-translate</div>}
      {scriptLoaded && <div id="google_translate_element" />}
    </div>
  );
};

export default GoogleTranslateWidget;
