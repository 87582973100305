import React, { useState, useEffect } from 'react';
import estate from '../Assets/Images/housing.jpeg';
import { Container } from 'react-bootstrap';
import Heading from '../Components/Headings/Heading';
import PropertyGrid from '../Components/PropertyGrid';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';
import { Helmet } from 'react-helmet-async';

const RealEstate = () => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      const querySnapshot = await getDocs(collection(db, 'properties'));
      const propertiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProperties(propertiesList);
    };

    fetchProperties();
  }, []);

  return (
    <div>
       <Helmet><title>Real Estate</title></Helmet>
      <div className="">
        <div className="h-full  w-full" style={{ backgroundImage: `url(${estate})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <div className="lg:pl-14 h-full w-full py-10 slide-content text-slate-100 ">
            <div>
              <div className="flex justify-center  items-center flex-col w-full h-full px-2 py-5">
                <h2 className="text-3xl py-36 md:text-5xl font-bold ">Real Estate</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Heading text='We have amazing properties in popular vacation locations across the globe available for long and short-term rent at very good prices.' class1='text-center' class5='text-center' text5='AMAZING PROPERTIES' text1='Our Properties' classname2='pt-5 ' classname1='text-center mb-3' classname3='pt-5 ' />
        <div className="border-l-4 pl-4 py-3 border-accent1">
          <h2 className='text-start pb-2 text-3xl text-textColor-100 font-semibold'>Our Amazing Properties</h2>
          <p className='text-start font-medium text-base'>Properties Grid</p>
        </div>
        <PropertyGrid properties={properties} />
      </Container>
    </div>
  )
}

export default RealEstate;
