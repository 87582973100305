import React from 'react'
import slider1 from '../Assets/Images/slider1.jpeg'
import { Container } from 'react-bootstrap'
import Heading from '../Components/Headings/Heading';
import ContactForm from '../Components/ContactForm';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { grey} from '@mui/material/colors';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  return (
    <div >
       <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="mb-5" >
        <div className="h-full w-full" style={{ backgroundImage: `url(${slider1})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
              <div className="lg:pl-14 h-full w-full  slide-content text-slate-100 bg-overlay">
                <div>
                  <div className="flex justify-center items-center flex-col w-full h-full px-2 py-5">
                <h2 className="text-2xl pt-10 md:text-4xl font-bold pb-10">Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Heading class1='text-center' class5='text-center' text5='CONTACT US' text1='Send Us a Message' classname2=' ' classname1='text-center' classname3='pt-5 '/>
        <div className="grid grid-cols-1 gap-5 md:gap-3 pb-5 px-2 lg:grid-cols-2 mx-auto">
          <div>
          <div className='flex gap-3 items-start justify-start'>
            <div className='w-16 h-16 bg-accent1 rounded-sm flex justify-center items-center'><EmailIcon style={{color: grey[100]}} /></div>
            <div>
              <h3 className='pb-2 text-start font-semibold text-lg text-textColor-100'>Send Email</h3>
              <p className='font-bold text-xl text-start text-textColor-100'>support@hillviewhousing.com</p>
            </div>
          </div>
          <ContactForm />
          </div>
          
          <div className=''>
          <div className='flex gap-3 items-start justify-start mb-12'>
            <div className='w-16 h-16 bg-accent1 rounded-sm flex justify-center items-center'><LocationOnIcon style={{color: grey[100]}} /></div>
            <div>
              <h3 className='pb-2 text-start font-semibold text-lg text-textColor-100'>Map</h3>
              <p className='font-bold text-xl text-start text-textColor-100'>California, United States</p>
            </div>
          </div>
          <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13084119.92598472!2d-129.9405752769447!3d36.8097674094653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sng!4v1718875092762!5m2!1sen!2sng"
      width="600"
      height="300"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
          </div>
          
        </div>
      </Container>
      
    </div>
  )
}

export default Contact
