import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import '../Dashboard.css'

const WalletSelection = () => {
  const [walletSelected, setWalletSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleWalletSelection = (e) => {
    setWalletSelected(e.target.checked);
  };

  const handleContinue = () => {
    if (walletSelected) {
      setIsLoading(true);
      setTimeout(() => {
        navigate('/dashboard/user/deposit/amount', { state: { wallet: 'Crypto Wallet' } });
      }, 2000);
    } else {
      alert('Please select a wallet to continue.');
    }
  };

  return (
    <div className='mx-auto max-w-lg text-slate-600 bgh'>
         <div className='flex justify-center items-center py-4'>
        <div className='text-green-500'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
      </div>
      <h2 className="text-2xl font-bold mb-4">Deposit Funds</h2>
      <p className="mb-1 text-base font-medium">Select from payment options below</p>
      <p className="mb-4 text-sm">Secure and safely deposit money into your account.</p>
      <div className='flex justify-between p-3 bg-white rounded border-2 border-yellow-100 items-center'>
      <div className="flex items-center">
        <input
          type="checkbox"
          id="wallet"
          checked={walletSelected}
          onChange={handleWalletSelection}
          className="mr-2"
        />
        <label htmlFor="wallet" className="cursor-pointer">Crypto Wallet</label>
      </div>
      <div><AccountBalanceWalletIcon /></div>
      </div>
      <button
        className={`w-full py-3 mt-4 px-4 rounded ${walletSelected ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
        onClick={handleContinue}
        disabled={!walletSelected || isLoading}
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </div>
        ) : (
          'Deposit Now'
        )}
      </button>
    </div>
  );
};

export default WalletSelection;
