import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Dashboard.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SyncIcon from '@mui/icons-material/Sync';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const ConfirmationModal = ({ data }) => {
  const [amount, setAmount] = useState(''); // Example amount
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { plan } = location.state || {};

  if (!plan) {
    return <p>No plan selected or amount entered</p>;
  }

  const handleInvestment = () => {
    const investmentAmount = parseFloat(amount);

    if (!amount) {
      setErrorMessage('Please enter an amount.');
    } else if (isNaN(investmentAmount) || investmentAmount <= 0) {
      setErrorMessage('Please enter a valid amount.');
    } else if (investmentAmount < plan.minDeposit) {
      setErrorMessage(`Amount must be at least ${plan.minDeposit}.`);
    } else if (investmentAmount > plan.maxDeposit) {
      setErrorMessage(`Amount must not exceed ${plan.maxDeposit}.`);
    } else if (investmentAmount > data.balance) {
      navigate('/dashboard/user/plans/no-funds'); // Navigate to "No funds in account!" page
    } else {
      const dailyInterest = parseFloat(plan.dailyInterest) / 100;
      const totalProfit = investmentAmount * dailyInterest * plan.termDays;
      const totalReturn = investmentAmount + totalProfit;

      navigate('/dashboard/user/plans/proceed', {
        state: {
          plan,
          investmentAmount,
          totalProfit,
          totalReturn
        }
      });
    }
  };

  return (
    <div className="bdh py-4 bg-bgColor-600 px-2">
      <div className='flex justify-center items-center'>
        <div className='text-green-500'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-3'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
      </div>
      <div className="max-w-lg mx-auto text-center">
        <h2 className="text-3xl text-slate-500 font-semibold mb-4">Invest on {plan.name}</h2>
        <p className='text-sm text-slate-500 text-start font-semibold'>Invested Plan</p>
        <div className="my-2 p-2 rounded bg-white border-2 flex items-center justify-start">
          <div className='text-slate-500'><SyncIcon /></div>
          <div className=' ml-4 '>
            <p className="text-sm text-slate-400 text-start"><strong>{plan.name}</strong></p>
            <p className="text-sm text-slate-400 text-start">Invest for {plan.termDays} days & earn daily {plan.dailyInterest}% as profit</p>
          </div>
        </div>
        <p className='text-sm text-slate-500 text-start mt-4 mb-2 font-semibold'>Enter Your Amount</p>
        <input
          type='text'
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
            setErrorMessage(''); // Clear error message when user types
          }}
          placeholder="Enter Amount"
          className="my-2 p-3 text-slate-400 text-lg border rounded block w-full"
        />
        <div className='flex justify-between mt-1'>
          <p className='text-xs text-slate-500'>Minimum {plan.minDeposit}</p>
          <p className='text-xs text-slate-500'>Maximum {plan.maxDeposit}</p>
        </div>

        {errorMessage && <p className='text-red-500 text-sm mt-2'>{errorMessage}</p>}

        <p className='text-sm mt-3 text-slate-500 text-start font-semibold'>Payment Account</p>
        <div className="my-2 p-2 rounded bg-white border-2 flex items-center justify-start">
          <div className='text-slate-500'><AccountBalanceWalletIcon /></div>
          <div className=' ml-4 '>
            <p className="text-sm text-slate-400 text-start"><strong>Main Balance</strong></p>
            <p className="text-sm text-slate-400 text-start">Current Balance: {data.balance} USD ( 0.00 EUR )</p>
          </div>
        </div>

        <button
          onClick={handleInvestment}
          className="mt-4 py-3 w-full bg-green-500 text-white rounded-md hover:bg-opacity-75"
        >
          Continue to invest
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
