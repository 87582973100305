import React, { useState } from 'react';
// Ensure this path is correct for your project setup
import CallMadeIcon from '@mui/icons-material/CallMade';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useNavigate } from 'react-router-dom';

const Active = ({ activities = [] }) => {
    const activitiess = activities.filter((data) => data.status === 'running' );
    const navigate = useNavigate();
    console.log(activitiess);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(activitiess.length / itemsPerPage);
    const [visibleOptions, setVisibleOptions] = useState(null);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = activitiess.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleMoreClick = (id) => {
        setVisibleOptions(visibleOptions === id ? null : id);
    };

    const formatDate = (timestamp) => {
        if (!timestamp || !timestamp.toDate) return 'N/A';
        const date = new Date(timestamp.toDate());
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <div>
            {activitiess.length === 0 ? (
                <div className="text-center py-4">No investments found</div>
            ) : (
                <>
                    <div className="overflow-auto hide-scrollbar">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b whitespace-nowrap">Plan</th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap">Invest By</th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap">Start Date</th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap">End Date</th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap">Investment ID</th>
                                    <th className="py-2 px-4 border-b whitespace-nowrap">Amount</th>
                                    <th className="py-2 px-4 border-b text-end pl-5 whitespace-nowrap">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((data) => (
                                    <tr key={data.id} className="border-b">
                                        <td className="py-2 px-4 flex items-center whitespace-nowrap">
                                            <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'>
                                                {data.type === 'deposit' ? <CallReceivedIcon /> : data.type === 'withdraw' ? (<div className='text-red-500'><CallMadeIcon /></div>) : (<div className='text-yellow-500'><SyncAltIcon /></div>)}
                                            </div>
                                            <div>
                                                <p className='text-xs font-medium'>{data.planName} - {data.dailyInterest}% for {data.termDays} Days</p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-4 text-xs pt-1 text-gray-500 whitespace-nowrap">{data.userName}</td>
                                        <td className="py-2 px-4 text-xs pt-1 text-gray-500 whitespace-nowrap">{formatDate(data.orderedDate)}</td>
                                        <td className="py-2 px-4 text-xs pt-1 text-gray-500 whitespace-nowrap">{formatDate(data.termEndAt)}</td>
                                        <td className="py-2 px-4 text-xs pt-1 text-gray-500 whitespace-nowrap">{data.invoiceNo}</td>
                                        <td className="py-2 px-4 text-xs pt-1 text-gray-500 whitespace-nowrap"><span>{Number(data.investmentAmount).toFixed(2)} USD</span></td>
                                        <td className="py-2 px-4 whitespace-nowrap">
                                            <div className='flex items-center justify-end relative'>
                                                <div className='mr-3'>
                                                    <p className={`text-xs font-semibold ${data.status === 'ended' ? 'text-green-500' : 'text-yellow-500'}`}>{data.status}</p>
                                                </div>
                                                <NavigateNextIcon onClick={() => navigate(`/admin/dashboard/invest/${data.userId}/investment/${data.id}`)} className='cursor-pointer' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {activitiess.length > itemsPerPage && (
                        <div className='flex justify-center mt-4'>
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className='px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded-md'
                            >
                                Previous
                            </button>
                            {Array.from({ length: Math.ceil(activitiess.length / itemsPerPage) }, (_, index) => (
                                <button
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`px-4 py-2 mx-1 ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-md`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === Math.ceil(activitiess.length / itemsPerPage)}
                                className='px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded-md'
                            >
                                Next
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Active;
