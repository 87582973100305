import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Dashboard.css'

const NoFundsMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="bgh bg-gray-500 flex items-center justify-center ">
      <div className="bg-white p-6 rounded-lg text-center">
        <img src="/path-to-wallet-icon.png" alt="Wallet Icon" className="mx-auto mb-4" />
        <h2 className="text-2xl font-semibold mb-2">No funds in account!</h2>
        <p className="mb-4">We regret that you have no funds in your account. Please make a deposit and try again once funds are available.</p>
        <button onClick={() => navigate('/dashboard/user/deposit/wallet')} className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">Deposit Now</button>
        <button onClick={() => navigate('/dashboard')} className="mt-4 px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 ml-2">Go to Dashboard</button>
        <p className="mt-4">Please feel free to contact us if you face any problem.</p>
      </div>
    </div>
  );
};

export default NoFundsMessage;
