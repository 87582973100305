import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase.config';

const PendingSlip = () => {
  const { depositId } = useParams();
  const [deposit, setDeposit] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvestmentData = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      const userId = user.uid;
      const investmentRef = doc(db, 'users', userId, 'deposit', depositId);
      const investmentSnap = await getDoc(investmentRef);

      if (investmentSnap.exists()) {
        setDeposit(investmentSnap.data());
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    };

    fetchInvestmentData();
  }, [depositId, navigate]);

  const handleCancelOrder = async () => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/login');
      return;
    }

    const userId = user.uid;
    const investmentRef = doc(db, 'users', userId, 'deposit', depositId);

    try {
      await deleteDoc(investmentRef);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!deposit) {
    return <p>No investment data found.</p>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full text-center">
        <h1 className="text-2xl font-semibold mb-4">Make Your Payment</h1>
        <p className="text-gray-700 mb-4">
          Your order <span className="font-semibold">{deposit.transactionId}</span> has been placed successfully. To complete, please send the exact amount of <span className="font-semibold">{deposit.paymentAmount} {deposit.paymentMethod}</span> to the address below.
        </p>
        <div className="border-2 bg-gray-100 rounded-lg p-6 mb-4">
          <h2 className="text-xl font-semibold mb-4">Pay {deposit.cryptoType}</h2>
          <img src={`https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=`} alt="QR Code" className="mx-auto mb-4" />
          <p className="text-2xl font-semibold mb-1">{deposit.paymentAmount} {deposit.paymentMethod}</p>
          <p className="text-sm text-gray-500 mb-4">{(deposit.paymentAmount * 27027.03).toFixed(2)} USD</p> {/* Adjust the conversion rate as needed */}
          <div className="bg-white border rounded-lg p-4 flex justify-between items-center mb-4">
            <span className="text-gray-700">{deposit.paymentMethod} Address {deposit.paymentTo}</span>
            <span className="text-gray-700 font-mono break-all">{deposit.paymentMethod}</span>
          </div>
          <div className="text-left text-xs text-gray-500">
            <p className="mb-1">⚠️ Be aware that this order will be cancelled if you send any other {deposit.cryptoType} amount.</p>
            <p>⚠️ Account will be credited once we receive your payment.</p>
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <button onClick={handleCancelOrder} className="text-red-500 text-sm hover:underline">
            Cancel Order
          </button>
          <button className="text-blue-500 text-sm hover:underline" onClick={() => navigate('/dashboard')}>
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PendingSlip;
