import React from 'react'
import Heading from '../Headings/Heading'
import { Container } from 'react-bootstrap'
import CheckIcon from '@mui/icons-material/Check';
import SimpleBtn from '../Buttons/SimpleBtn'

const About = () => {
  return (
    <div className='py-4'>
      <Container className="px-4 md:px-10">
      <Heading class1='text-start' class5='text-start' text5='ABOUT US' text1='The Best Real Estate and Investment Solution With 10 Years of Experience' classname2='pt-5' classname3='pt-5'/>
      <p className='text-start text-textColor-100 '>Hillview Housing is a real estate and digital investment platform firmly focused on providing affordable housing to everyone and creating stable wealth through level based investments. We are the only firm in the market that provides amazing real estate services alongside profitable investment. Hillview Housing has remained the best agency for digital investment. It was concieved by a group of professionals with enormous experience and wealth of knowledge in digital investments. Our primary aim is creating stable wealth and profit for our investors at all levels.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-4 mt-4 font-bold text-lg">
          <p className="flex items-start "><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> Flexibility</p>
          <p className="flex items-start"><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> Steady Growth</p>
          <p className="flex items-start"><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> 24/7 Support</p>
          <p className="flex items-start"><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> Integrity</p>
        </div>
        <SimpleBtn text='Read More' link={`/about-us`} classname='self-start bg-accent1 px-3 py-2 mt-5 block'/>
      </Container>
    </div>
  )
}

export default About
