import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CircularProgress from '@mui/material/CircularProgress';
import { doc, getDoc, updateDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase.config'; 
import { getAuth } from 'firebase/auth';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';


const ConfirmProceed = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan, investmentAmount, totalProfit, totalReturn } = location.state || {};
  const auth = getAuth();
  const user = auth.currentUser;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Redirect to login if user is not logged in
    }
  }, [user, navigate]);

  if (!plan || !investmentAmount || !totalProfit || !totalReturn) {
    return <p>No investment details available</p>;
  }

  const calculateAdjustProfit = (termEndAt, termDays) => {
    const currentDate = new Date();
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const remainingDays = Math.ceil((termEndAt - currentDate) / millisecondsPerDay);
    const totalDays = termDays;
    const daysPassed = totalDays - remainingDays;
    return { daysPassed, totalDays };
  };

  const handleConfirm = async () => {
    setLoading(true);
    
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentBalance = userData.balance;

        if (currentBalance >= investmentAmount) {
          // Deduct the investment amount from user's balance
          const newBalance = currentBalance - investmentAmount;
          await updateDoc(userRef, { balance: newBalance });

          const termStartAt = new Date();
          const termEndAt = new Date(termStartAt.getTime() + plan.termDays * 24 * 60 * 60 * 1000);
          const randomDigits = Math.floor(Math.random() * 9000000) + 3000000;

          const investmentRef = collection(db, 'users', user.uid, 'investment');
          const investmentData = {
            planName: plan.name,
            termDays: plan.termDays,
            dailyInterest: plan.dailyInterest,
            investmentAmount,
            invoiceNo: `INV-${randomDigits}`,
            totalProfit,
            investmentProfit: 0,
            status: 'running',
            paidAmount: 0,
            paymentReference: 'N/A',
            paymentSource: 'N/A',
            totalReturn,
            termStartAt,
            termEndAt,
            adjustProfit: calculateAdjustProfit(termEndAt, plan.termDays),
            orderedDate: serverTimestamp(),
            timestamp: termStartAt
          };

          await addDoc(investmentRef, investmentData);
          
          toast.success(`Investment Confirmed for ${plan.name}`);
          navigate('/dashboard');
        } else {
          toast.error('Insufficient balance for the investment.');
        }
      } else {
        toast.error('User data not found.');
      }
    } catch (error) {
      
      toast.error('Error processing investment. Please try again later.');
    } finally {
      setLoading(false);
      
    }
  };

  return (
    <div className="py-4 bg-bgColor-600 px-2">
      <Helmet><title>Comfirm-investment</title></Helmet>
      <div className='flex justify-center items-center'>
        <div className=' text-gray-300'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-green-500 ml-3'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
      </div>
      <div className="max-w-lg mx-auto text-center">
        <h2 className="text-3xl text-slate-500 font-semibold mb-4">Confirm Your Investment</h2>
        {error && <p className="text-red-500">{error}</p>}
        <div className="my-2 p-3 rounded bg-white border-2 flex justify-around items-center">
          <div className="text-sm text-slate-500">
            <p>Plan Name</p>
            <p><strong>{plan.name}</strong></p>
          </div>
          <div className="text-sm text-slate-500">
            <p>Duration</p>
            <p><strong>{plan.termDays} Days</strong></p>
          </div>
          <div className="text-sm text-slate-500">
            <p>Daily Profit</p>
            <p><strong>{plan.dailyInterest}%</strong></p>
          </div>
        </div>
        <div className="my-2 rounded bg-white border-2">
          <div className="text-base text-slate-500 flex justify-between px-3 py-2 items-center border-b-2">
            <p>Payment Account:</p>
            <p className='font-medium text-neutral-600'>
              <span className='self-center mr-1'>
                <AccountBalanceWalletIcon style={{ fontSize: 'medium' }} />
              </span>
              Main Balance
            </p>
          </div>
          <div className="text-base text-slate-500 px-3 py-2 border-b-2">
            <div className="flex justify-between mb-1">
              <p>Investment Amount:</p>
              <p className='font-medium text-neutral-600'>{investmentAmount.toFixed(2)} USD</p>
            </div>
            <div className="flex justify-between">
              <p>Total Profit Earn:</p>
              <p className='font-medium text-xs'>{totalProfit.toFixed(2)} USD</p>
            </div>
          </div>
          <div className="flex justify-between text-base text-slate-500 px-3 py-2">
            <p>Total Return (inc. cap):</p>
            <p className='font-medium text-neutral-600'>{totalReturn.toFixed(2)} USD</p>
          </div>
        </div>
        <div className="flex justify-between border-2 bg-white mt-2 text-base text-slate-500 px-3 py-2">
          <p>Amount to Debit:</p>
          <p className='font-medium text-neutral-600'>{investmentAmount.toFixed(2)} USD</p>
        </div>
        <button
          onClick={handleConfirm}
          className="mt-4 py-3 w-full bg-green-500 text-white rounded-md hover:bg-opacity-75 flex justify-center items-center"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirm & Proceed'}
        </button>
        <button
          onClick={() => navigate(-1)}
          className="mt-3 w-full text-red-500 text-sm hover:text-opacity-75"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmProceed;
