import React from 'react'
import { Container } from 'react-bootstrap'
import './Header.css'
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';

const TopHeader = () => {
  return (
    <div className=" text-center text-bgColor-100 bg-bgColor-200 font-semibold text-sm">
            <Container className='px-2'> 
                <div className='d-flex justify-content-around align-items-center flex-col md:flex-row'>
                <div className="flex gap-2 items-center  py-1">
              <div className=""><PlaceIcon  /></div>
              <p className=" text-center">California, United States</p>
            </div>
            <div className="flex gap-2 items-center  py-1">
              <div className=""><EmailIcon  /></div>
              <p className="">support@hillviewhousing.com</p>
            </div>
                </div>
                
            </Container>
        </div>
  )
}

export default TopHeader
