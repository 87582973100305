import React, { useState, useEffect, useRef } from 'react';
import { grey} from '@mui/material/colors';
import GroupIcon from '@mui/icons-material/Group';
import CheckIcon from '@mui/icons-material/Check';
import IosShareIcon from '@mui/icons-material/IosShare';

const CounterSection = () => {
  const [activeUsers, setActiveUsers] = useState(0);
  const [deposits, setDeposits] = useState(0);
  const [withdrawals, setWithdrawals] = useState(0);

  // Ref to observe the section
  const sectionRef = useRef(null);

  // Function to handle intersection observer
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Perform counter animations when section is in view
        animateCounter(setActiveUsers, 1500, 352400); // Update active users to 150 after 1500ms
        animateCounter(setDeposits, 2000, 104210);    // Update investors to 25 after 2000ms
        animateCounter(setWithdrawals, 2500, 12);   // Update profits to 50000 after 2500ms
      }
    });
  };

  // Effect to set up intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the section is in view
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // Function to animate counter from 0 to a target value
  const animateCounter = (setFunc, duration, targetValue) => {
    let start = 0;
    const increment = Math.ceil(targetValue / (duration / 10));

    const counter = setInterval(() => {
      start += increment;
      if (start >= targetValue) {
        clearInterval(counter);
        start = targetValue;
      }
      setFunc(start);
    }, 10);
  };

  return (
    <section className=' py-10 ' ref={sectionRef}>
    
      <div className="statistics grid grid-cols-1 lg:grid-cols-3">
        <div className="text-white statistic flex  py-5 bg-bgColor-200 items-start justify-center gap-4 ">
          <div className='w-16 h-16 bg-accent1 rounded-sm flex items-center justify-center'><GroupIcon style={{color: grey[100]}} /></div>
          <div>
          <p className='text-start text-lg font-bold py-1'>Active Investors</p>
          <h3 className='text-start text-3xl font-bold'>{activeUsers}</h3>
          
          </div>
        </div>
        <div className="statistic flex bg-accent1 py-5 items-start justify-center gap-4 ">
          <div className='w-16 h-16 bg-bgColor-200 rounded-sm flex items-center justify-center'><CheckIcon style={{color: grey[100]}} /></div>
          <div>
          <p className='text-start text-lg font-bold py-1'>Recommedations</p>
          <h3 className='text-start text-3xl font-bold'>{deposits}</h3>
          
          </div>
        </div>
        <div className="statistic text-white flex bg-bgColor-200 py-5 items-start justify-center gap-4 ">
          <div className='w-16 h-16 bg-accent1 rounded-sm flex items-center justify-center'><IosShareIcon style={{color: grey[100]}} /></div>
          <div>
          <p className='text-start text-lg font-bold py-1'>Years of Experience</p>
          <h3 className=' text-start text-3xl font-bold'>{withdrawals}</h3>
          
          
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
