import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { wallet, address, imageUrl, amount, cryptoType, transactionId } = location.state;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full text-center">
        <h1 className="text-2xl font-semibold mb-4">Make Your Payment</h1>
        <p className="text-gray-700 mb-4">
          Your order <span className="font-semibold">{transactionId}</span> has been placed successfully. To complete, please send the exact amount of <span className="font-semibold">{amount} USD worth of {cryptoType}</span> to the address below.
        </p>
        <div className="border-2 text-center text-xs bg-gray-100 rounded-lg p-2 mb-4">
          <h2 className="text-xl  font-semibold mb-4">Pay {cryptoType}</h2>
          <img src={imageUrl} alt="QR Code" className="mx-auto w-80 h-80 mb-4" />
          <p className="text-xs font-semibold mb-1">{amount}USD worth of {cryptoType}</p>
          <p className="text-sm text-gray-500 mb-4">{Number(amount).toFixed(2)} USD</p> {/* Adjust the conversion rate as needed */}
          <div className="bg-white border rounded-lg px-2 py-4 mb-4">
            <p className="text-gray-700"><span className="text-sm font-semibold">{cryptoType} Address: </span> {address}</p>
            <p className="text-gray-700 font-mono ">{wallet}</p>
          </div>
          <div className="text-left text-xs text-gray-500">
            <p className="mb-1">⚠️ Be aware that this order will be cancelled if you send any other {cryptoType} amount.</p>
            <p>⚠️ Account will be credited once we receive your payment.</p>
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <button className="text-red-500 text-sm hover:underline" onClick={() => navigate('/dashboard/user/deposit/cancel', { state: { transactionId } })}>
            Cancel Order
          </button>
          <button className="text-blue-500 text-sm hover:underline" onClick={() => navigate('/dashboard')}>
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
