import React from 'react'
import Heading from '../Headings/Heading'
import SimpleBtn from '../Buttons/SimpleBtn'
import { Container } from 'react-bootstrap'


const Plans = () => {
  return (
    <div className='py-4'>
      
      <Container className="px-4 md:px-10">
      <Heading class1='text-start' class5='text-start' text1='Our Investment Plans' classname2='pt-3'/>
      <p className='text-start text-textColor-100 '>The broad category of our investment plans and their pricing just goes a long way to show that we have financial solutions for every kind of investor, from the newbie, to the professional, to those who are looking to go long-term in the financial market.</p> <br /> 
      <p className='text-start text-textColor-100 '>
      Hillview Housing has a portfolio investing in Cryptocurrencies(both mining and trading), Real Estate Investing/Financing and Shipping services with states of the arts technology. All their teams are made of up industry professionals who take their jobs seriously and are passionate about what we do.
      </p>
      
        <SimpleBtn text='Select Investment Plan' classname='self-start bg-accent1 px-3 py-2 mt-5 block' link='/pricing'/>
      </Container>
    </div>
  )
}

export default Plans
