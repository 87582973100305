import React, { useEffect, useState } from 'react';
import Spinner from '../Components/Spinner/Spinner';

// Higher-Order Component to add a delay before rendering the wrapped component
const withDelay = (WrappedComponent, delay = 6000) => {
  return (props) => {
    const [showComponent, setShowComponent] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowComponent(true);
      }, delay);

      // Cleanup the timer on unmount
      return () => clearTimeout(timer);
    }, []);

    return showComponent ? <WrappedComponent {...props} /> : <div><Spinner /></div>;
  };
};

export default withDelay;
