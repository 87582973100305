import React, { useEffect, useState } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Deposit = ({ deposits = [] }) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(deposits.length / itemsPerPage);
  const [visibleOptions, setVisibleOptions] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState({});

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = deposits.slice(indexOfFirstItem, indexOfLastItem);
  const [visiblePages, setVisiblePages] = useState([]);

  const updateVisiblePages = () => {
    const maxVisiblePages = 2; // Number of page buttons to display
    const halfVisiblePages = Math.floor(maxVisiblePages / 2);
    let startPage = Math.max(currentPage - halfVisiblePages, 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    const visiblePageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    setVisiblePages(visiblePageNumbers);
  };

  useEffect(() => {
    updateVisiblePages();
  }, [currentPage, totalPages]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleMoreClick = (id) => {
    setVisibleOptions(visibleOptions === id ? null : id);
  };

  const updateUserBalance = async (userId, amount, type) => {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const newBalance = type === 'deposit'
        ? userData.balance + amount
        : userData.balance - amount;
      const totalDep = type === 'deposit'
        ? userData.totalDep + amount
        : userData.totalDep;
      const totalWit = type === 'withdraw'
        ? userData.totalWit + amount
        : userData.totalWit;
      const status = 'active';

      await updateDoc(userRef, {
        status,
        balance: newBalance,
        totalDep: totalDep,
        totalWit: totalWit,
      });

      const htmlContentDeposit = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        body {
          font-family: Arial, sans-serif;
          font-size: 16px;
          color: #333;
          background-color: #f7f7f7;
          margin: 0;
          padding: 0;
        }
        .container {
          width: 100%;
          padding: 20px;
          box-sizing: border-box;
        }
        .content {
          max-width: 600px;
          margin: 0 auto;
          background-color: #fff;
          border: 1px solid #ddd;
        }
        .header, .footer {
          padding: 20px;
          text-align: center;
          background-color: #f7f7f7;
        }
        .body-content {
          padding: 20px;
          color: #333;
        }
        .body-content img {
          display: block;
          margin: 0 auto 10px;
          width: 100px;
          height: auto;
        }
        .body-content p {
          margin: 0 0 10px;
        }
      </style>
      </head>
      <body>
        <div class="container">
          <div class="content">
            <div class="header">
              <h2 style="color: darkblue; padding: 10px; text-align: center;">Hillview Housing</h2>
            </div>
            <div class="body-content">
              <p>Hello ${userData.name},</p>
              <p>Your deposit of ${amount}.00 USD has been successfully approved. This email confirms that the funds have been added to your account.</p>
              <p>Feel free to contact us if you have any questions.</p>
            </div>
            <div class="footer">
              Hillview Housing &copy; 2024
            </div>
          </div>
        </div>
      </body>
      </html>
      `;

      const plainTextDeposit = `
      Hello ${userData.name},
      
      Your deposit of ${amount}.00 USD has been successfully approved. This email confirms that the funds have been added to your account.
      
      Feel free to contact us if you have any questions.
      
      Hillview Housing © 2024
      `;

      await axios.post("https://nodemailer-theta.vercel.app/send-email", {
        email: userData.email,
        subject: "Deposit Successfully Processed",
        htmlContent: htmlContentDeposit,
        plainText: plainTextDeposit
      });

    } else {
      // Handle case where user document doesn't exist
    }

  };

  const updateFirestore = async (userId, type, id, status, amount) => {
    if (!userId || !type || !id) {
      // Handle missing parameters
      return;
    }

    try {
      const docRef = doc(db, `users/${userId}/${type}`, id);
      await updateDoc(docRef, { status });

      // Update user's balance and totals
      if (status === 'completed') {
        await updateUserBalance(userId, amount, type);
      }

      // Update state after successful Firestore update
      deposits((prevArray) =>
        prevArray.map((item) =>
          item.id === id ? { ...item, status } : item
        )
      );
    } catch (error) {
      // Handle error
    }
  };

  const handleConfirmClick = async (id) => {
    const item = deposits.find((item) => item.id === id);
    if (item) {
      const amount = item.type === 'deposit'
        ? item.paymentAmount
        : item.withdrawalAmount;

      await updateFirestore(item.userId, item.type, item.id, 'completed', amount);
      setVisibleOptions(null);
      setDisabledButtons((prevState) => ({ ...prevState, [id]: true }));
    }
  };

  const handleCancelClick = async (id) => {
    const item = deposits.find((item) => item.id === id);
    if (item) {
      await updateFirestore(item.userId, item.type, item.id, 'cancelled');
      setVisibleOptions(null);
      setDisabledButtons((prevState) => ({ ...prevState, [id]: true }));
    }
  };

  return (
    <div>
      <div className="overflow-auto min-h-screen hide-scrollbar">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b whitespace-nowrap">Details</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Transaction By</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Order</th>
              <th className="py-2 px-4 border-b whitespace-nowrap text-end pl-5">Amount</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((data) => (
              <tr key={data.id} className="border-b">
                <td className="py-2 px-4 flex whitespace-nowrap items-center">
                  <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'>
                    {data.type === 'deposit' ? <CallReceivedIcon /> : data.type === 'withdraw' ? (<div className='text-red-500'><CallMadeIcon /></div>) : (<div className='text-yellow-500'><SyncAltIcon /></div>)}
                  </div>
                  <div>
                    <p className='text-sm font-semibold'>{data.type === 'deposit' ? 'Deposit Funds' : data.type === 'withdraw' ? 'Withdraw Funds' : 'Invested on Plan'}</p>
                    <div className='flex'>
                      <p className='text-xs pt-1 text-gray-500'>{new Date((data.date?.seconds || data.orderedDate?.seconds) * 1000).toLocaleDateString()}</p>
                      <p className={`text-sm ml-3 ${data.status === 'cancelled' ? 'text-red-500' : 'text-gray-500'}`}>{data.status}</p>
                    </div>
                  </div>
                </td>
                <td className="py-2 px-4 whitespace-nowrap text-xs pt-1 text-gray-500">{data.userName}</td>
                <td className="py-2 px-4 whitespace-nowrap text-xs pt-1 text-gray-500">{data.invoiceNo || data.transactionId}</td>
                <td className="py-2 whitespace-nowrap px-4">
                  <div className='flex items-center justify-end relative'>
                    <div className='mr-3'>
                      <p className={`text-lg font-semibold ${data.type === 'deposit' ? 'text-green-500' : data.type === 'withdraw' ? 'text-red-500' : 'text-yellow-500'}`}>
                        {data.type === 'deposit' ? (<span>+ {Number(data.paymentAmount).toFixed()}</span>) : data.type === 'withdraw' ? (<span>- {Number(data.withdrawalAmount).toFixed()}</span>) : (<span>+ {Number(data.investmentAmount).toFixed()}</span>)} USD
                      </p>
                      <p className='text-slate-500 text-sm font-semibold'>
                        {data.type === 'deposit' ? Number(data.paymentAmount).toFixed(4) : data.type === 'withdraw' ? Number(data.withdrawalAmount).toFixed(4) : Number(data.investmentAmount).toFixed(4)} USDT
                      </p>
                    </div>
                    <MoreHorizIcon onClick={() => handleMoreClick(data.id)} className='cursor-pointer' />
                    {visibleOptions === data.id && (
                      <div className="absolute right-5 top-5 mt-2 z-10 w-48 bg-white border rounded-lg shadow-lg">
                        <div className="py-2">
                          {data.status === 'pending' && (
                            <>
                              <button
                                onClick={() => handleConfirmClick(data.id)}
                                disabled={disabledButtons[data.id]}
                                className="w-full block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <DoneIcon /> Confirm
                              </button>
                              <button
                                onClick={() => handleCancelClick(data.id)}
                                disabled={disabledButtons[data.id]}
                                className="w-full block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <ClearIcon /> Cancel
                              </button>
                            </>
                          )}
                          <button className="w-full block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => navigate(`/admin/dashboard/${data.userId}/${data.type}/${data.id}`)}>
                            <VisibilityIcon /> View Details
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='flex justify-center mt-4'>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className='px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded-md'
          >
            Previous
          </button>
          {visiblePages.map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-4 py-2 mx-1 ${page === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-md`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className='px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded-md'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
