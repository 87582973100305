import test1 from '../Images/test1.jpeg'
import test2 from '../Images/test2.jpeg'
import test3 from '../Images/test3.jpeg'
import test4 from '../Images/test4.jpeg'
import test5 from '../Images/test5.jpeg'
import test6 from '../Images/test6.jpeg'
import test7 from '../Images/test7.jpeg'
import test8 from '../Images/test8.jpeg'
import test9 from '../Images/test9.jpeg'

const testimonials = [
    {
      id: 1,
      name: 'John Smith',
      body: "Investing with Hillview Housing has been a game-changer for me. Their team's expertise and personalized approach helped me achieve my financial goals faster than I ever imagined. I highly recommend them to anyone serious about investing.",
      image: test1
    },
    {
      id: 2,
      name: 'Michael Davis',
      body: "I've been a client of hillview for years, and I couldn't be happier with the results. Their transparent communication and commitment to delivering value have earned my trust. hillview is my go-to choice for investment opportunities.",
      image: test2
    },
    {
      id: 3,
      name: 'Emily Johnson',
      body: 'Hillview has consistently provided me with sound investment advice and exceptional service. Their attention to detail and thorough research ensure that I am always making informed decisions. I am grateful for their partnership in growing my portfolio',
      image: test3
    },
    {
      id: 4,
      name: 'David Wilson',
      body: 'As a busy professional, I appreciate how Hillview housing simplifies the investment process. Their user-friendly platform, coupled with expert guidance, makes investing accessible and profitable. I am thrilled with the returns I have seen so far.',
      image: test4
    },
    {
      id: 5,
      name: 'Jane Smith',
      body: 'Hillview housing goes above and beyond to understand my financial objectives and tailor investment strategies accordingly. Their proactive approach and dedication to client success set them apart in the industry. I am proud to be a Hillview investor',
      image: test5
    },
    {
      id: 6,
      name: 'Matthew Brown',
      body: 'Hillview has been instrumental in helping me build wealth for the future. Their commitment to integrity and client satisfaction gives me confidence in my investment decisions. I look forward to continued success with Hillview',
      image: test6
    },
    {
      id: 7,
      name: 'Daniel Clark',
      body: 'I have been impressed by hillview housing professionalism and commitment to delivering results. Their knowledgeable team provides valuable insights and recommendations that have helped me navigate the complexities of the market with ease',
      image: test7
    },
    {
      id: 8,
      name: 'Olivia Martinez',
      body: 'Hillview dedication to excellence and client-centric approach have made them my preferred investment partner. Their diversified portfolio options and personalized service have exceeded my expectations. I am grateful for their guidance.',
      image: test8
    },
    {
      id: 9,
      name: 'Thompson',
      body: 'Hillview housing has been instrumental in helping me achieve my financial goals. Their transparent communication and proactive investment strategies have consistently yielded impressive returns. I highly recommend Hillview to investors seeking reliable growth opportunities.',
      image: test9
    },
    // Add more testimonials as needed
  ];

  export default testimonials