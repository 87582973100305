import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const HalfCircleProgressBar = ({ day, term, header1, header2, text1, text2, left, right, path, classname }) => {
  const percentage = (day / (term * 2)) * 100;

  return (
    <div className="relative flex flex-col max-w-xs bg-white items-center rounded-md justify-center p-4">
      <h3 className={`text-xl font-semibold text-slate-500 ${classname}`}>{header1}</h3>
      <h3 className="text-sm mb-2 text-slate-500">{header2}</h3>
      <div className="relative w-40 h-20 overflow-hidden">
        <div className="absolute inset-0">
          <CircularProgressbar
            value={percentage}
            strokeWidth={5}
            styles={buildStyles({
              pathColor: path,
              trailColor: "#e5e7eb",
              rotation: 0.75, // Rotate to start at the bottom center
              strokeLinecap: "butt",
            })}
          />
        </div>
        <div className="absolute inset-0 mt-3">
          <p className="text-3xl font-light text-slate-500">{text1}</p>
          <p className="text-sm text-slate-500">{text2}</p>
        </div>
      </div>
      
      <div className="absolute  left-10 -bottom-10 ml-5 mb-5 text-xs text-slate-500">{left}</div>
      <div className="absolute right-10 -bottom-10 mr-5 mb-5 text-xs text-slate-500">{right}</div>
    </div>
  );
};

export default HalfCircleProgressBar;
