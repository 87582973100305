import React from 'react';
import useAuth from '../CustomHooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = () => {
    const { currentUser, currentAdmin, error } = useAuth();

    if (error && !currentUser && !currentAdmin) {
        return (
            <div>
                <p>{error}</p>
                <Navigate to='/auth/login' />
            </div>
        );
    }

    if (currentAdmin) {
        return <Navigate to='/admin/dashboard' />; // Redirect to admin dashboard
    }

    if (currentUser) {
        return <Outlet />; // Render user routes
    }

    return <Navigate to='/auth/login' />;
};

export default ProtectedRoutes;
