import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';

const InactiveUsers = ({ users = [] }) => {
    const userss = users.filter((data) => data.status === 'inactive' && data.role != 'admin');
    const navigate = useNavigate();
    const [visibleOptions, setVisibleOptions] = useState(null);
    const [updatedUsers, setUpdatedUsers] = useState(users);
    console.log()
    const getInitials = (name) => {
    if (!name) return ''; // Return an empty string if the name is undefined
    const initials = name.split(' ').map((word) => word[0]).join('');
    return initials.toUpperCase();
    };

    const handleMoreClick = (id) => {
    setVisibleOptions(visibleOptions === id ? null : id);
    };

  const getRandomColor = () => {
    const colors = ['bg-green-500', 'bg-blue-500', 'bg-orange-500', 'bg-yellow-500'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const updateUserStatus = async (userId, newStatus) => {
    try {
        await updateDoc(doc(db, 'users', userId), {
            status: newStatus,
        });
        toast.success(`User status updated to ${newStatus}`);
        setUpdatedUsers((prevUsers) =>
            prevUsers.map((user) =>
                user.uid === userId ? { ...user, status: newStatus } : user
            )
        );
    } catch (error) {
        console.error('Error updating user status:', error);
    }
};

const getButtonLabel = (currentStatus, action) => {
    if (action === 'suspend') {
        return currentStatus === 'suspended' ? 'Un-suspend User' : 'Suspend User';
    }
    if (action === 'lock') {
        return currentStatus === 'locked' ? 'Unlock Account' : 'Lock Account';
    }
};

const getNewStatus = (currentStatus, action) => {
    if (action === 'suspend') {
        return currentStatus === 'suspended' ? 'inactive' : 'suspended';
    }
    if (action === 'lock') {
        return currentStatus === 'locked' ? 'inactive' : 'locked';
    }
};

  return (
    <div>
      <div className="overflow-x-auto min-h-screen hide-scrollbar">
        <table className="min-w-full mt-4">
          <thead>
            <tr className="bg-gray-100 border-b border-gray-200">
              <th className="py-2 px-4 border-b whitespace-nowrap">User</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Account Balance</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Verified</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Last Login</th>
              <th className="py-2 px-4 border-b text-end pl-5 whitespace-nowrap">Status</th>
            </tr>
          </thead>
          <tbody>
            {userss.map((user) => (
              <tr key={user.id} className="border-b text-xs border-gray-200">
                <td className="py-2 px-4 flex items-center whitespace-nowrap">
                  <div className={`${getRandomColor()} w-12 h-12 text-lg font-semibold rounded-full flex items-center justify-center`}>
                    {getInitials(user.name)}
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-sm font-bold">{user.name}</p>
                    <p className="text-xs">{user.email}</p>
                  </div>
                </td>
                <td className="py-2 px-4 whitespace-nowrap">{Number(user.balance).toFixed(2)}</td>
                <td className="py-2 px-4 whitespace-nowrap">{user.emailVerified ? 'Verified' : 'Verified'}</td>
                <td className="py-2 px-4 whitespace-nowrap">{user.lastLoginAt}</td>
                <td className="py-2 px-4 relative">
                  <div className='flex items-center justify-end'>
                    <div className='mr-3'>
                      <p className='text-slate-500 text-sm font-semibold'>
                      {user.status}
                      </p>
                    </div>
                    <MoreHorizIcon onClick={() => handleMoreClick(user.userId)} className='cursor-pointer' />
                    {visibleOptions === user.userId && (
                      <div className="absolute right-10 top-8 mt-2 z-10 w-48 bg-white border rounded-lg shadow-lg">
                        <div className="py-2">
                        <button
                                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                        onClick={() => updateUserStatus(user.uid, getNewStatus(user.status, 'suspend'))}
                                                    >
                                                        <DoneIcon /> {getButtonLabel(user.status, 'suspend')}
                                                    </button>
                                                    <button
                                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                        onClick={() => updateUserStatus(user.uid, getNewStatus(user.status, 'lock'))}
                                                    >
                                                        <ClearIcon /> {getButtonLabel(user.status, 'lock')}
                                                    </button>
                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => navigate(`/admin/dashboard/${user.userId}/${user.type}/${user.userId}`)}><VisibilityIcon /> View Details</button>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InactiveUsers;