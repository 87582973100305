import React from 'react';
import { Helmet } from 'react-helmet-async';


const Referrals = ({ data }) => {
  const refs = data.referrals ?? [];
  return (
    <div className="bgh">
      <Helmet><title>Referrals</title></Helmet>
      <div className="max-w-3xl mx-auto mt-8 p-4  text-start shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold text-slate-500 mb-4">Your Referrals</h2>
        {
          refs.length === 0 ? (<p>No referral yet</p>) : (
            <div className="space-y-2">
            {refs.map((referral, index) => (
              <div key={index} className="p-2 bg-gray-100 rounded-md  text-start">
                <div className='flex justify-between items-center'>
                  <p className="text-base font-medium text-slate-600">{referral.email}</p>
                  <p className="text-sm text-gray-500">Referred on {new Date(referral.timestamp.toDate()).toLocaleDateString()}</p>
                </div>
              </div>
            ))}
          </div>
          )
        }
      </div>
    </div>
  );
};

export default Referrals;
