import React from 'react'
import Heading from '../Headings/Heading'
import FeaturedCard from './FeaturedCard'
import { Container } from 'react-bootstrap'



const Featured = () => {
  return (
    <Container className='py-4 px-4 md:px-10'>
      <Heading class1='text-center' class5='text-center' text5='WHY CHOOSE US' text1='We Are Here To Improve' child='Digital Investment' classname1='text-center' classname2='pt-4'/>
      <FeaturedCard />
    </Container>
  )
}

export default Featured
