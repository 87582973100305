import React, { useState } from 'react';
import '../User/Dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import ActiveUsers from './ActiveUsers';
import Admin from './Admin';
import AllUser from './AllUser';
import InactiveUsers from './InactiveUsers';


const ManageUser = ({ activities}) => {
  const [activeLink, setActiveLink] = useState('all');
  const [visibleOptions, setVisibleOptions] = useState(null);
  const navigate = useNavigate()


  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const handleMoreClick = (id) => {
    setVisibleOptions(visibleOptions === id ? null : id);
  };

  const active = activities.filter((data) => data.status === 'active' );
  const inactive = activities.filter((data) => data.status === 'inactive' );

  const activeClass = 'text-green-500 border-b-2 font-semibold text-base border-green-500 pb-3';
  const normalClass = 'text-slate-500 pb-4 font-semibold text-base';

  return (
    <div className='bgh bg-bgColor-600 p-3'>
      <div className="lg:mx-5 mb-4">
        <div className='text-start lg:p-4'>
          <div>
          <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>Manage Users</p>
          <p className='text-base font-medium text-slate-500 my-2'>Total {activeLink === 'all' && activities.length}{activeLink === 'active' && active.length}{activeLink === 'inactive' &&  inactive.length} entries.</p>
          </div>
        </div>

        <div className='flex justify-start border-b-2 lg:mx-4'>
          <Link
            to=''
            className={`inline-block text-start ${activeLink === 'all' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('all')}
          >
            All
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'active' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('active')}
          >
            Active
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'inactive' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('inactive')}
          >
            Inactive
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'admin' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('admin')}
          >
            Admin
          </Link>
        </div>

        <div className='lg:mx-4 mt-4 text-start'>
          {activeLink === 'all' && (
            <div className=''>
              <AllUser users={activities}/>
            </div>
          )}
          {activeLink === 'active' && (
            <div className=''>
              <ActiveUsers users={activities}/>
            </div>
          )}
          {activeLink === 'inactive' && (
            <div className=''>
              <InactiveUsers  users={activities}/>
            </div>
          )}
          {activeLink === 'admin' && (
            <div className=''>
              <Admin  users={activities}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
