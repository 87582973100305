import React from 'react';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';



const Home = ({activities=[], transactions=[], withdrawals=[], deposits=[], deposit, withdrawal, numDeposits, numInvestments, numUsers, numWithdrawals}) => {

    const navigate = useNavigate()
    const pendingDeposits = deposits.filter(deposit => deposit.status === 'pending').length;
    const pendingWithdrawals = withdrawals.filter(withdrawal => withdrawal.status === 'pending').length;
    const getInitials = (name) => {
    if (!name) return ''; // Return an empty string if the name is undefined
    const initials = name.split(' ').map((word) => word[0]).join('');
    return initials.toUpperCase();
    };
    const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = new Date(timestamp.toDate());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };
    return (
        <div className="flex flex-col items-center">
            <div className=" shadow-lg rounded-lg w-full max-w-6xl p-3">
                <header className="flex justify-between flex-col md:flex-row text-start items-start md:items-center pb-4   md:px-4">
                    <div className='pl-2 mb-3 md:mb-0'>
                        <h1 className="text-2xl font-bold ">Overview</h1>
                        <p className='text-sm '>Here is an insight of what's going on.</p>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<VisibilityIcon />}
                        className="bg-blue-500 text-white pl-2 text-xs"
                        onClick={() => navigate('/admin/dashboard/transactions')}
                    >
                        View Transaction
                    </Button>
                </header>

                {
        pendingDeposits || pendingWithdrawals > 0 ? (
                <div className='flex flex-col bg-white  justify-between p-2 my-2 border-2 rounded border-yellow-400'>
        <div className='flex '>
          <p className='text-yellow-400 inline-block'><InfoIcon /></p>
          <p className='ml-4 text-slate-400 text-base text-start inline-block'>Attention: You have few pending request, that need to review</p>
        </div>
        <div className='flex ml-5 mt-2'>
          <p onClick={() => navigate('/admin/dashboard/transactions')} className='inline-block ml-4 text-yellow-500 font-semibold uppercase text-sm cursor-pointer'>{pendingWithdrawals}   withdraw</p>
          <p onClick={() => navigate('/admin/dashboard/transactions')} className='inline-block ml-4 text-yellow-500 font-semibold uppercase text-sm cursor-pointer'>{pendingDeposits}   deposit</p>
        </div>
      </div>
        ) : (
          <div></div>
        )
      }
    
                <section className="grid grid-cols-1 text-start md:grid-cols-6 gap-6 pt-4  md:px-4">
                    <div className="flex flex-col justify-between  bg-white shadow-sm border p-4 rounded-lg md:col-span-4">
                        <div>
                            <h2 className="text-xl font-semibold">Daily Insight</h2>
                            <p className="text-gray-600 text-sm">Daywise overall deposit & withdraw</p>
                        </div>
            
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4  mt-4">
                            <div className='p-3 border-2 rounded border-blue-400 text-start'>
                                <p className="text-blue-600 text-2xl font-bold">{deposit.toFixed(2)} USD</p>
                                <p className="text-gray-600  text-xs">Last month: <span className='font-semibold'> 0.00 USD</span></p>
                                <div className='flex items-center'>
                                <span className='flex mt-1 justify-center items-center bg-blue-600 h-6 text-white w-6 rounded-full'><CallReceivedIcon  style={{fontSize: 'small'}}/></span>
                                <span className='text-sm ml-1'>Deposit</span>
                                </div>
                            </div>
                            <div className='p-3 border-2 rounded border-orange-300 text-start'>
                                <p className="text-red-600 text-2xl font-bold">{withdrawal.toFixed(2)} USD</p>
                                <p className="text-gray-600 text-xs">Last month: <span className='font-semibold'> 0.00 USD</span></p>
                               <div className="flex items-center">
                               <span className='flex justify-center mt-1 items-center bg-red-600 h-6 text-white w-6 rounded-full'><CallMadeIcon  style={{fontSize: 'small'}}/></span> 
                               <span className='text-sm ml-1'>Withdrawal</span>
                               </div>
                            </div>
                        </div>
                </div>
                <div className='md:col-span-2 space-y-4'>
                    <div className="border rounded bg-white shadow-sm  p-4">
                        <div className='flex justify-between'>
                            <h3 className='text-start text-base font-bold text-slate-600'>Total Deposit</h3>
                            <InfoIcon style={{fontSize: 'small'}}/>
                        </div>
                        <h3 className="text-xl my-2 text-neutral-800 font-medium uppercase text-start">{deposit.toFixed(2)} <span>USD</span></h3>
                        <div className='mt-4'>
                            <p className='text-base  text-neutral-800 font-medium uppercase text-start'>This month</p>
                            <p className='text-start text-sm mb-2 text-slate-400'>0.00</p>
                            <p className="text-base text-neutral-800 font-medium uppercase text-start">This week</p>
                            <p className="text-sm text-slate-400 text-start">0.00</p>
                        </div>
                    </div>
                    <div className="border rounded bg-white shadow-sm p-4">
                        <div className='flex justify-between'>
                            <h3 className='text-start text-base  font-bold text-slate-600'>Total Withdrawal</h3>
                            <InfoIcon style={{fontSize: 'small'}}/>
                        </div>
                        <h3 className="text-xl my-2 text-neutral-800 font-medium uppercase text-start">{withdrawal.toFixed(2)} <span>USD</span></h3>
                        <div className='mt-4'>
                            <p className='text-base  text-neutral-800 font-medium uppercase text-start'>This month</p>
                            <p className='text-start text-sm mb-2 text-slate-400'>0.00</p>
                            <p className="text-base text-neutral-800 font-medium uppercase text-start">This week</p>
                            <p className="text-sm text-slate-400 text-start">0.00</p>
                        </div>
                    </div>
                    
                </div>
            </section>
    
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6 my-6 md:px-4">
            <div>
      
      <div className="bg-white text-start text-slate-600 shadow-md rounded-lg overflow-hidden">
            <div className="flex justify-between items-center p-3 border">
            <h2 className="text-base font-semibold">Transactions</h2>
            <p className='text-sm text-blue-400'></p>
            </div>
        <div className={`overflow-auto ${transactions.length > 6 ? 'h-96' : ''}`}>
          {transactions.map((data, index) => (
            <div
              className='flex flex-col px-2 py-2 border rounded-md bg-white text-slate-600 shadow-sm'
              key={index}
            >
              <div className='flex justify-between'>
                <div className="flex items-center mb-2 md:mb-0">
                  <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'> {data.type === 'deposit' ? <CallReceivedIcon /> : data.type === 'withdraw' ? (<div className='text-red-500'><CallMadeIcon /></div>) : (<div className='text-yellow-500'><SyncAltIcon /></div>)}</div>
                <div>
                <p className='text-lg  font-semibold'>{data.details}</p>
                  <div className='flex'>
                  <p className='text-xs pt-1 text-gray-500'>{new Date(data.date.seconds * 1000).toLocaleDateString()}</p>
                  <p className={`text-sm ml-3 ${data.status === 'cancelled' ? 'text-red-500' : 'text-gray-500'}`}>{data.status}</p>
                  </div>
                </div>
                </div>
               <div className="flex item-center">
               <div className=''>
                <p className={`text-lg font-semibold ${data.type === 'deposit' ? 'text-green-500' : data.type === 'withdraw' ? 'text-red-500' : 'text-yellow-500'}`}>+ {data.type === 'deposit' ? Number(data.paymentAmount).toFixed() : data.type==='withdraw' ? Number(data.withdrawalAmount).toFixed() : Number(data.transferAmount).toFixed()} USD</p>
                <p className='text-slate-500 text-sm font-semibold'>{data.type === 'deposit' ? Number(data.paymentAmount).toFixed(4) : data.type==='withdraw' ? Number(data.withdrawalAmount).toFixed(4) : Number(data.transferAmount).toFixed(4)} USDT</p>
              </div>
              
               </div>
              </div>
              
            </div>
          ))}
        </div>
      </div>
    </div>
              <div>
      
      <div className="bg-white text-start text-slate-600 shadow-md rounded-lg overflow-hidden">
            <div className="flex justify-between items-center p-3 border">
            <h2 className="text-base font-semibold">Investment Activities</h2>
            <p className='text-sm text-blue-400'>All Investments</p>
            </div>
        <div className={`overflow-auto ${activities.length > 6 ? 'h-96' : ''}`}>
          {activities.map((activity, index) => (
            <div
              key={index}
              className="p-3 border-b flex justify-between items-center"
            >
              <div className="flex items-center">
                <div className="text-base text-gray-500 bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center">
                  {getInitials(activity.userName)}
                </div>
                <div className="ml-2">
                  <p className="font-medium text-sm">{activity.planName} - Daily {activity.dailyInterest} for {activity.termDays}</p>
                  <p className="text-gray-600 text-sm">{formatDate(activity.orderedDate) || 'No date provided'}</p>
                </div>
              </div>
              <p className="text-slate-500 text-sm font-medium">+ {activity.investmentAmount} USD</p>
            </div>
          ))}
        </div>
      </div>
    </div>


            </section>
    
            <footer className="grid grid-cols-1 md:grid-cols-2 text-start gap-6 mt-6">
              <div className="bg-white shadow-md  p-4 rounded-lg">
                <div className='flex justify-start items-start flex-col'>
                <h2 className="text-xl font-semibold">User Activities</h2>
                <p className="text-gray-600 text-xs">In last 30 days</p>
                </div>
               <div className="flex mt-3">
                <div className='flex'>
                <span className="">
                <PeopleOutlineIcon />
                </span>
                <div className='ml-3'>
                <p className="text-gray-600 text-lg font-semibold">0</p>
                <p className="text-gray-600 text-sm">Direct Join</p>
                </div>
               </div>
               <div className='flex ml-3'>
                <span className="">
                <ShareIcon />
                </span>
                <div className='ml-3'>
                <p className="text-gray-600 text-lg font-semibold">0</p>
                <p className="text-gray-600 text-sm">Referral Join</p>
                </div>
               </div>
               </div>
              </div>
              <div className="bg-white shadow-md  p-4 rounded-lg">
                <div className='flex justify-start items-start flex-col'>
                <h2 className="text-xl font-semibold">Total Stat</h2>
                
                </div>
               <div className="grid grid-cols-2 gap-4 mt-3">
                <div className='flex'>
                <div className='w-4 h-4 rounded-md bg-yellow-600'></div>
                <div className='ml-3'>
                <p className="text-gray-600 text-xs">Deposits</p>
                <p className="text-gray-600 text-lg font-semibold">{numDeposits}</p>
                <p className="text-gray-600 text-xs">0 since last week</p>
                </div>
               </div>
               <div className='flex'>
                <div className='w-4 h-4 rounded-md bg-yellow-400'></div>
                <div className='ml-3'>
                <p className="text-gray-600 text-xs">withdrawals</p>
                <p className="text-gray-600 text-lg font-semibold">{numWithdrawals}</p>
                <p className="text-gray-600 text-xs">0 since last week</p>
                </div>
               </div><div className='flex'>
                <div className='w-4 h-4 rounded-md bg-indigo-600'></div>
                <div className='ml-3'>
                <p className="text-gray-600 text-xs">investments</p>
                <p className="text-gray-600 text-lg font-semibold">{numInvestments}</p>
                <p className="text-gray-600 text-xs">0 since last week</p>
                </div>
               </div><div className='flex'>
                <div className='w-4 h-4 rounded-md bg-indigo-400'></div>
                <div className='ml-3'>
                <p className="text-gray-600 text-xs">users</p>
                <p className="text-gray-600 text-lg font-semibold">{numUsers}</p>
                <p className="text-gray-600 text-xs">0 since last week</p>
                </div>
               </div>
               </div>
              </div>
              
            </footer>
          </div>
        </div>
      );
    };

export default Home;
