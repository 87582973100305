import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase.config';
import HalfCircleProgressBar from './HalfCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet-async';

const InvestmentDetails = () => {
  const { investmentId } = useParams();
  const [investment, setInvestment] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const calculateDaysDifference = (startDate, endDate) => {
    const msInDay = 24 * 60 * 60 * 1000;
    const diff = (endDate - startDate) / msInDay;
    return Math.floor(diff);
  };

  useEffect(() => {
    const fetchInvestmentData = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      const userId = user.uid;
      const investmentRef = doc(db, 'users', userId, 'investment', investmentId);
      const investmentSnap = await getDoc(investmentRef);

      if (investmentSnap.exists()) {
        setInvestment(investmentSnap.data());
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    };

    fetchInvestmentData();
  }, [investmentId, navigate]);

  useEffect(() => {
    const updateInvestmentProgress = async () => {
      if (!investment) return;

      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      const userId = user.uid;
      const investmentRef = doc(db, 'users', userId, 'investment', investmentId);

      const startDate = new Date(investment.termStartAt.seconds * 1000);
      const endDate = new Date();
      const daysDifference = calculateDaysDifference(startDate, endDate);

      if (daysDifference > 0 && investment.adjustProfit.daysPassed < investment.adjustProfit.totalDays && investment.status === 'running') {
        let newDaysPassed = investment.adjustProfit.daysPassed + daysDifference;
        newDaysPassed = newDaysPassed > investment.adjustProfit.totalDays ? investment.adjustProfit.totalDays : newDaysPassed;

        const dailyProfit = investment.investmentAmount * (investment.dailyInterest / 100) * (newDaysPassed - investment.adjustProfit.daysPassed);
        const newInvestmentProfit = investment.investmentProfit + dailyProfit;

        let updates = {
          'adjustProfit.daysPassed': newDaysPassed,
          'investmentProfit': newInvestmentProfit,
          'termStartAt': endDate // Update termStartAt to the current date
        };

        if (newDaysPassed >= investment.adjustProfit.totalDays) {
          const totalReturn = (investment.investmentAmount + newInvestmentProfit).toFixed(2);
          const newPaymentReference = '3' + Math.floor(Math.random() * 1000000000).toString();

          updates = {
            ...updates,
            'status': 'ended',
            'paymentSource': 'Main Balance',
            'paymentReference': newPaymentReference,
            'paidAmount': totalReturn
          };

          // Update user balance
          const userRef = doc(db, 'users', userId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const profit = Number(userSnap.data().profit) || 0; // Ensure profit is a number
            const newBalance = (profit + Number(totalReturn)).toFixed(2);
            await updateDoc(userRef, {
              profit: newBalance
            });
          }
          
        }

        await updateDoc(investmentRef, updates);

        setInvestment(prevState => ({
          ...prevState,
          adjustProfit: {
            ...prevState.adjustProfit,
            daysPassed: newDaysPassed
          },
          investmentProfit: newInvestmentProfit,
          status: newDaysPassed >= investment.adjustProfit.totalDays ? 'ended' : prevState.status,
          paymentSource: newDaysPassed >= investment.adjustProfit.totalDays ? 'Main Balance' : prevState.paymentSource,
          paidAmount: newDaysPassed >= investment.adjustProfit.totalDays ? totalReturn : prevState.paidAmount,
          termStartAt: endDate
        }));
      }
    };

    updateInvestmentProgress();
  }, [investment, navigate, investmentId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!investment) {
    return <p>No investment data found.</p>;
  }

  const startDate = new Date(investment.termStartAt.seconds * 1000).toLocaleString();
  const endDate = new Date(investment.termEndAt.seconds * 1000).toLocaleString();
  const dailyProfit = investment.investmentAmount * (investment.dailyInterest / 100);
  const totalProfit = Number(dailyProfit.toFixed(2) * investment.termDays);
  const totalReturn = (parseFloat(investment.investmentAmount) + parseFloat(totalProfit));
  const totalReturnPercentage = ((investment.adjustProfit.daysPassed / investment.adjustProfit.totalDays) * 100).toFixed(0);

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = new Date(timestamp.toDate());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };


  return (
    <div className="p-3">
      <Helmet><title>Investment-details</title></Helmet>
      <div className="lg:px-5 p-2">
        <p className="text-start cursor-pointer"><ArrowBackIcon onClick={() => navigate(-1)} /> Investment</p>
        <h3 className="text-3xl text-slate-600 font-normal capitalize mb-2 text-start">{investment.planName} - Daily {investment.dailyInterest}% for {investment.termDays} Days</h3>
        <div className="flex mb-5 text-slate-600">
          <p className="text-sm">{investment.invoiceNo}</p>
          <p className={`px-2 text-xs ml-2 rounded-md ${investment.status === 'running' ? 'bg-yellow-400' : 'bg-green-500'} text-white`}>
            {investment.status}
          </p>
        </div>
      </div>
      <div className="bg-white text-slate-600 lg:px-5 py-3 px-4 rounded-md mb-5 border-2">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex text-start items-center">
            <div className="p-2">
              <p className="text-xl md:text-2xl text-slate-600 font-bold capitalize mb-1 text-start">{investment.investmentAmount.toFixed(2)} <span className="font-light textxl">USD</span></p>
              <p className="text-base">Invested</p>
            </div>
            <AddIcon />
            <div className="p-2">
              <p className="text-xl md:text-2xl text-slate-600 font-bold capitalize mb-1 text-start">{totalProfit.toFixed(2)} <span className="font-light textxl">USD</span></p>
              <p className="text-base">Profit</p>
            </div>
          </div>
          <div className="p-2 mt-1 md:mt-0 lg:mr-14">
            <p className="text-xl md:text-2xl text-slate-600 font-bold capitalize mb-1 text-start">{totalReturn} <span className="font-light textxl">USD</span></p>
            <p className="text-base text-start">Total Return (inc. cap)</p>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 px-2 mt-2">
          <div className="mb-4">
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Term Basis:</p>
              <p>Daily</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Term Duration:</p>
              <p>{investment.termDays} Days</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Term Start:</p>
              <p>{formatDate(investment.orderedDate)}</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Term End:</p>
              <p>{endDate}</p>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Interest (daily)</p>
              <p>{investment.dailyInterest}%</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Total net profit:</p>
              <p>{Number(totalProfit).toFixed(2)} USD</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Daily profit (inc. cap):</p>
              <p>{dailyProfit.toFixed(2)}</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Adjust profit:</p>
              <p>{investment.adjustProfit.daysPassed} / {investment.adjustProfit.totalDays} Times</p>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Ordered date:</p>
              <p>{formatDate(investment.orderedDate)}</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Payment source</p>
              <p>{investment.paymentSource || 'N/A'}</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Payment reference</p>
              <p>{investment.paymentReference || 'N/A'}</p>
            </div>
            <div className="flex justify-between text-sm mb-1 pr-4 border-b-2 py-2 text-slate-500">
              <p>Paid amount</p>
              <p>{investment.adjustProfit.daysPassed === investment.adjustProfit.totalDays ? investment.investmentAmount.toFixed(2) : 'N/A'}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <h3 className="text-2xl text-slate-500 mb-4 font-semibold">Graph View</h3>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 bg-white rounded p-4">
          <div className='ml-4 md:ml-0 w-full'><HalfCircleProgressBar day={investment.adjustProfit.daysPassed} term={investment.termDays} path="green" header1="Overview" text1={`${totalReturnPercentage}%`} text2={`${investment.dailyInterest}% / per day`} left="0.00 USD" right={`${totalReturn.toFixed(2)} USD`} /></div>
          <div className='ml-4 md:ml-0 w-full'>
          <HalfCircleProgressBar  day={investment.adjustProfit.daysPassed} term={investment.termDays} path="blue" header1="Net Profit" header2={`Earn so far ${investment.investmentProfit.toFixed(2)} USD`} text1={`${investment.dailyInterest}%`} text2="daily profit" left="0.00 USD" right={`${dailyProfit.toFixed(2)} USD`} />
          </div>
          <div className='ml-4 md:ml-0 w-full'>
          <HalfCircleProgressBar day={investment.adjustProfit.daysPassed} term={investment.termDays} header1="Remain" text1={`${investment.termDays - investment.adjustProfit.daysPassed}`} text2="remain to adjust" left="0 Time" right={`${investment.adjustProfit.totalDays} Times`} header2={`Adjusted so far ${investment.adjustProfit.daysPassed} Times`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
