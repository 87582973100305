import React from 'react'
import slider1 from '../Assets/Images/slider1.jpeg'
import { Container } from 'react-bootstrap'
import CheckIcon from '@mui/icons-material/Check';
import Heading from '../Components/Headings/Heading';
import team1 from '../Assets/Images/team1.jpeg'
import team2 from '../Assets/Images/team2.jpeg'
import team3 from '../Assets/Images/team3.jpeg'
import FeaturedCard from '../Components/Sections/FeaturedCard';
import { Helmet } from 'react-helmet-async';



const About = () => {
  return (
    <div>
       <Helmet>
        <title>About</title>
      </Helmet>
       <div className="" >
            <div className="h-full w-full" style={{ backgroundImage: `url(${slider1})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
              <div className="lg:pl-14 h-full w-full  slide-content text-slate-100 bg-overlay">
                <div>
                  <div className="flex justify-center items-center flex-col w-full h-full px-2 py-5">
                    <h2 className="text-2xl pt-10 md:text-4xl font-bold pb-10">About Us</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Container>
          <Heading class1='text-start' class5='text-start' text5='ABOUT US' text1='The Best Real Estate and Investment Solution With 10 Years of Experience' classname2='pt-5' classname3='pt-5'/>
      <p className='text-start text-textColor-100 '>Hillview Housing is a real estate and digital investment platform firmly focused on providing affordable housing to everyone and creating stable wealth through level based investments. We are the only firm in the market that provides amazing real estate services alongside profitable investment. Hillview Housing has remained the best agency for digital investment. It was concieved by a group of professionals with enormous experience and wealth of knowledge in digital investments. Our primary aim is creating stable wealth and profit for our investors at all levels.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-4 mt-4 font-bold text-lg">
          <p className="flex items-start "><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> Flexibility</p>
          <p className="flex items-start"><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> Steady Growth</p>
          <p className="flex items-start"><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> 24/7 Support</p>
          <p className="flex items-start"><span className="pr-2"><CheckIcon style={{ color: '#f9bd0d' }}/></span> Integrity</p>
        </div>
        <Heading class1='text-center' class5='text-center' text5='WE KEPT ON GROWING' text1='Our Journey Continued' classname2='pt-5 ' classname1='text-center' classname3='pt-5 '/>
        <div className='lg:w-3/4 mx-auto text-base'>
          <p>We started off as an agency focused on cryptocurrency trading, staking, investing, holding and futures predictions. We made tremendous progress in a few years as our investor base and volume kept on increaing. We invested so much knowledge in advancing our working process to maximise profit.</p> <br /> 
          <p>
          Hillview Housing faced a lot of challenges, but our wealth of experience and willingness to learn and improve kept us running. Later on, we integrated a new sector, real estate. We started off with retailing properties and crowd development. As we maintained progress, we had the courage to own some properties and transcended into property rentals and resell.
          </p> <br /> 
          <p>
          We as of recent solely focus and transact on cryptocurrency and real estate properties. As an agency that understands the power of specialisation, we have decided to keep our sectors small and grow consistenly regardless.
          </p>

          <h3 className='py-3 text-lg font-semibold text-textColor-100'>How we work</h3>
          <p>
          We invest in high volumes into any sector to maximise profit. We however understand the concept of short term investment and long term investment. That is why we have found the most convenient way to transact.
          </p><p>
          Investors who invest are entitled to a certain amount of profit in a given period of time as the agency uses the finance(high volume) ganered over a period to invest in different sectors in order to make safe profit.

          </p> <br />
          <p>We know the risks and go with the best and safest options always.</p>
        </div>

        <Heading class1='text-center' class5='text-center' text5='TEAM MEMBERS' text1='Our Top Team Members' classname2='pt-5 ' classname1='text-center' classname3='pt-5 '/>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 pb-5'>
          <div className='bg-bgColor-300 pb-4 '>
                <img src={team3} alt="" />
                <h3 className='py-2 font-semibold text-xl text-textColor-100 capitalize'>Mollah Stephen</h3>
                <p className='px-4'>CHIEF EXECUTIVE OFFICER</p> 
            </div>
          <div className='bg-bgColor-300 pb-4'>
                <img src={team2} alt="" />
                <h3 className='py-2 font-semibold text-xl text-textColor-100 capitalize'>Jennifer Perez</h3>
                <p className='px-4'>VICE PRESIDENT</p> 
            </div>
          <div className='bg-bgColor-300 h-full w-5/6 pb-4'>
                <div className='h-4/5 bg-white'>
                <img className='h-full' src={team1} alt="" />
                </div>
                <div className=""></div>
                <h3 className='py-2 font-semibold text-xl text-textColor-100 capitalize'>Chris Nuel</h3>
                <p className=''>HEAD OF OPERATIONS</p> 
            </div>
        </div>
        <Heading class1='text-center' class5='text-center' text5='WHY CHOOSE US' text1='We Are Here To Improve' child='Digital Investment' classname1='text-center' classname2='pt-4'/>
        <FeaturedCard />
          </Container>
    </div>
  )
}

export default About
