import { Container } from "react-bootstrap"
import Heading from "./Headings/Heading";
import { Link } from "react-router-dom";


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section className="bg-bgColor-200 text-white mt-5 pb-10">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-3">
          <div>
            <Heading class1='text-start md:text-xl text-white' text1='Buy Bitcoin' classname1=' text-start ' classname2='pt-4 '/>
            <div className="text-white ink-animated flex flex-col justify-start items-start">
            <h3 className="text-light text-lg font-semibold mb-2">- EUROPE & AMERICA -</h3>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" rel="noreferrer" href="https://www.coinmama.com/"><span className="text-accent1">&rarr;</span> Coin Mama</a>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank"rel="noreferrer" href="https://www.paybis.com/"><span className="text-accent1">&rarr;</span> PayBis</a>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" rel="noreferrer" href="https://www.coinbase.com/"><span className="text-accent1">&rarr;</span> CoinBase</a>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" rel="noreferrer" href="https://www.luno.com/"><span className="text-accent1">&rarr;</span> Luno</a>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" targe="_blank" rel="noreferrer" href="https://www.xcoin.com/"><span className="text-accent1">&rarr;</span> Xcoin</a>
                           <a
                            className="text-light transition-all duration-500 hover:pl-2.5" targe="_blank" rel="noreferrer" href="https://www.binance.com/"><span className="text-accent1">&rarr;</span> Binance</a>
            </div>
          </div>
          <div>
            <Heading class1='text-start md:text-xl text-white' text1='Buy Bitcoin' classname1='text-start ' classname2='pt-4 pb-0'/>
            <div className="text-white ink-animated flex flex-col justify-start items-start">
            <h3 className="text-light mb-2 text-lg font-semibold">- OTHERS -</h3>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" rel="noreferrer" href="https://trustwallet.com/download"><span className="text-accent1">&rarr;</span> Trust Wallet</a>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" rel="noreferrer" href="https://coinhako.com/"><span className="text-accent1">&rarr;</span> Coin Hako</a>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" rel="noreferrer" href="https://www.blockchain.com/"><span className="text-accent1">&rarr;</span> Blockchain</a>
                           <a
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" rel="noreferrer" href="https://www.exodus.com/download/"><span className="text-accent1">&rarr;</span> Exodus</a>
            </div>
          </div>
          <div>
            <Heading class1='text-start md:text-xl text-white' text1='Company' classname1='text-start w-8' classname2='pt-4 pb-0'/>
            <div className="text-white ink-animated flex flex-col justify-start items-start">
                          <Link
                        className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/"><span className="text-accent1">&rarr;</span> Home</Link>
                            <Link
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/about"><span className="text-accent1">&rarr;</span> About Us</Link>
                            <Link
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/services/crypto-currency"><span className="text-accent1">&rarr;</span> Cryptocurrency</Link>
                            <Link
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/services/real-estate"><span className="text-accent1">&rarr;</span> Real Estate</Link>
                            <Link
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/pricing"><span className="text-accent1">&rarr;</span> Pricing</Link>
                            <Link
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/contact"><span className="text-accent1">&rarr;</span> Contact Us</Link>
            </div>
          </div>
          <div className="">
            <Heading class1='text-start font-semibold md:text-xl text-white' text1='QuickLinks' classname1='text-start w-8' classname2='pt-4 pb-0'/>
            <div className="text-white ink-animated flex flex-col justify-start items-start">
                          <Link
                        className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/auth/register"><span className="text-accent1">&rarr;</span> Create Account</Link>
                            <Link
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/auth/login"><span className="text-accent1">&rarr;</span> Login</Link>
                            <Link
                            className="text-light mb-2 transition-all duration-500 hover:pl-2.5" target="_blank" to="/contact"><span className="text-accent1">&rarr;</span> Email Support</Link>
                            
            </div>
          </div>
          
         
        </div>
        <p className="text-sm text-center text-white pt-3">&copy; {currentYear} Hillview Housing. All Rights Reserved.</p>
      </Container>
    </section>
  )
}

export default Footer
