import React from 'react';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';



const History = ({ transactions = [], loading }) => {
  console.log(transactions)
  const navigate = useNavigate();
  const historyTransactions = transactions.filter((data) => data.status === 'completed' || data.status === 'cancelled');
  return (
    <div className='overflow-hidden'>
      <Helmet><title>History</title></Helmet>
    <div >
      <div className='text-slate-400'>
        {loading ? (
          <p>Loading...</p>
        ) : historyTransactions.length <= 0 ? (
          <p className='text-start'>No transaction found!</p>
        ) : (
          historyTransactions.map((data) => (
            <div
              className='flex flex-col px-2 py-2 border rounded-md bg-white text-slate-600 shadow-sm'
              key={data.id}
            >
              <div className='flex justify-between'>
                <div className="flex items-center mb-2 md:mb-0">
                  <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'> {data.type === 'Deposit' ? <CallReceivedIcon /> : data.type === 'Withdraw' ? (<div className='text-red-500'><CallMadeIcon /></div>) : (<div className='text-yellow-500'><SyncAltIcon /></div>)}</div>
                <div>
                <p className='text-sm  font-bold'>{data.details}</p>
                  <div className='flex'>
                  <p className='text-xs pt-1 text-gray-500'>{new Date(data.date.seconds * 1000).toLocaleDateString()}</p>
                  <p className={`text-sm ml-3 ${data.status === 'cancelled' ? 'text-red-500' : 'text-gray-500'}`}>{data.status}</p>
                  </div>
                </div>
                </div>
               <div className="flex item-center">
               <div className=''>
                <p className={`text-sm font-bold ${data.type === 'Deposit' ? 'text-green-500' : data.type === 'Withdraw' ? 'text-red-500' : 'text-yellow-500'}`}>+ {data.type === 'Deposit' ? Number(data.paymentAmount).toFixed() : data.type==='Withdraw' ? Number(data.withdrawalAmount).toFixed() : Number(data.transferAmount).toFixed()} USD</p>
                <p className='text-slate-500 text-sm font-semibold'>{data.type === 'Deposit' ? Number(data.paymentAmount).toFixed(4) : data.type==='Withdraw' ? Number(data.withdrawalAmount).toFixed(4) : Number(data.transferAmount).toFixed(4)} USDT</p>
              </div>
              <div className='ml-1'>
                  <button
                  onClick={() => navigate(`/dashboard/user/${data.type}/${data.id}`)}
                  className="text-blue-500 hover:underline mt-2 md:mt-0"
                >
                  <ArrowForwardIosIcon />
                </button>
                  </div>
               </div>
              </div>
              
            </div>
          ))
        )}
      </div>
    </div>
  </div>
  );
};

export default History;
