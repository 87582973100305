
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const Scheduled = ({transactions = [], loading}) => {
  const navigate = useNavigate()
  const depositTransactions = transactions.filter((data) => data.type === 'Deposit' && data.status === 'pending' );
  const withdrawalTransactions = transactions.filter((data) => data.type === 'Withdraw' && data.status === 'pending' );

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = new Date(timestamp.toDate());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };
  
  return (
    
    <div className='overflow-hidden'>
      <Helmet><title>Sheduled</title></Helmet>
      <div >
        <div className='text-slate-400'>
          {loading ? (
            <p>Loading...</p>
          ) : depositTransactions.length  <= 0  ? (
            <p>No transaction found!</p>
          ) : (
            depositTransactions.map((data) => (
              <div
                className='flex flex-col mb-1 px-2 py-2 border rounded-md bg-white text-slate-600 shadow-sm'
                key={data.id}
              >
                <div className='flex justify-between'>
                  <div className="flex items-center mb-2 md:mb-0">
                    <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'><CallReceivedIcon /></div>
                  <div>
                  <p className='text-sm  font-bold'>{data.details}</p>
                    <div className='flex'>
                    <p className='text-xs pt-1 text-gray-500'>{new Date(data.date.seconds * 1000).toLocaleDateString()}</p>
                    <p className='text-sm ml-3 text-gray-500'>{data.status}</p>
                    </div>
                  </div>
                  </div>
                  <div className='flex items-center'>
                  <div className=' '>
                  <p className='text-sm font-bold text-green-500'>+ {data.paymentAmount} USD</p>
                  <p className='text-slate-500 text-sm font-semibold'>{Number(data.paymentAmount).toFixed(4)} USDT</p>
                  </div>
                  <div className='ml-1 md:ml-4'>
                  <button
                  onClick={() => navigate(`/dashboard/user/deposit/pending/${data.id}`)}
                  className="text-blue-500 hover:underline mt-2 md:mt-0"
                >
                  <ArrowForwardIosIcon />
                </button>
                  </div>
                </div>
                </div>
                
              </div>
            ))
          )}
          {loading ? (
            <p>Loading...</p>
          ) : withdrawalTransactions.length <= 0  ? (
            <p></p>
          ) : (
            withdrawalTransactions.map((data) => (
              <div
                className='flex flex-col mb-1 px-2 py-2 border rounded-md bg-white text-slate-600 shadow-sm'
                key={data.id}
              >
                <div className='flex justify-between'>
                  <div className="flex items-center mb-2 md:mb-0">
                  <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'><div className='text-red-500'><CallMadeIcon /></div></div>
                  <div>
                  <p className='text-lg  font-semibold'>{data.details}</p>
                    <div className='flex'>
                    <p className='text-xs pt-1 text-gray-500'>{new Date(data.date.seconds * 1000).toLocaleDateString()}</p>
                    <p className='text-sm ml-3 text-gray-500'>{data.status}</p>
                    </div>
                  </div>
                  </div>
                  <div className='flex items-center'>
                    <div className=' '>
                      <p className='text-lg font-semibold text-red-500'>+ {data.withdrawalAmount} USD</p>
                     <p className='text-slate-500 text-sm font-semibold'>{Number(data.withdrawalAmount).toFixed(4)} USDT</p>
                    </div>
                    <div className='ml-1 md:ml-4'>
                      <button
                      onClick={() => navigate(`/dashboard/user/withdraw/${data.id}`)}
                      className="text-blue-500 hover:underline mt-2 md:mt-0"
                      >
                        <ArrowForwardIosIcon />
                      </button>
                    </div>
                  </div>
                </div>
                
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Scheduled;
