import React from 'react'
import '../Headings/Headings.css'

const Heading = ({text1, text, text5, child, class1, class5, classname1, classname2, classname3 }) => {
  return (
    <>
      <div className={`${classname2}`}>
         <div className={`px-2 md:px-10 ${classname3}`}>
            <div className={`section-title relative pb-3 mb-5 ${classname1}`}>
            <h5 className={`font-bold text-lg text-accent1 text-uppercase ${class5}`}>{text5}</h5>
            <h1 className={`mb-0 font-bold text-bgColor-200 text-2xl md:text-3xl capitalize ${class1}`}>{text1} <br/> {child}</h1>
            <p className='max-w-lg mx-auto'>{text}</p>
            </div>
        </div>
    </div>
    </>
  )
}

export default Heading
