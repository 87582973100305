import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import ProfileCard from './Components/Profile';
import Security from "./Components/Security";
import Activity from "./Components/Activity";
import Accounts from "./Components/Accounts";
import { Link, useLocation } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import SimpleBtn from "../Components/Buttons/SimpleBtn";
import { Helmet } from "react-helmet-async";

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeLink, setActiveLink] = useState('profile');
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const section = query.get('section');
    if (section) {
      setActiveLink(section);
    }
  }, [location]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = doc(db, "users", user.uid);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            setUserInfo(userSnapshot.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.log("Error getting document:", error);
        }
      } else {
        console.log("User is not authenticated");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userInfo) {
    return <div>No user information available.</div>;
  }
  

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const activeClass = 'text-green-500 border-b-2 font-semibold text-base border-green-500 pb-3';
  const normalClass = 'text-slate-500 pb-4 font-semibold text-base';

  return (
    <div className='bgh bg-bgColor-600 px-2 py-6'>
      <Helmet><title>Profile</title></Helmet>
      <div className="lg:mx-5 mb-3">
        <div className='text-start '>
          {activeLink === 'profile' && (
            <div className=''>
              <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>profile info</p>
              <h1 className='text-base font-medium text-slate-500 mt-3 mb-4'>You have full control to manage your own account setting.</h1>
            </div>
          )}
          {activeLink === 'accounts' && (
            <div className=''>
              <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>Payment Accounts</p>
              <h1 className='text-base font-medium text-slate-500 mt-3 mb-4'>You have full control to manage your own account setting.</h1>
            </div>
          )}
          {activeLink === 'security' && (
            <div className=''>
              <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>Security Settings</p>
              <h1 className='text-base font-medium text-slate-500 mt-3 mb-4'>You have full control to manage your own account setting.</h1>
            </div>
          )}
          {activeLink === 'activity' && (
            <div className=''>
              <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>Login Activity</p>
              <h1 className='text-base font-medium text-slate-500 mt-3 mb-4'>You have full control to manage your own account setting.</h1>
            </div>
          )}
        </div>

        <div className='flex justify-start border-b-2'>
          <Link
            to=''
            className={`inline-block text-start ${activeLink === 'profile' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('profile')}
          >
            Profile
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'accounts' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('accounts')}
          >
            Accounts
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'security' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('security')}
          >
            Security
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'activity' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('activity')}
          >
            Activity
          </Link>
        </div>

        <div className=' mt-4 text-start'>
          {activeLink === 'profile' && (
            <div className=''>
              <div className='flex flex-col bg-white md:flex-row justify-between p-3 mb-3 mt-5 border-2 rounded border-textColor-400'>
                <div className='flex'>
                  <p className='text-green-400 inline-block'><InfoIcon /></p>
                  <p className='ml-4 text-slate-400 inline-block text-base text-start'>Update your account information from your profile to complete account setup.</p>
                </div>
                <div><SimpleBtn text='Update profile' classname='text-green-400 rounded py-2 px-3 capitalize font-semibold text-sm ' link='/dashboard/user/update'/></div>
              </div>
              <ProfileCard  userInfo={userInfo}/>
            </div>
          )}
          {activeLink === 'accounts' && (
            <div className=''>
              <Accounts />
            </div>
          )}
          {activeLink === 'security' && (
            <div className=''>
              <Security />
            </div>
          )}
          {activeLink === 'activity' && (
            <div className=''>
              <Activity />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
