import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

const Admin = ({ users = [] }) => {
    const userss = users.filter((data) => data.role === 'admin' );
    const navigate = useNavigate();
    const [visibleOptions, setVisibleOptions] = useState(null);
    console.log()
    const getInitials = (name) => {
    if (!name) return ''; // Return an empty string if the name is undefined
    const initials = name.split(' ').map((word) => word[0]).join('');
    return initials.toUpperCase();
    };

    const handleMoreClick = (id) => {
    setVisibleOptions(visibleOptions === id ? null : id);
    };

  const getRandomColor = () => {
    const colors = ['bg-green-500', 'bg-blue-500', 'bg-orange-500', 'bg-yellow-500'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div>
      <div className="overflow-x-auto min-h-screen hide-scrollbar">
        <table className="min-w-full mt-4">
          <thead>
            <tr className="bg-gray-100 border-b border-gray-200">
              <th className="py-2 px-4 border-b whitespace-nowrap">User</th>
              <th className="py-2 px-4 border-b whitespace-nowrap">Last Login</th>
              <th className="py-2 px-4 border-b text-end pl-5 whitespace-nowrap">Status</th>
            </tr>
          </thead>
          <tbody>
            {userss.map((user) => (
              <tr key={user.id} className="border-b text-xs border-gray-200">
                <td className="py-2 px-4 flex items-center whitespace-nowrap">
                  <div className={`${getRandomColor()} w-12 h-12 text-lg font-semibold rounded-full flex items-center justify-center`}>
                    {getInitials(user.name)}
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-sm font-bold">{user.name}</p>
                    <p className="text-xs">{user.email}</p>
                  </div>
                </td>
          
                
                <td className="py-2 px-4 whitespace-nowrap">{user.lastLoginAt}</td>
                <td className="py-2 px-4 relative">
                  <div className='flex items-center justify-end'>
                    <div className='mr-3'>
                      <p className='text-slate-500 text-sm font-semibold'>
                        active
                      </p>
                    </div>
                   
   
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;