import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';

const useAuth = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentAdmin, setCurrentAdmin] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', user.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        if (userData.role === 'admin') {
                            setCurrentAdmin(userData);
                            setError(''); // Clear error if user is admin
                        } else if (userData.role === 'user') {
                            if (userData.status === 'active' || userData.status === 'inactive') {
                                setCurrentUser(userData);
                                setError(''); // Clear error if user is active or inactive
                            } else if (userData.status === 'suspended' || userData.status === 'locked') {
                                setCurrentUser(null);
                                setError(`Your account is ${userData.status}.`);
                            } else {
                                setCurrentUser(null);
                                setError('User status is unknown.');
                            }
                        } else {
                            setCurrentUser(null);
                            setError('User role is unknown.');
                        }
                    } else {
                        setCurrentUser(null);
                        setError('User data not found.');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setCurrentUser(null);
                    setError('Error fetching user status.');
                }
            } else {
                setCurrentUser(null);
                setError('User is not authenticated.');
            }
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, []); // Empty dependency array to run only on mount

    return {
        currentUser,
        currentAdmin,
        error,
    };
};

export default useAuth;
