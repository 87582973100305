import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebase.config';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SyncIcon from '@mui/icons-material/Sync';
import CancelIcon from '@mui/icons-material/Cancel';
import { Helmet } from 'react-helmet-async';

const InvestmentsPage = () => {
  const [pendingInvestments, setPendingInvestments] = useState([]);
  const [endedInvestments, setEndedInvestments] = useState([]);
  const [cancelledInvestments, setCancelledInvestments] = useState([]);
  const navigate = useNavigate();
  const user = auth.currentUser;

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Redirect to login if user is not authenticated
      return;
    }

    const fetchInvestments = async () => {
      const investmentsRef = collection(db, 'users', user.uid, 'investment');

      const pendingQuery = query(investmentsRef, where('status', '==', 'running'));
      const endedQuery = query(investmentsRef, where('status', '==', 'ended'));
      const cancelledQuery = query(investmentsRef, where('status', '==', 'cancelled'));

      const pendingSnapshot = await getDocs(pendingQuery);
      const endedSnapshot = await getDocs(endedQuery);
      const cancelledSnapshot = await getDocs(cancelledQuery);

      const pendingList = pendingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const endedList = endedSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const cancelledList = cancelledSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setPendingInvestments(pendingList);
      setEndedInvestments(endedList);
      setCancelledInvestments(cancelledList);
    };

    fetchInvestments();
  }, [user, navigate]);

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = new Date(timestamp.toDate());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <div className="md:p-6 p-4 md:mx-4 bg-gray-100 min-h-screen">
      <Helmet><title>Investment List</title></Helmet>
      {pendingInvestments.length > 0 && (
        <div className="mt-4">
          <h3 className="text-xl text-start font-semibold mb-4">Pending Plan ({pendingInvestments.length})</h3>
          {pendingInvestments.map(investment => (
            <div key={investment.id} className="bg-white md:p-6 p-4 rounded-lg shadow-md flex justify-between items-center mb-4">
              <div className="flex items-center mb-2 md:mb-0">
                <div className='text-yellow-400 mr-3'><SyncIcon /></div>
                <div>
                  <p className="font-semibold text-lg">{investment.planName} - {investment.dailyInterest}% for {investment.termDays} Days</p>
                  <p className="text-sm text-gray-500">Invested: {Number(investment.investmentAmount).toFixed(2)} USD</p>
                  <p className='font-semibold text-sm text-gray-700'>Net Profit: {Number(investment.investmentProfit).toFixed(2)} USD</p>
                </div>
              </div>
              <div className="md:flex items-center space-x-4">
                <p className='text-sm text-gray-500 hidden md:block'>Start Date: {formatDate(investment.orderedDate)}</p>
                <p className='text-sm text-gray-500 hidden md:block'>End Date: {formatDate(investment.termEndAt)}</p>
                <button
                  onClick={() => navigate(`/dashboard/user/investment/${investment.id}`)}
                  className="text-blue-500 hover:underline mt-2 md:mt-0"
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {endedInvestments.length > 0 && (
        <div className="my-5">
          <h3 className="text-xl font-semibold mb-4 text-start">Recently Ended ({endedInvestments.length})</h3>
          {endedInvestments.map(investment => (
            <div key={investment.id} className="bg-white md:p-6 p-4 rounded-lg shadow-md flex justify-between items-center mb-4">
              <div className="flex items-center mb-2 md:mb-0">
                <div className='text-green-400 mr-3'><SyncIcon /></div>
                <div>
                  <p className="font-semibold text-lg">{investment.planName} - {investment.dailyInterest}% for {investment.termDays} Days</p>
                  <p className="text-sm text-gray-500">Invested: {Number(investment.investmentAmount).toFixed(2)} USD</p>
                  <p className='font-semibold text-sm text-gray-700'>Net Profit: {Number(investment.investmentProfit).toFixed(2)} USD</p>
                </div>
              </div>
              <div className="md:flex items-center space-x-4">
                <p className='text-sm text-gray-500 hidden md:block'>Start Date: {formatDate(investment.orderedDate)}</p>
                <p className='text-sm text-gray-500 hidden md:block'>End Date: {formatDate(investment.termEndAt)}</p>
                <button
                  onClick={() => navigate(`/dashboard/user/investment/${investment.id}`)}
                  className="text-blue-500 hover:underline mt-2 md:mt-0"
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {cancelledInvestments.length > 0 && (
        <div className="my-5">
          <h3 className="text-xl font-semibold mb-4 text-start">Cancelled Investments ({cancelledInvestments.length})</h3>
          {cancelledInvestments.map(investment => (
            <div key={investment.id} className="bg-white md:p-6 p-4 rounded-lg shadow-md flex justify-between items-center mb-4">
              <div className="flex items-center mb-2 md:mb-0">
                <div className='text-red-400 mr-3'><CancelIcon /></div>
                <div>
                  <p className="font-semibold text-lg">{investment.planName} - {investment.dailyInterest}% for {investment.termDays} Days</p>
                  <p className="text-sm text-gray-500">Invested: {Number(investment.investmentAmount).toFixed(2)} USD</p>
                  <p className='font-semibold text-sm text-gray-700'>Net Profit: {Number(investment.investmentProfit).toFixed(2)} USD</p>
                </div>
              </div>
              <div className="md:flex items-center space-x-4">
                <p className='text-sm text-gray-500 hidden md:block'>Start Date: {formatDate(investment.orderedDate)}</p>
                <p className='text-sm text-gray-500 hidden md:block'>End Date: {formatDate(investment.termEndAt)}</p>
                <button
                  onClick={() => navigate(`/dashboard/user/investment/${investment.id}`)}
                  className="text-blue-500 hover:underline mt-2 md:mt-0"
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InvestmentsPage;
