import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase.config';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const DepositSlip = () => {
  const { depositId } = useParams();
  const [deposit, setDeposit] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvestmentData = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      const userId = user.uid;
      const investmentRef = doc(db, 'users', userId, 'deposit', depositId);
      const investmentSnap = await getDoc(investmentRef);

      if (investmentSnap.exists()) {
        setDeposit(investmentSnap.data());
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    };

    fetchInvestmentData();
  }, [depositId, navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!deposit) {
    return <p>No investment data found.</p>;
  }

  return (
    <div className="text-slate-600 p-3 max-w-3xl mx-auto bgh">
      <div className="bg-white p-3 relative rounded-lg">
        <button
          onClick={() => navigate(-1)}
          className="absolute top-4 right-4 text-slate-500 hover:text-opacity-75"
        >
          <CloseIcon />
        </button>
        <h2 className="text-xl font-semibold text-start">
          Order ID <span className="text-green-600">#{deposit.transactionId}</span>
        </h2>

        <div className="flex justify-between mt-3 items-center border-b pb-4 mb-4">
          <div className="flex items-center">
            <div className="w-10 h-10 flex justify-center items-center mr-3 rounded-full bg-green-50 text-green-600">
              <CallReceivedIcon style={{ fontSize: 'medium' }} />
            </div>
            <div className="w-10 h-10 flex justify-center items-center mr-3 rounded-full bg-slate-300 text-slate-800">
              <AccountBalanceWalletIcon style={{ fontSize: 'medium' }} />
            </div>
            <div className="text-start">
              <p className="text-slate-700 text-lg font-semibold">{Number(deposit.paymentAmount).toFixed(2)} USD</p>
              <p className="text-gray-400 text-xs">{new Date(deposit.date.seconds * 1000).toLocaleString()}</p>
            </div>
          </div>
          <div className="bg-green-400 text-green-50 rounded-md px-3 py-2 text-sm font-semibold">
            {deposit.status}
          </div>
        </div>

        <div className="mb-4 text-start border-b-2">
          <h3 className="text-gray-600 mb-2">Deposit Details</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 text-start">
            <div>
              <p className="text-gray-400 text-xs">Payment Amount</p>
              <p className="text-gray-700 font-semibold">{deposit.paymentAmount} USDT</p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Credited in Account</p>
              <p className="text-gray-700 font-semibold">{deposit.paymentAmount} USD</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 text-start my-3">
            <div>
              <p className="text-gray-400 text-xs">Exchange Rate</p>
              <p className="text-gray-700 font-semibold">1 USD = 1 USDT</p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Details</p>
              <p className="text-gray-700 font-semibold">{deposit.details}</p>
            </div>
          </div>
        </div>

        <div className="mb-4 text-start border-b-2">
          <h3 className="text-gray-600 mb-2">Additional</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 text-start">
            <div>
              <p className="text-gray-400 text-xs">Payment Method</p>
              <p className="text-gray-700 font-semibold">{deposit.paymentMethod}</p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Payment To</p>
              <p className="text-gray-700 text-xs font-semibold">{deposit.paymentTo}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 text-start my-3">
            <div>
              <p className="text-gray-400 text-xs">Reference</p>
              <p className="text-gray-700 font-semibold">{deposit.reference || 'N/A'}</p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Updated Balance</p>
              <p className="text-gray-700 font-semibold">{deposit.status ==='completed' ? `${deposit.updatedBalance} USD` : 'N/A'}</p>
            </div>
          </div>
        </div>

        <div className="text-gray-600 text-sm text-start mt-4">
          <CheckCircleIcon className="text-green-500" /> The transaction has been completed at{' '}
          {new Date(deposit.date.seconds * 1000).toLocaleString()}.
        </div>
      </div>
    </div>
  );
};

export default DepositSlip;
