import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Header = () => {
  return (
    <div className='w-full py-2 hidden lg:flex justify-end bg-white items-center px-5 mr-10'>
      <span className='text-green-400'><AccountCircleIcon /></span>
      <div className='ml-3'>
                  <p className='text-grey-400 font-semibold text-xs text-start'>Admin</p>
                  <p className='text-green-500 font-bold capitalize text-start text-sm'>Super House</p>
                </div>
    </div>
  )
}

export default Header