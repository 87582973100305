import React, { useState } from 'react';

const MapView = ({mapSrc}) => {
  const [showMap, setShowMap] = useState(false);

  const toggleMap = () => {
    setShowMap(prevState => !prevState);
  };

  return (
    <div className="">
      <button className="bg-accent1 block hover:bg-opacity-75 text-white font-bold py-2 px-4 rounded mt-4" onClick={toggleMap}>
        {showMap ? 'Hide Map' : 'View Map'}
      </button>
      {showMap && (
        <iframe
          title='property map'
          src={mapSrc}
          width="600"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="mt-4"
        ></iframe>
      )}
    </div>
  );
};

export default MapView;
