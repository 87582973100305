import React from 'react';
import axios from 'axios';

const DepositComponent = () => {
  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const htmlContent = `
      <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: Arial, sans-serif; font-size: 14px; color: #333;">
        <tr>
          <td align="center" style="padding: 20px; background-color: #f7f7f7;">
            <table width="600" border="0" cellspacing="0" cellpadding="0" style="background-color: #fff; border: 1px solid #ddd;">
              <tr>
                <td align="center" style="padding: 20px;">
                  <img src="https://firebasestorage.googleapis.com/v0/b/hillview-housing.appspot.com/o/file%2F1722265076798?alt=media&token=8153bbc8-13ed-4aba-b638-83def2db1970" alt="Hillview" style="display: block; margin: 0 auto 10px; width: 100px;" />
                </td>
              </tr>
              <tr>
                <td style="padding: 20px; color: #333;">
                  <p>Thanks for joining our platform.</p>
                  <p>As a member of our platform, you can manage your account, buy and sell cryptocurrency.</p>
                  <p>Find out more about us at <a href="https://hillviewhousing.com" style="color: #0066cc; text-decoration: none;">https://hillviewhousing.com</a></p>
                </td>
              </tr>
              <tr>
                <td align="center" style="padding: 20px; background-color: #f7f7f7;">
                  Hillview Housing &copy; 2024
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      `;

      await axios.post("https://nodemailer-theta.vercel.app/send-email", {
        email: "fernandojerry19911500@gmail.com",
        subject: "Welcome to Hillview Housing",
        message: htmlContent
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      alert('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email');
    }
  };

  return (
    <div>
      <form onSubmit={handleSignIn}>
        <button type="submit">Send Test Email</button>
      </form>
    </div>
  );
};

export default DepositComponent;
