import React from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning';

const NoTransfer = () => {
    const navigate = useNavigate()
  return (
    <div className="bgh max-w-lg flex justify-center items-center text-slate-500 mx-auto">
      <div className=" flex p-6 flex-col items-center justify-center">
        <div className='w-20 h-20 rounded-full flex justify-center  bg-yellow-400 items-center text-white font-bold'><WarningIcon style={{}}/></div>
        <h2 className="text-xl mt-2 font-bold mb-3">Oops, insufficient balance!</h2>
        <p className="">You do not have enough funds in your investment account. Try again, once funds available.</p>
    
        <button onClick={() => navigate('/dashboard')} className="mt-1 text-sm font-semibold px-6 py-2  text-green-400 hover:text-green-600 ml-2">Return</button>
        
      </div>
    </div>
  )
}

export default NoTransfer
