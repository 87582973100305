import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { Helmet } from 'react-helmet-async';


const ProfileCard = ({ userInfo }) => {
  const formatDateOfBirth = (dateOfBirth) => {
    if (dateOfBirth && dateOfBirth.toDate) {
      return dateOfBirth.toDate().toISOString().split('T')[0];
    }
    return "Not added yet";
  };

  return (
    <div className="bgh text-start text-slate-500 bg-bgColor-600 overflow-hidden">
      <Helmet><title>Profile</title></Helmet>
      <div className='mb-4'>
        <p className='text-xl font-semibold'>Personal Information</p>
        <p className='text-sm font-medium my-1'>Basic info, like your name and address, that you use on our platform.</p>
      </div>
      <div className="bg-white pt-3 rounded-md px-2">  
        <div className="space-y-4 ">
          <div className="grid grid-cols-3 py-2 border-b-2 md:px-4">
            <span className="font-medium">Full Name:</span>
            <span className='col-span-2'>{userInfo.name}</span>
          </div>
          <div className="grid grid-cols-3 py-2 border-b-2 md:px-4">
            <span className="font-medium">Email:</span>
            <div className='col-span-2 flex text-sm font-bold justify-between'>
              <span>{userInfo.email}</span>
              <span className='text-slate-300'><LockIcon style={{ fontSize: 'small' }} /></span>
            </div>
          </div>
          <div className="grid grid-cols-3 py-2 border-b-2 md:px-4">
            <span className="font-medium">Phone Number:</span>
            <span className='col-span-2'>{userInfo.phone || "Not added yet"}</span>
          </div>
          <div className="grid grid-cols-3 py-2 border-b-2 md:px-4">
            <span className="font-medium">Date of Birth:</span>
            <span className='col-span-2'>{formatDateOfBirth(userInfo.dateOfBirth)}</span>
          </div>
          <div className="grid grid-cols-3 py-2 border-b-2 md:px-4">
            <span className="font-medium">Country:</span>
            <span className='col-span-2'>{userInfo.country || "Not added yet"}</span>
          </div>
          <div className="grid grid-cols-3 py-2 border-b-2 md:px-4">
            <span className="font-medium">State:</span>
            <span className='col-span-2'>{userInfo.stateAddress || "Not added yet"}</span>
          </div>
          <div className="grid grid-cols-3 py-2 border-b-2 md:px-4">
            <span className="font-medium">Address:</span>
            <span className='col-span-2'>{userInfo.address || "Not added yet"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
