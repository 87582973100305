import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, updateEmail, sendEmailVerification, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { toast } from 'react-toastify';

const ChangeEmail = () => {
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setCurrentEmail(user.email);
    }
  }, [auth]);

  const handleSendVerificationEmail = async () => {
    setIsProcessing(true);
    setError('');

    try {
      // Send verification email to new email address
      await sendEmailVerification(auth.currentUser, {
        url: window.location.href,
      });

      // Save new email to localStorage to use later in handleChangeEmail
      window.localStorage.setItem('newEmailForVerification', newEmail);

      toast.success('Verification email sent to new email address. Please verify your email.');
      setVerificationSent(true);

      // Wait for 2 minutes (120 seconds) before attempting to update the email
      setTimeout(() => {
        handleChangeEmail();
      }, 120000);
    } catch (error) {
      setError(error.message);
      setIsProcessing(false);
    }
  };

  const handleChangeEmail = async () => {
    setIsProcessing(true);
    setError('');

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);
    const newEmail = window.localStorage.getItem('newEmailForVerification');

    try {
      // Reauthenticate user
      await reauthenticateWithCredential(user, credential);

      // Update email
      await updateEmail(user, newEmail);
      toast.success('Email address updated successfully.');

      // Clear localStorage
      window.localStorage.removeItem('newEmailForVerification');

      navigate('/profile');
    } catch (authError) {
      setError(authError.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="p-6 max-w-xl text-start mx-auto">
      <h2 className="text-2xl font-bold mb-4">Change Email</h2>
      
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Current Email Address</label>
        <input
          type="text"
          value={currentEmail}
          readOnly
          className="mt-1 p-3 border border-gray-300 rounded w-full bg-gray-100 cursor-not-allowed"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">New Email Address</label>
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className="mt-1 p-3 border border-gray-300 rounded w-full"
          disabled={verificationSent || isProcessing}
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Current Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 p-3 border border-gray-300 rounded w-full"
          disabled={verificationSent || isProcessing}
        />
      </div>

      <div className="flex items-center">
        {!verificationSent ? (
          <button
            onClick={handleSendVerificationEmail}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400 transition-colors duration-300 flex items-center"
            disabled={isProcessing}
          >
            {isProcessing ? 'Sending...' : 'Send Verification Email'}
          </button>
        ) : (
          <p className="text-blue-500">Waiting for email verification...</p>
        )}
        <button
          onClick={() => navigate(-1)}
          className="text-red-500 px-4 py-2 ml-4 hover:bg-gray-400 transition-colors duration-300"
          disabled={isProcessing}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ChangeEmail;
