import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const UserTransactions = () => {
  const { userId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const navigate= useNavigate()

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const depositQuery = collection(db, `users/${userId}/deposit`);
        const withdrawQuery = collection(db, `users/${userId}/withdraw`);

        const [depositSnapshot, withdrawSnapshot] = await Promise.all([
          getDocs(depositQuery),
          getDocs(withdrawQuery),
        ]);

        const depositTransactions = depositSnapshot.docs.map(doc => ({
          ...doc.data(),
          type: 'deposit',
        }));
        const withdrawTransactions = withdrawSnapshot.docs.map(doc => ({
          ...doc.data(),
          type: 'withdraw',
        }));

        const combinedTransactions = [...depositTransactions, ...withdrawTransactions];
        console.log(combinedTransactions)
        setTransactions(combinedTransactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [userId]);

  if (transactions.length === 0) {
    return <div>No transactions found.</div>;
  }

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = new Date(timestamp.toDate());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };


  return (
<div className='bgh'>
<div className="flex items-center justify-between px-6 text-start mb-4">
         <div className='font-medium'>
         <h1 className="text-2xl font-semibold mb-2">Transactions</h1>
         <p className="text-sm text-gray-400">Account ID: {userId}</p> 
         </div>
         <div>
            <button className='border p-2 rounded' onClick={()=> navigate(-1)}><KeyboardBackspaceIcon style={{fontSize: 'small'}}/> back</button>
         </div>
        </div>
      <div className="overflow-auto min-h-screen hide-scrollbar">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Details</th>
              <th className="py-2 px-4 border-b">Order</th>
              <th className="py-2 px-4 border-b text-end pl-5">Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((data) => (
              <tr key={data.id} className="border-b">
                <td className="py-2 px-4 flex items-center">
                  <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'>
                    {data.type === 'deposit' ? <CallReceivedIcon /> : data.type === 'withdraw' ? (<div className='text-red-500'><CallMadeIcon /></div>) : (<div className='text-yellow-500'><SyncAltIcon /></div>)}
                  </div>
                  <div>
                    <p className='text-sm font-semibold'>{data.type === 'deposit' ? 'Deposit Funds' : data.type === 'withdraw' ? 'Withdraw Funds' : 'Invested on Plan'}</p>
                    <div className='flex'>
                      <p className='text-xs pt-1 text-gray-500'>{new Date((data.date?.seconds || data.orderedDate?.seconds) * 1000).toLocaleDateString()}</p>
                      <p className={`text-sm ml-3 ${data.status === 'cancelled' ? 'text-red-500' : 'text-gray-500'}`}>{data.status}</p>
                    </div>
                  </div>
                </td>
                
                <td className="py-2 px-4 text-xs pt-1 text-gray-500">{data.invoiceNo || data.transactionId}</td>
                <td className="py-2 px-4">
                  <div className='flex items-center justify-end relative'>
                    <div className='mr-3'>
                      <p className={`text-lg font-semibold ${data.type === 'deposit' ? 'text-green-500' : data.type === 'withdraw' ? 'text-red-500' : 'text-yellow-500'}`}>
                        {data.type === 'deposit' ? (<span>+ {Number(data.paymentAmount).toFixed()}</span>) : data.type === 'withdraw' ? (<span>- {Number(data.withdrawalAmount).toFixed()}</span>) : (<span>+ {Number(data.investmentAmount).toFixed()}</span>)}USD
                      </p>
                      <p className='text-slate-500 text-sm font-semibold'>
                        {data.type === 'deposit' ? Number(data.paymentAmount).toFixed(4) : data.type === 'withdraw' ? Number(data.withdrawalAmount).toFixed(4) : Number(data.investmentAmount).toFixed(4)}USDT
                      </p>
                    </div>
                   
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTransactions;
