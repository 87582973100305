import React from 'react';
import Slider from 'react-slick';
import "../Slider/Slider.css";
import "../Slider/Slider-theme.css";
import Heading from '../Headings/Heading';
import { Container } from 'react-bootstrap';




const TestimonialCarousel = ({testimonials}) => {
 

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <Container className=" mx-auto py-4">
        <Heading class1='text-center' class5='text-center' text5='TESTIMONIAL' text1='What Our Clients Say' child='About Us' classname1='text-center' classname2='pt-4'/>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="px-8 py-2">
            <div className="bg-gray-100 rounded-lg shadow-md p-4">
              <img src={testimonial.image} alt={testimonial.name} className="rounded-full w-20 h-20 mx-auto mb-4 object-contain" />
              <p className="text-gray-800 text-base text-center mb-4">{testimonial.body}</p>
              <p className="text-gray-600 text-center font-bold">{testimonial.name}</p>
              <p className="text-Primary-500 text-center font-semibold">Investor</p>
            </div>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default TestimonialCarousel;
