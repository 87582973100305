import React, { useState } from 'react';
import { Col, Container, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase.config'; // Ensure db is imported from your firebase.config
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../Assets/Images/register.jpeg';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import logo from '../Assets/Images/brand.png';
import axios from 'axios';
import { collection, addDoc, getDoc, doc } from 'firebase/firestore'; // Import necessary Firestore methods

// Utility function to format date
const formatDate = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Function to get the ordinal suffix
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return null;
    }
  };

  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'Chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'Firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'Safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'Opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'Edge';
    }
    return browserName;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.exists() ? userDoc.data() : null;

        if (userData) {
          const ipAddress = await fetchIpAddress();
          const loginTime = formatDate(new Date());
          const browserName = getBrowserInfo();

          const activitiesRef = collection(db, 'users', user.uid, 'activities');
          await addDoc(activitiesRef, {
            loginTime: loginTime,
            ipAddress: ipAddress,
            browserName: browserName,
          });

          setLoading(false);

          if (userData.role === 'admin') {
            navigate('/admin/dashboard');
          } else if (userData.role === 'user') {
            if (userData.status === 'active' || userData.status === 'inactive') {
              navigate('/dashboard');
            } else {
              auth.signOut();
              alert(`Your account is ${userData.status}. contact support for more details`);
            }
          } else {
            auth.signOut();
          }
          
        } else {
          setLoading(false);
          auth.signOut();
          toast.error('User data not found.');
        }
      } else {
        setLoading(false);
        auth.signOut();
        toast.error('Please verify your email before logging in.');
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div
      
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
     <Helmet>
        <title>Login</title>
      </Helmet>
      <Container>
        <div className='p-3 max-w-lg mx-auto'>
            <div lg={6} className='m-auto py-10 text-center'>
              <Link to='/'><div className=""><img src={logo} alt="footerLogo " className='h-20 w-30 z-50 rounded-lg bg-slate-300 object-contain' /></div></Link>
              <h1 className='text-4xl text-center text-white font-semibold my-7'>
                Login
              </h1>
              <Form className='flex flex-col gap-4' onSubmit={handleLogin}>
                <label className='text-white text-start' htmlFor='email'>Email</label>
                <input
                  id='email'
                  className='border p-3 rounded-lg'
                  type='email'
                  placeholder='Enter Your Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className='text-white text-start' htmlFor='password'>Password</label>
                <input
                  id='password'
                  className='border p-3 rounded-lg'
                  type='password'
                  placeholder='Enter Your Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  disabled={loading}
                  className='bg-accent1 text-bgColor-200 font-medium p-3 rounded-lg uppercase hover:opacity-75 disabled:opacity-80'
                >
                  {loading ? <Spinner animation="border" size="sm" /> : 'Login'}
                </button>
                <p className='text-white font-semibold cursor-pointer'>
                  Don't have an account?{' '}
                  <Link to='/auth/register' className='text-blue-700'>Create an account</Link>
                </p>
              </Form>
            </div>
        </div>
      </Container>
    </div>
  );
}

export default Login;
