import React from "react";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import SimpleBtn from "../Buttons/SimpleBtn";
import "./Slider.css";
import "./Slider-theme.css";

function SimpleSlider({ slideData }) {
  const settings = {
    dots: false,
    autoplaySpeed: 6000,
    autoplay: true,
    infinite: true,
    speed: 700,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings}>
        {slideData.map((info, index) => (
          <div key={index} className="slide__container  w-full" >
            <div className="h-full w-full" style={{ backgroundImage: `url(${info.bgImg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
              <Container fluid className="lg:pl-14 h-full w-full slide-content text-slate-100 bg-overlay">
                <div>
                  <div className="flex justify-center items-center flex-col w-full h-full px-2 py-5">
                    <h1 className="poph5 text-lg md:text-xl font-semibold pb-3">{info.header2}</h1>
                    <h2 className="text-2xl md:text-3xl font-bold pb-10">{info.header1}</h2>
                  </div>
                  <div className='font-semibold'>
                    <SimpleBtn text="Register Now" classname="popBtnL bg-green-600 px-3 md:px-5 mr-3 py-2 md:py-3 text-lg text-white" link="/auth/register" />
                    <SimpleBtn text="Login" classname="popBtnR text-accent1 hover:bg-accent1 hover:text-white text-lg border-1 border-accent1 px-3 md:px-5 py-2 md:py-3" link="/auth/login" />
                  </div>
                </div>
              </Container>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SimpleSlider;
