import React from 'react'
import { Link } from 'react-router-dom'

const SimpleBtn = ({classname, text,link}) => {
  return (
    <Link to={link}><button className={`font-semibold rounded-sm hover:opacity-80 ${classname}`}>
    {text}
  </button></Link>
  )
}

export default SimpleBtn
