import React from 'react';
import { Helmet } from 'react-helmet-async';

const TermsAndConditions = () => {
  return (
    <div className='p-5 max-w-4xl mx-auto'>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <h1 className='text-3xl font-semibold mb-5'>Terms and Conditions</h1>
      <div className='space-y-4'>
        <section>
          <h2 className='text-2xl font-semibold'>1. Introduction</h2>
          <p>
            Welcome to Hillview Housing. These terms and conditions outline the rules and regulations for the use of Hillview Housing's Website and services. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Hillview Housing's website if you do not accept all of the terms and conditions stated on this page.
          </p>
          <p>
            Hillview Housing reserves the right to change, modify, or remove portions of these terms at any time. It is your responsibility to check these terms periodically for changes. Your continued use of the site following the posting of changes will mean that you accept and agree to the changes.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>2. Investment Services</h2>
          <p>
            Hillview Housing provides an online platform that allows users to invest in various financial instruments, including stocks, bonds, real estate, and other securities. Our goal is to help you grow your wealth by providing access to a diversified range of investment opportunities.
          </p>
          <p>
            The investments offered through our platform are subject to market conditions and the performance of the underlying assets. As such, investments can increase or decrease in value. However, Hillview Housing guarantees the principal amount of your investment.
          </p>
          <p>
            By using our services, you acknowledge that while all investments carry inherent risks, Hillview Housing ensures the return of your initial investment amount. In cases where there is a loss in trade, Hillview Housing will refund users with a bonus, which can be used to reinvest.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>3. Account Registration</h2>
          <p>
            To use our services, you must register for an account on our website. During the registration process, you will be required to provide accurate and complete information. You agree to keep your account information up-to-date and accurate at all times.
          </p>
          <p>
            You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account or password.
          </p>
          <p>
            If you suspect any unauthorized use of your account, you must notify us immediately. Hillview Housing will not be liable for any loss or damage arising from your failure to comply with this obligation.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>4. Investments and Profits</h2>
          <p>
            By investing through Hillview Housing, you agree that your investments are subject to market risk and that the value of your investments may fluctuate. However, Hillview Housing guarantees the principal amount of your investment.
          </p>
          <p>
            Profits are calculated daily based on the performance of your investments and will be credited to your account. The daily profit calculation will be based on the net asset value (NAV) of the invested funds and the type of investment you have chosen. The type of investment determines the percentage of profit, with different financial instruments and real estate investments having varied profit rates.
          </p>
          <p>
            All investments are subject to our standard fees and charges, which will be clearly outlined at the time of investment. We reserve the right to change our fee structure at any time, and such changes will be communicated to you in advance.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>5. Withdrawals</h2>
          <p>
            Users can request to withdraw their profits daily. Withdrawals are processed according to our withdrawal schedule and may take up to 24 hours to reflect in your bank account or other payment methods.
          </p>
          <p>
            We reserve the right to impose withdrawal limits and fees, which will be disclosed to you prior to your withdrawal request. Withdrawal requests may be subject to verification processes to ensure the security of your funds.
          </p>
          <p>
            Hillview Housing is not responsible for any delays or issues arising from third-party payment processors or financial institutions.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>6. User Obligations</h2>
          <p>
            You agree to use Hillview Housing's services in compliance with all applicable laws and regulations. You agree not to use our services for any unlawful or fraudulent activities, including but not limited to money laundering, fraud, or terrorist financing.
          </p>
          <p>
            You are solely responsible for any activities conducted through your account. Any misuse of your account, including unauthorized transactions, must be reported to us immediately.
          </p>
          <p>
            You agree to indemnify and hold Hillview Housing harmless from any claims, damages, losses, or expenses, including legal fees, arising out of or in connection with your use of our services.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>7. Limitation of Liability</h2>
          <p>
            Hillview Housing will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services. This includes, but is not limited to, any loss of profits, investment losses, or damages resulting from delays or interruptions in service.
          </p>
          <p>
            Our total liability to you for any claims arising out of or in connection with our services shall not exceed the amount you paid to use our services in the twelve (12) months preceding the claim.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>8. Privacy Policy</h2>
          <p>
            Your use of our website and services is also governed by our Privacy Policy. By using our services, you agree to the collection and use of your information as described in the Privacy Policy.
          </p>
          <p>
            We take your privacy seriously and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information.
          </p>
          <p>
            If you have any questions about our Privacy Policy, please contact us at support@hillviewhousing.com.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>9. Changes to Terms</h2>
          <p>
            Hillview Housing reserves the right to revise these terms and conditions at any time. We will notify you of any changes by posting the new terms on our website. Your continued use of the services after any changes will constitute your acceptance of the new terms and conditions.
          </p>
          <p>
            It is your responsibility to review these terms periodically. If you do not agree to the updated terms, you must stop using our services.
          </p>
          <p>
            Any changes to these terms will become effective immediately upon posting, and your continued use of our services will indicate your acceptance of the new terms.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold'>10. Contact Information</h2>
          <p>
            If you have any questions about these terms and conditions, please contact us at support@hillviewhousing.com. We are here to help and will respond to your inquiries as quickly as possible.
          </p>
          
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
