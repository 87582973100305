import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink,Link} from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Container} from 'react-bootstrap'
import './Header.css'
import { useState} from 'react';
import brand from '../../Assets/Images/brand.png'
import GoogleTranslateWidget from '../GoogleTranslate';
import SimpleBtn from '../Buttons/SimpleBtn';



const Header = () => {

  
  const [expanded, setExpanded] = useState(false)
  const normalLink = 'text-textColor-100';
  const activeLink = 'active'
  
  
  return (  
        <Navbar expanded={expanded} sticky='top' expand="lg"  className='shadow-lg my-nav text-sm px-10'>
        <Container className='px-2 md:px-10'>
          <div  className=' w-32'><Link to='/'><img className='w-full h-full object-contain' src={brand} alt="" /></Link></div>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : 'expanded')}  aria-controls="responsive-navbar-nav" className='d-flex d-lg-none flec-column justify-content-around'>
                <span className='toggler-icon top-bar'></span>
                <span className='toggler-icon mid-bar'></span>
                <span className='toggler-icon bottom-bar'></span>
            </Navbar.Toggle>
            
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="flex ms-auto lg:justify-around items-start text-lg lg:items-center gap-4">
                    <div className='pt-2'></div>
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? `${activeLink} ` : `${normalLink} nav-item  `)} to="/">Home</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item `)} to="/about-us">About</NavLink>
                   
                    <NavDropdown className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item text-lg text-textColor-100`)}  title="Services" id="collapsible-nav-dropdown">
                    <NavDropdown.Item><NavLink onClick={() => setExpanded(false)} to='services/cryptocurrency'>CryptoCurrency</NavLink></NavDropdown.Item>
                      <NavDropdown.Item><NavLink onClick={() => setExpanded(false)} to='/services/real-estate'>Real Estate</NavLink></NavDropdown.Item>
                    </NavDropdown>
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item `)} to="/pricing">Pricing</NavLink>
                    <NavLink onClick={() => setExpanded(false)} className={({isActive}) => (isActive? activeLink : `${normalLink} nav-item `)} to="/contact">Contact</NavLink>
                    <GoogleTranslateWidget />
                    <SimpleBtn text='Login' link='/auth/login' classname='bg-accent1 text-bgColor-200 px-3 py-2 '/>
                    
                    
        </Nav>
        
        </Navbar.Collapse>
        
        </Container>
        </Navbar>
  )
}

export default Header
