import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db, auth } from '../../firebase.config'; // Ensure db and auth are imported from your firebase.config

const ActivityLogger = () => {
  const [activities, setActivities] = useState([]);
  const user = auth.currentUser;

  useEffect(() => {
    const fetchActivities = async () => {
      console.log('Fetching activities...');

      if (user) {
        console.log('User is authenticated:', user);

        const activitiesRef = collection(db, 'users', user.uid, 'activities');
        console.log('Activities reference created:', activitiesRef);

        const activitiesQuery = query(activitiesRef, orderBy('loginTime', 'desc'), limit(20));
        console.log('Activities query created:', activitiesQuery);

        try {
          const querySnapshot = await getDocs(activitiesQuery);
          console.log('Query snapshot received:', querySnapshot);

          const activitiesData = querySnapshot.docs.map(doc => doc.data());
          console.log('Activities data mapped:', activitiesData);

          setActivities(activitiesData);
          console.log('Activities state updated');
        } catch (error) {
          console.error('Error fetching activities:', error);
        }
      } else {
        console.log('No authenticated user found');
      }
    };

    fetchActivities();
  }, [user]);

  return (
    <div className="p-2">
    <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4">Activity on your account</h2>
    <p className="text-xs sm:text-sm mb-2 sm:mb-4">Here is your last 20 login activities log.</p>
    <div className="bg-white p-2 sm:p-4 border rounded-lg overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-3 px-2 sm:px-4 border-b">BROWSER</th>
            <th className="py-3 px-2 sm:px-4 border-b">IP</th>
            <th className="py-3 px-2 sm:px-4 border-b">TIME</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity, index) => (
            <tr key={index}>
              <td className="py-1 text-sm text-slate-400 sm:py-2 px-2 sm:px-4 border-b">{activity.browserName}</td>
              <td className="py-1 text-sm text-slate-400 sm:py-2 px-2 sm:px-4 border-b">{activity.ipAddress}</td>
              <td className="py-1 text-sm text-slate-400 sm:py-2 px-2 sm:px-4 border-b">{activity.loginTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default ActivityLogger;
