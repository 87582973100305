import React, { useState } from 'react';
import { db } from '../firebase.config';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ManuallyAddDeposit = ({ accounts }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    account: '',
    amount: '',
    description: '',
    remarks: '',
    transactionType: 'Direct Deposit',
    method: 'Manual / Direct'
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { account, amount, transactionType, method } = formData;
    const generateTransactionId = () => {
      return `TNX${Math.floor(10000000 + Math.random() * 90000000)}`;
    };

    if (!account || !amount) {
      toast.error('Please fill in all required fields.');
      return;
    }
    const transactionId = generateTransactionId();

    setLoading(true);
    try {
      // Get user document reference
      const userDocRef = doc(db, 'users', account);
      // Fetch the current balance
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const currentBalance = userDoc.data().balance || 0;
        const newBalance = currentBalance + parseFloat(amount);
      
        // Calculate updated balance
        const updatedBalance = parseFloat(currentBalance) + parseFloat(amount);

        // Update the balance in the user's document
        await updateDoc(userDocRef, { balance: newBalance });

        // Add the deposit transaction
        await addDoc(collection(db, `users/${account}/deposit`), {
          paymentAmount: parseFloat(amount),
          date: new Date(),
          details: transactionType,
          paymentMethod: method,
          status: 'completed',
          transactionId,
          depositTime: new Date(),
          updatedBalance,
        });

        setFormData({
          account: '',
          amount: '',
          description: '',
          remarks: '',
          transactionType: 'Direct Deposit',
          method: 'Manual / Direct'
        });
        toast.success('Deposit added and balance updated successfully!');
        navigate(-1);
      } else {
        toast.error('User account not found.');
      }
    } catch (error) {
     
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white my-5 text-start shadow-md flex rounded p-2 items-center max-w-2xl mx-auto justify-center min-h-screen">
      <div className="w-full md:px-4 md:py-2">
        <h2 className="text-xl font-semibold mb-4">Manually Add Deposit</h2>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className="mb-2">
              <label htmlFor="amount" className="block text-gray-700 font-semibold mb-1">Amount *</label>
              <div className="relative">
                <input
                  type="number"
                  id="amount"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="0.00"
                  value={formData.amount}
                  onChange={handleChange}
                />
                <span className="absolute inset-y-0 right-4 flex items-center text-gray-500">USD</span>
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="account" className="block text-gray-700 font-semibold mb-1">Add to Account *</label>
              <select
                id="account"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.account}
                onChange={handleChange}
              >
                <option value="">Select Account</option>
                {accounts.map((acc) => (
                  <option key={acc.userId} value={acc.userId}>
                    {acc.userName}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="transactionType" className="block text-gray-700 font-semibold mb-1">Transaction Type *</label>
              <select
                id="transactionType"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.transactionType}
                onChange={handleChange}
              >
                <option value="Direct Deposit">Direct Deposit</option>
                <option value="Crypto Deposit">Crypto Deposit</option>
                <option value="Bonus">Bonus</option>
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="method" className="block text-gray-700 font-semibold mb-1">Method *</label>
              <select
                id="method"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.method}
                onChange={handleChange}
              >
                <option value="Manual / Direct">Manual / Direct</option>
                <option value="System Default">System Default</option>
              </select>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block text-gray-700 font-semibold mb-1">Description</label>
            <input
              type="text"
              id="description"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Eg. Credited Balance"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="remarks" className="block text-gray-700 font-semibold mb-1">Note / Remarks</label>
            <input
              type="text"
              id="remarks"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.remarks}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center justify-between">
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-700" disabled={loading}>
              {loading ? 'Processing...' : 'Add Deposit'}
            </button>
            <button onClick={() => navigate(-1)} type="button" className="text-gray-500 hover:text-gray-700">Cancel</button>
          </div>
        </form>
        <p className="text-sm text-gray-500 mt-4">
          <span className="block">Add Bonus/Deposit: Amount will add/credit into account balance.</span>
          <span className="block">Leaving blank description will add 'Debited Balance' or 'Credited Balance' based on type.</span>
          <span className="block">You can not undo this action once you confirmed to add.</span>
        </p>
      </div>
    </div>
  );
};

export default ManuallyAddDeposit;
