import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const UserInfo = () => {
    const navigate = useNavigate()
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, [userId]);

  if (!user) {
    return <div>Loading...</div>;
  }
  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = new Date(timestamp.toDate());
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };
  const getDisplayName = (name) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    const lastNameInitial = nameParts[nameParts.length - 1];
    return lastNameInitial;
  };

  return (
    <div className="bgh container mx-auto p-4 text-start">
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center justify-between mb-4">
         <div className='font-medium'>
         <h1 className="text-2xl font-semibold mb-2">User / <span className="font-bold text-green-500 capitalize">{user.name}</span></h1>
         <p className="text-sm text-gray-600">Account ID:</p>
         <span className="text-xs text-gray-600">{userId}</span>
         <p className="text-sm  text-gray-600 ">Account Status: {user.status}</p>
         </div>
         <div>
            <button className='border p-2 rounded text-xs' onClick={()=> navigate(-1)}><KeyboardBackspaceIcon style={{fontSize: 'small'}}/>back</button>
         </div>
        </div>
        <div className="grid grid-cols-1 gap-6">
          <div>
            <h2 className="text-lg font-semibold mb-2">Personal Information</h2>
            <p className="text-sm text-gray-600">Basic info, like your name and address, that you use on our Platform.</p>
            <div className="mt-4 space-y-3">
              <p className="text-sm capitalize"><span className="font-semibold">Full Name:</span> {user.name}</p>
              <p className="text-sm capitalize"><span className="font-semibold">Display Name:</span> {getDisplayName(user.name)}</p>
              <p className="text-sm"><span className="font-semibold">Email Address:</span> {user.email}</p>
              <p className="text-sm"><span className="font-semibold">Date of Birth:</span> {formatDate(user.dateOfBirth)}</p>
              <p className="text-sm"><span className="font-semibold">Mobile Number:</span> {user.telephone}</p>
            </div>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Additional Information</h2>
            <div className="mt-4 space-y-2">
              <p className="text-sm"><span className="font-semibold">Join Date:</span> {user.createdAt}</p>
              
              <p className="text-sm"><span className="font-semibold">Reg Method:</span> By Email</p>
              <p className="text-sm"><span className="font-semibold">Save Activity Logs:</span> Enabled</p>
              <p className="text-sm"><span className="font-semibold">Country:</span> {user.country}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
