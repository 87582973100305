import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import About from '../Pages/About'
import Contact from '../Pages/Contact'
import Pricing from '../Pages/Pricing'
import CryptoCurrency from '../Pages/CryptoCurrency'
import RealEstate from '../Pages/RealEstate'
import PropertyDetail from '../Pages/PropertyDetail'
import SignUp from '../Pages/SignUp'
import Login from '../Pages/SignIn'
import WelcomePage from '../Pages/WelcomePage'
import Dashboard from '../User/Dashboard'
import Admindashboard from '../Admin/Admindashboard'
import ProtectedRoutes from './ProtectedRoutes'
import TermsAndConditions from '../Pages/TermsAndConditions'
import DepositComponent from '../Pages/Test'



const Router = () => {
  
  return (
    <Routes>
      <Route path='/' element={<Home />}/>
      <Route path='/about-us' element={<About />}/>
      <Route path='/services/real-estate' element={<RealEstate />}/>
      <Route path='/pricing' element={<Pricing />}/>
      <Route path='/contact' element={<Contact />}/>
      <Route path='/services/cryptocurrency' element={<CryptoCurrency />}/>
      <Route path="/property/:id" element={<PropertyDetail />} />
      <Route path='/auth/register' element={<SignUp />}/>
      <Route path='/auth/login' element={<Login />}/>
      <Route path='/welcome' element={<WelcomePage />}/>
      <Route path='/test' element={<DepositComponent />}/>
      <Route path='/terms/conditions' element={<TermsAndConditions />}/>

      <Route path='/*' element={<ProtectedRoutes />}>
                    <Route path='dashboard' element={<Dashboard />} />
                    <Route path='admin/dashboard' element={<Admindashboard />} />
                </Route>
      
    </Routes>
  )
}

export default  Router
