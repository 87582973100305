import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { Link, useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ConstructionIcon from '@mui/icons-material/Construction';

const Header = ({ currentUser }) => {
  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  };
  
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleNavigation = (path, section) => {
    closeDropdown();
    navigate(`${path}?section=${section}`);
  };

  return (
    <div className='w-full py-2 hidden lg:flex justify-end bg-white items-center px-5 mr-10'>
      <span className='text-green-400 cursor-pointer' onClick={toggleDropdown}>
        <AccountCircleIcon />
      </span>
      <div className='flex items-baseline'>
        <div className='ml-3 relative text-slate-500 cursor-pointer' onClick={toggleDropdown}>
          <p className='text-green-400 font-semibold text-xs text-start'>verified</p>
          <p className='text-slate-500 font-bold capitalize text-start text-sm'>
            {currentUser.name} <span><KeyboardArrowDownIcon style={{ fontSize: 'medium' }} /></span>
          </p>
        </div>
        <div className=''></div>
      </div>
      {isOpen && (
        <div
          className="absolute right-8 top-11 mt-2 min-w-72 border-t-4 border-green-500 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button" 
          tabIndex="-1"
        >
          <div className="text-start" role="none">
            <div className="px-4 py-3 text-sm text-white flex bg-slate-100">
              <div className="font-bold text-white mr-3 bg-red-400 p-3 rounded-full">{getInitials(currentUser.name)}</div>
              <div>
                <div className="text-base font-semibold text-green-500">{currentUser.name}</div>
                <div className="text-sm text-gray-500">{currentUser.email}</div>
              </div>
            </div>
            <div className="px-4 py-2 text-sm text-gray-700" role="menuitem">
              <div className="font-semibold">Account Balance</div>
              <div className="text-xl my-1 font-semibold text-green-500">{currentUser.balance} USD</div>
              <div className="text-sm text-gray-500">0.00 EUR</div>
            </div>
            <div className="border-gray-100"></div>
            
            <Link
              to='/dashboard/user/deposit/wallet'
              className="block px-4 py-1 text-sm text-green-500 hover:bg-green-300"
              role="menuitem"
              onClick={closeDropdown}
            >
              Deposit Funds <span className='text-green-500 ml-2'><CreditCardIcon style={{ fontSize: 'medium' }} /></span>
            </Link>
            <Link
              to='/dashboard/user/withdraw'
              className="block mb-2 px-4 py-1 text-sm text-green-500 hover:bg-green-300"
              role="menuitem"
              onClick={closeDropdown}
            >
              Withdraw Funds <span className='text-green-500 ml-2'><CreditScoreIcon style={{ fontSize: 'medium' }} /></span>
            </Link>
           
            <div className="border-t mb-3 border-gray-100"></div>
            <div
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => handleNavigation('/dashboard/user/profile', 'profile')}
            >
              <span className="text-slate-400 mr-2"><PersonOutlineIcon style={{ fontSize: 'medium' }} /></span> View Profile
            </div>
            <div
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => handleNavigation('/dashboard/user/profile', 'security')}
            >
              <span className="text-slate-400 mr-2"><SettingsIcon style={{ fontSize: 'medium' }} /></span> Security Settings
            </div>
            <div
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => handleNavigation('/dashboard/user/profile', 'activity')}
            >
              <span className="text-slate-400 mr-2"><ConstructionIcon style={{ fontSize: 'medium' }} /></span> Login Activity
            </div>
            <Link
              to='/logout'
              className="block px-4 mb-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={closeDropdown}
            >
              <span className="text-slate-400 mr-2"><LogoutIcon style={{ fontSize: 'medium' }} /></span> Sign out
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
