const investmentPlans = [
    {
      stat: 'Promo Plan',
      name: "Diamond Plan",
      dailyInterest: 15,
      termDays: 5,
      minDeposit: 2000.00,
      maxDeposit: 100000.00,
      capitalReturn: "Each Term",
      totalReturn: 75,
      buttonText: "Invest Now",
    },
    {
      name: "Cardinal Plan",
      dailyInterest: 1.5,
      termDays: 7,
      minDeposit: 200.00,
      maxDeposit: 4999.00,
      capitalReturn: "Each Term",
      totalReturn: 10.5,
      buttonText: "Invest Now",
    },
    {
      name: "Pennant Plan",
      dailyInterest: 2,
      termDays: 7,
      minDeposit: 5000.00,
      maxDeposit: 14999.00,
      capitalReturn: "Each Term",
      totalReturn: 14,
      buttonText: "Invest Now",
    },
    {
      name: "Golden Plan",
      dailyInterest: 2.5,
      termDays: 7,
      minDeposit: 15000.00,
      maxDeposit: 29999.00,
      capitalReturn: "Each Term",
      totalReturn: 17.5,
      buttonText: "Invest Now",
    },
    {
      name: "Apex Plan",
      dailyInterest: 3,
      termDays: 7,
      minDeposit: 30000.00,
      maxDeposit: 59999.00,
      capitalReturn: "Each Term",
      totalReturn: 21,
      buttonText: "Invest Now",
    },
    {
      name: "VIP Plan",
      dailyInterest: 3.5,
      termDays: 7,
      minDeposit: 60000.00,
      maxDeposit: 99999.00,
      capitalReturn: "Each Term",
      totalReturn: 24.5,
      buttonText: "Invest Now",
    },
    {
      name: "VVIP Plan",
      dailyInterest: 4,
      termDays: 7,
      minDeposit: 100000.00,
      maxDeposit: 1000000.00,
      capitalReturn: "Each Term",
      totalReturn: 28,
      buttonText: "Invest Now",
    },
    {
      name: "Regular Plan (Real Estate)",
      dailyInterest: 1.8,
      termDays: 10,
      minDeposit: 2000.00,
      maxDeposit: 14999.00,
      capitalReturn: "Each Term",
      totalReturn: 18,
      buttonText: "Invest Now",
    },
    {
      name: "Standard Plan (Real Estate)",
      dailyInterest: 2.6,
      termDays: 10,
      minDeposit: 15000.00,
      maxDeposit: 29999.00,
      capitalReturn: "Each Term",
      totalReturn: 26,
      buttonText: "Invest Now",
    },
    {
      name: "Premium Plan (Real Estate)",
      dailyInterest: 3.1,
      termDays: 10,
      minDeposit: 30000.00,
      maxDeposit: 59999.00,
      capitalReturn: "Each Term",
      totalReturn: 31,
      buttonText: "Invest Now",
    },
    {
      name: "VIP Plan (Real Estate)",
      dailyInterest: 3.7,
      termDays: 10,
      minDeposit: 60000.00,
      maxDeposit: 99999.00,
      capitalReturn: "Each Term",
      totalReturn: 37,
      buttonText: "Invest Now",
    },
    {
      name: "VVIP Plan (Real Estate)",
      dailyInterest: 4.5,
      termDays: 10,
      minDeposit: 100000.00,
      maxDeposit: 1000000.00,
      capitalReturn: "Each Term",
      totalReturn: 45,
      buttonText: "Invest Now",
    },
  ];
  
  export default investmentPlans;