import React from 'react';
import { Helmet } from 'react-helmet-async';

const FAQ = () => {
  return (
    <div className='p-5 max-w-4xl mx-auto text-start'>
      <Helmet>
        <title>Frequently Asked Questions</title>
      </Helmet>
      <h1 className='text-3xl font-semibold mb-5'>Frequently Asked Questions</h1>
      <div className='space-y-6'>
        <div className='border p-4 rounded-lg'>
          <h2 className='text-2xl font-semibold mb-2'>How can we help you?</h2>
          <p>Do you have any questions? We strongly recommend that you start searching for the necessary information in the FAQ section.</p>
        </div>
        <div className='border p-4 rounded-lg'>
          <h2 className='text-2xl font-semibold mb-2'>What is Evergreen Housing company?</h2>
          <p>Evergreen Housing platform is an international investment company. The activity of our company is aimed at the cryptocurrency trading, forex, stocks and providing investment services worldwide.</p>
        </div>
        <div className='border p-4 rounded-lg'>
          <h2 className='text-2xl font-semibold mb-2'>How to create an account?</h2>
          <p>The registration process on the website is quite simple. You need to fill out the fields of the registration form, which include full name, email address and password.</p>
        </div>
        <div className='border p-4 rounded-lg'>
          <h2 className='text-2xl font-semibold mb-2'>Which payment methods do you accept?</h2>
          <p>At the moment we work with PayPal, Wire Transfer, Bitcoin, Ethereum, Litecoin, Binance Coin.</p>
        </div>
        <div className='border p-4 rounded-lg'>
          <h2 className='text-2xl font-semibold mb-2'>I want to reinvest the funds received, is it possible?</h2>
          <p>Of course. You have the right to reinvesting your profits again and again.</p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
