import React, { useRef, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { doc, getDoc } from 'firebase/firestore';

const CopyInput = () => {
  const inputRef = useRef(null);
  const [userData, setUserData] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const getData = async () => {
          try {
            const ref = doc(db, 'users', user.uid);
            const docSnap = await getDoc(ref);
            if (docSnap.exists()) {
              setUserData(docSnap.data());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        getData();
      }
    });
  }, []);

  const handleCopyText = () => {
    // Select the text inside the input field
    inputRef.current.select();
    // Copy the selected text to the clipboard
    document.execCommand('copy');
    // Set copied state to true
    setCopied(true);
    // Reset copied state after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className='border flex justify-between w-full border-gray-300 rounded-md p-2'>
      <div className='flex-grow flex items-center'>
        <span className='ml-2'><LinkIcon /></span>
        <input
          ref={inputRef}
          type="text"
          className="outline-none ml-2 text-xs font-medium flex-grow"
          value={userData.referralLink || ''}
          readOnly
        />
      </div>
      <span onClick={handleCopyText} className="text-sm text-slate-400 cursor-pointer font-semibold px-2 self-center">
        {copied ? 'Copied' : <><ContentCopyIcon style={{ fontSize: 'small' }} /> Copy link</>}
      </span>
    </div>
  );
};

export default CopyInput;
