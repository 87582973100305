import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../firebase.config';
import { Container, Button, Form, Table, Alert, Spinner } from 'react-bootstrap';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AdminPanel = () => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [form, setForm] = useState({
    id: '',
    name: '',
    images: [],
    price: '',
    status: '',
    beds: '',
    baths: '',
    location: '',
    country: '',
    map: '',
    description: ''
  });
  const [imageFiles, setImageFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'properties'));
      const fetchedProperties = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProperties(fetchedProperties);
    } catch (error) {
      setError('Error fetching properties: ' + error.message);
    }
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setForm(property);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
  };

  const handleAdd = async () => {
    setLoading(true);
    try {
      if (imageFiles.length > 0) {
        const imagesUrls = await uploadImages(); // Upload images first
        const newProperty = { ...form, images: imagesUrls};
        const propertyRef = await addDoc(collection(db, 'properties'), newProperty);
        await updateDoc(propertyRef, { id: propertyRef.id });
        await fetchProperties(); // Refresh properties list
        resetForm();
      } else {
        setError('Please select at least one image.');
      }
    } catch (error) {
      setError('Error adding property: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const uploadImages = async () => {
    const storageRef = ref(storage, `property_images/${form.id}`);
    const imagesUrls = [];

    try {
      for (let index = 0; index < imageFiles.length; index++) {
        const imageFile = imageFiles[index];
        const imageRef = ref(storageRef, `${index + 1}`);
        await uploadBytes(imageRef, imageFile);
        const url = await getDownloadURL(imageRef);
        imagesUrls.push(url);
      }
    } catch (error) {
      throw new Error('Error uploading images: ' + error.message);
    }

    return imagesUrls;
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      if (selectedProperty) {
        const imagesUrls = await uploadImages(); // Upload images first
        const updatedProperty = { ...form, images: imagesUrls };
        const docRef = doc(db, 'properties', selectedProperty.id);
        await updateDoc(docRef, updatedProperty);
        await fetchProperties(); // Refresh properties list
        resetForm();
      }
    } catch (error) {
      setError('Error updating property: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'properties', id));
      setProperties(properties.filter(prop => prop.id !== id));
    } catch (error) {
      setError('Error deleting property: ' + error.message);
    }
  };

  const resetForm = () => {
    setForm({
      id: '',
      name: '',
      images: [],
      price: '',
      area: '',
      beds: '',
      baths: '',
      location: '',
      country: '',
      map: '',
      description: ''
    });
    setImageFiles([]);
    setSelectedProperty(null);
  };

  return (
    <div>
      <h2 className='text-2xl text-textColor-100 my-5'>{selectedProperty ? 'Edit Property' : 'Add Property'}</h2>
      <Form className='lg:grid-cols-4 gap-4 mx-auto p-5 grid grid-cols-1 md:grid-cols-3'>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" value={form.name} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formLocation">
          <Form.Label>Location</Form.Label>
          <Form.Control type="text" name="location" value={form.location} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formCountry">
          <Form.Label>Country</Form.Label>
          <Form.Control type="text" name="country" value={form.country} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formPrice">
          <Form.Label>Price</Form.Label>
          <Form.Control type="text" name="price" value={form.price} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formRent">
          <Form.Label>Rent</Form.Label>
          <Form.Control type="text" name="rent" value={form.rent} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formStatus">
          <Form.Label>Status</Form.Label>
          <Form.Control type="text" name="status" value={form.status} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formType">
          <Form.Label>Type</Form.Label>
          <Form.Control type="text" name="type" value={form.type} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formBeds">
          <Form.Label>Beds</Form.Label>
          <Form.Control type="text" name="beds" value={form.beds} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formBaths">
          <Form.Label>Baths</Form.Label>
          <Form.Control type="text" name="baths" value={form.baths} onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formMap">
          <Form.Label>Map</Form.Label>
          <Form.Control type="text" name="map" value={form.map} onChange={handleChange} />
        </Form.Group>
      
        <Form.Group controlId="formImages">
          <Form.Label>Images</Form.Label>
          <Form.Control type="file" accept="image/*" multiple onChange={handleImageChange} />
        </Form.Group>
        <Form.Group controlId="formDescription" className='md:col-span-3  lg:col-span-4' >
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" className='w-full' rows={3} name="description" value={form.description} onChange={handleChange} />
        </Form.Group>
        <Button variant="primary" onClick={selectedProperty ? handleUpdate : handleAdd} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : selectedProperty ? 'Update' : 'Add'}
        </Button>
      </Form>
      <h2>Properties</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className='hidden md:table'>ID</th>
            <th>Name</th>
            <th className='hidden md:table'>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((property) => (
            <tr key={property.id}>
              <td className='hidden md:table'>{property.id}</td>
              <td>{property.name}</td>
              <td className='hidden md:table'>{property.location}</td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(property)}>Edit</Button>
                <Button variant="danger" className='ml-2' onClick={() => handleDelete(property.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminPanel;
