import React, { useState } from 'react';
import '../User/Dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import AllPlans from './AllPlans';
import Active from './Active';
import Completed from './Completed';

const Investments = ({ activities}) => {
  const [activeLink, setActiveLink] = useState('all');
  const [visibleOptions, setVisibleOptions] = useState(null);
  const navigate = useNavigate()


  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const handleMoreClick = (id) => {
    setVisibleOptions(visibleOptions === id ? null : id);
  };

  const active = activities.filter((data) => data.status === 'running' );
  const completed = activities.filter((data) => data.status === 'ended' );

  const activeClass = 'text-green-500 border-b-2 font-semibold text-base border-green-500 pb-3';
  const normalClass = 'text-slate-500 pb-4 font-semibold text-base';

  return (
    <div className='bgh bg-bgColor-600 p-3'>
      <div className="lg:mx-5 mb-4">
        <div className='text-start lg:p-4'>
          <div>
          <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>Invested Plans</p>
          <p className='text-base font-medium text-slate-500 my-2'>Total {activeLink === 'all' && activities.length}{activeLink === 'active' && active.length}{activeLink === 'completed' &&  completed.length} entries.</p>
          </div>
        </div>

        <div className='flex justify-start border-b-2 lg:mx-4'>
          <Link
            to=''
            className={`inline-block text-start ${activeLink === 'all' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('all')}
          >
            All Plans
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'active' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('active')}
          >
            Active
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'completed' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('completed')}
          >
            Completed
          </Link>
        </div>

        <div className='lg:mx-4 mt-4 text-start'>
          {activeLink === 'all' && (
            <div className=''>
              <AllPlans activities={activities}/>
            </div>
          )}
          {activeLink === 'active' && (
            <div className=''>
              <Active activities={activities}/>
            </div>
          )}
          {activeLink === 'completed' && (
            <div className=''>
              <Completed  activities={activities}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Investments;
