
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';


const Deposit = ({transactions = [], loading}) => {
  const withdrawTransactions = transactions.filter((data) => data.type === 'Withdraw' && data.status === 'completed');
  const navigate = useNavigate()
  return (
    <div className='overflow-hidden'>
      <div >
        <div className='text-slate-400'>
          {loading ? (
            <p>Loading...</p>
          ) : withdrawTransactions.length <= 0  ? (
            <p>No transaction found!</p>
          ) : (
            withdrawTransactions.map((data) => (
              <div
                className='flex flex-col mb-1 px-2 py-2 border rounded-md bg-white text-slate-600 shadow-sm'
                key={data.id}
              >
                <div className='flex justify-between'>
                  <div className="flex items-center mb-2 md:mb-0">
                    <div className='p-1 mr-3 rounded-full bg-green-50 text-green-600'><div className='text-red-500'><CallMadeIcon /></div></div>
                  <div>
                  <p className='text-xs  font-bold'>{data.details}</p>
                    <div className='flex'>
                    <p className='text-xs pt-1 text-gray-500'>{new Date(data.date.seconds * 1000).toLocaleDateString()}</p>
                    <p className={`text-sm ml-3 ${data.status === 'cancelled' ? 'text-red-500' : 'text-gray-500'}`}>{data.status}</p>
                    </div>
                  </div>
                  </div>
                  <div className='flex items-center'>
                    <div className=' '>
                      <p className='text-xs font-bold text-red-500'>+ {data.withdrawalAmount} USD</p>
                     <p className='text-slate-500 text-xs font-semibold'>{Number(data.withdrawalAmount).toFixed(4)} USDT</p>
                    </div>
                    <div className='ml-1 md:ml-4'>
                      <button
                      onClick={() => navigate(`/dashboard/user/withdraw/${data.id}`)}
                      className="text-blue-500 hover:underline mt-2 md:mt-0"
                      >
                        <ArrowForwardIosIcon />
                      </button>
                    </div>
                  </div>
                </div>
                
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Deposit;
