import slider1 from '../Images/slider1.jpeg'
import slider2 from '../Images/slider2.jpeg'
import slider3 from '../Images/slider3.jpeg'

const data = [
    {
        header1: 'EXCELLENT REAL ESTATE PLATFORM',
        header2: 'Rent property in excellent locations across the globe',
        bgImg: slider1
    },
    {
        header1: 'CRYPTOCURRENCY INVESTMENT',
        header2: 'Cryptocurrency is the Backbone of Digital Assets',
        bgImg: slider3
    },
    {
        header1: 'REAL ESTATE INVESTMENT',
        header2: 'Real Estate is a Trusted Sector for Wealth Building',
        bgImg: slider2
    },
]

export default data