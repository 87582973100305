import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate} from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import logo from '../Assets/Images/brand.png';
import GridViewIcon from '@mui/icons-material/GridView';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShareIcon from '@mui/icons-material/Share';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import '../User/Dashboard.css'; 
import '../Components/Header/Header.css';
import Header from './Header';
import Footer from './Footer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Home from './Home';
import { db } from '../firebase.config';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import Transaction from './Transactions';
import ManuallyAddDeposit from './AddDeposit';
import Detail from './Detail';
import Investments from './Investments';
import InvestmentDetails from './InvestmentDetail';
import ManageUser from './ManageUser';
import UserInfo from './UserInfo';
import UserTransactions from './UserTransactions';
import AddProperty from './AddProperty';
import Referrals from './Referrals';
import UpdateWalletInfo from './AddWallet';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Helmet } from 'react-helmet-async';


const Admindashboard = () => {
  const [show, setShow] = useState(false);
  const [activeLink, setActiveLink] = useState('/dashboard');
  const [accounts, setAccounts] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});

  const [activities, setActivities] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalDepositAmount, setTotalDepositAmount] = useState(0);
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState(0);
  const [numUsers, setNumUsers] = useState(0);
  const [numDeposits, setNumDeposits] = useState(0);
  const [numWithdrawals, setNumWithdrawals] = useState(0);
  const [numInvestments, setNumInvestments] = useState(0);
  const [pending, setpending] = useState([])

  useEffect(() => {
    const fetchActivities = async () => {
      const activitiesList = [];
      const depositsList = [];
      const withdrawalsList = [];

      let depositAmountSum = 0;
      let withdrawalAmountSum = 0;
      let investmentsCount = 0;
      let depositsCount = 0;
      let withdrawalsCount = 0;
      const accountsList = [];
      const userList = [];

      // Get all users
      const usersSnapshot = await getDocs(collection(db, 'users'));
      setNumUsers(usersSnapshot.docs.length);

      // Create an array of promises for each user's investments, deposits, and withdrawals
      const userPromises = usersSnapshot.docs.map(async (userDoc) => {
        const userId = userDoc.id;
        const userName = userDoc.data().name;
        accountsList.push({ userId, userName });
        userList.push({ ...userDoc.data(), userId });

        // Get investment subcollection for each user, ordered by orderedDate
        const investmentsSnapshot = await getDocs(
          query(collection(db, `users/${userId}/investment`), orderBy('orderedDate'))
        );

        investmentsSnapshot.forEach((doc) => {
          activitiesList.push({
            ...doc.data(),
            id: doc.id,
            userId,
            userName,
          });
          investmentsCount++;
        });

        const depositsSnapshot = await getDocs(
          query(collection(db, `users/${userId}/deposit`), orderBy('date'))
        );

        depositsSnapshot.forEach((doc) => {
          const depositData = doc.data();
          depositsList.push({
            ...doc.data(),
            id: doc.id,
            userId,
            userName,
            type: 'deposit',
          });
          depositAmountSum += depositData.paymentAmount || 0;
          depositsCount++;
        });

        // Get withdraw subcollection for each user, ordered by orderedDate
        const withdrawalsSnapshot = await getDocs(
          query(collection(db, `users/${userId}/withdraw`), orderBy('date'))
        );

        withdrawalsSnapshot.forEach((doc) => {
          const withdrawData = doc.data();
          withdrawalsList.push({
            ...doc.data(),
            id: doc.id,
            userId,
            userName,
            type: 'withdraw',
          });
          withdrawalAmountSum += withdrawData.withdrawalAmount || 0;
          withdrawalsCount++;
        });
        
      });

      // Wait for all user promises to resolve
      await Promise.all(userPromises);

      // Sort activities by orderedDate
      activitiesList.sort((a, b) => b.orderedDate - a.orderedDate);
      withdrawalsList.sort((a, b) => b.date - a.date);
      depositsList.sort((a, b) => b.date - a.date);

      setActivities(activitiesList);
      setWithdrawals(withdrawalsList);
      setDeposits(depositsList);
      setAccounts(accountsList);
      setUsers(userList);

      const transactionList = [...withdrawalsList, ...depositsList];
      setTransactions(transactionList);
      setTotalDepositAmount(depositAmountSum);
      setTotalWithdrawalAmount(withdrawalAmountSum);
      setNumInvestments(investmentsCount);
      setNumDeposits(depositsCount);
      setNumWithdrawals(withdrawalsCount);
      const pend = transactions.filter((data) => data.status === 'pending');
      setpending(pend)
      
    };

    fetchActivities();
  }, []);





  
  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setShow(false);
  };

  useEffect(() => {
    // Set initial active link on page load
    setActiveLink(window.location.pathname);
  }, []);

  const activeClass = 'text-left rounded-full py-2 px-3 hover:bg-bgColor-500 bg-bgColor-500 transition text-textColor-400 text-sm font-semibold capitalize w-100 my-2';
  const normalClass = 'text-left rounded-full py-2 pl-3 hover:bg-bgColor-500 transition text-slate-300 text-sm font-medium capitalize w-100 hover:text-textColor-400';

  return (
    <Container fluid>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Row className="flex-nowrap ">
        <Col as="aside" className="sidebar w-80 col-auto col-md-3 col-xl-3 px-sm-4 bg-bgColor-200 d-none d-lg-flex flex-column pb-4 align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 fixed top-0 left-0 overflow-y-scroll ">
          <Link to="/" className="d-flex align-items-center justify-center pb-3 mb-md-0 me-md-auto text-decoration-none">
            <img src={logo} alt="dashboard" className="w-20 rounded mt-3 ml-4 bg-slate-200" />
          </Link>
          <Nav className="flex-column w-full mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            
      
            <Nav.Item className='w-full'>
              <Link to="/admin/dashboard" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard')}>
                <button className={activeLink === '/admin/dashboard' ? activeClass : normalClass}>
                  <span><GridViewIcon /></span> <span className="ms-1 d-none d-sm-inline">Dashboard</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item className='w-full '>
              <Link to="admin/dashboard/transactions" className="nav-link" onClick={() => handleNavLinkClick('admin/dashboard/transactions')}>
                <button className={activeLink === 'admin/dashboard/transactions' ? activeClass : normalClass}>
                  <span><ImportExportIcon /></span> <span className="relative ms-1 d-none d-sm-inline">Transactions  {pending.length > 0 && (
                <span className='text-xs bg-slate-400 text-white py-1 px-2 rounded-full -top-4 absolute -right-5'>
                  {pending.length}
            </span>
  )}</span>
                </button>
              </Link>
            </Nav.Item>
            
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/investments" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/investments')}>
                <button className={activeLink === '/admin/dashboard/investments' ? activeClass : normalClass}>
                <span><CurrencyExchangeIcon /></span> <span className="ms-1 d-none d-sm-inline">Investments</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/manage-users" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/manage-users')}>
                <button className={activeLink === '/admin/dashboard/manage-users' ? activeClass : normalClass}>
                <span><ManageAccountsIcon /></span> <span className="ms-1 d-none d-sm-inline">Manage Users</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/addproperties" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/addproperties')}>
                <button className={activeLink === '/admin/dashboard/addproperties' ? activeClass : normalClass}>
                <span><ApartmentIcon /></span> <span className="ms-1 d-none d-sm-inline">Real Estate</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/referrals" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/referrals')}>
                <button className={activeLink === '/admin/dashboard/referrals' ? activeClass : normalClass}>
                <span><ShareIcon /></span> <span className="ms-1 d-none d-sm-inline">Referrals</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/addwallet" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/addwallet')}>
                <button className={activeLink === '/admin/dashboard/addwallet' ? activeClass : normalClass}>
                <span><AccountBalanceWalletIcon /></span> <span className="ms-1 d-none d-sm-inline">Wallets</span>
                </button>
              </Link>
            </Nav.Item>
          </Nav>

        </Col>
        <Col className="pb-3 px-0">
          <Navbar  expand={false} className="mb-3 w-full d-lg-none">
            <Container fluid className="d-flex justify-content-between px-4">
              <div className='flex items-center justify-start'>
              <div onClick={handleShow} className={`navbar-toggler ${show ? '' : 'collapsed'}`}>
                <span className='toggler-icon top-bar'></span>
                <span className='toggler-icon mid-bar'></span>
                <span className='toggler-icon bottom-bar'></span>
              </div>
              <Link to="/">
                <img className='w-20 h-10 object-contain ' src={logo} alt="dashboard" />
              </Link>
              </div>
              <div className=' flex justify-end items-center  '>
                <span className='text-green-400'><AccountCircleIcon /></span>
                <div className='ml-3'>
                  <p className='text-grey-400 font-semibold text-xs text-start'>Admin</p>
                  <p className='text-green-500 font-bold capitalize text-start text-sm'>Super House</p>
                </div>
              </div>
            </Container>
          </Navbar>

          <Offcanvas show={show} onHide={handleClose} className="d-lg-none custom-offcanvas">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <Link to='/' onClick={handleNavLinkClick}>
                  <img className="w-20 rounded mt-3 ml-4 bg-slate-200" src={logo} alt="dashboard" />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="flex-column">
              <Nav.Item className='w-full'>
              <Link to="/admin/dashboard" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard')}>
                <button className={activeLink === '/admin/dashboard' ? activeClass : normalClass}>
                  <span><GridViewIcon /></span> <span className="">Dashboard</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item className='w-full '>
              <Link to="/admin/dashboard/transactions" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/transactions')}>
                <button className={activeLink === '/admin/dashboard/transactions' ? activeClass : normalClass}>
                  <span><ImportExportIcon /></span> <span className="ms-1">Transactions</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item className='w-full'>
              <Link to="/admin/dashboard/investments" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/investments')}>
                <button className={activeLink === '/admin/dashboard/investments' ? activeClass : normalClass}>
                <span><CurrencyExchangeIcon /></span> <span className="ms-1 ">Investments</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/manage-users" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/manage-users')}>
                <button className={activeLink === '/admin/dashboard/manage-users' ? activeClass : normalClass}>
                <span><ManageAccountsIcon /></span> <span className="ms-1 ">Manage Users</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/addproperties" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/addproperties')}>
                <button className={activeLink === '/admin/dashboard/addproperties' ? activeClass : normalClass}>
                <span><ApartmentIcon /></span> <span className="ms-1">Real Estate</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/admin/dashboard/referrals" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/referrals')}>
                <button className={activeLink === '/admin/dashboard/referrals' ? activeClass : normalClass}>
                <span><ShareIcon /></span> <span className="ms-1">Referrals</span>
                </button>
              </Link>
              <Link to="/admin/dashboard/addwallet" className="nav-link" onClick={() => handleNavLinkClick('/admin/dashboard/addwallet')}>
                <button className={activeLink === '/admin/dashboard/addwallet' ? activeClass : normalClass}>
                <span><AccountBalanceWalletIcon /></span> <span className="ms-1">Wallets</span>
                </button>
              </Link>
            </Nav.Item>
            <div className="dropdown pb-4 pl-4 mb-3">
            <h4 className='text-start text-xs my-2 uppercase font-bold pt-2 text-slate-300'>Additional</h4>
            <Link className='text-start text-sm capitalize font-bold pt-2 text-slate-400' >go to home</Link>
          
          </div>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
          
          <div className='bg-bgColor-600 h-full md:ml-80 min-h-screen'>
          <Header />
          <Routes>
            <Route path="/admin/dashboard" element={<Home  data={currentUser} transactions={transactions} activities={activities} deposit={totalDepositAmount} withdrawal={totalWithdrawalAmount} numDeposits={numDeposits} numUsers={numUsers} numInvestments={numInvestments} numWithdrawals={numWithdrawals} deposits={deposits} withdrawals={withdrawals}/>} />
            <Route path="/admin/dashboard/transactions" element={<Transaction   activities={activities} transactions={transactions}  deposits={deposits} withdrawals={withdrawals}/>} />
            <Route path="/admin/dashboard/investments" element={<Investments activities={activities}/>} />
            <Route path="/admin/dashboard/manage-users" element={<ManageUser activities={users}/>} />
            <Route path="/admin/dashboard/add-deposit" element={<ManuallyAddDeposit accounts={accounts}/>} />
            <Route path="/admin/dashboard/:userId/:type/:depositId" element={<Detail />} />
            <Route path="/admin/dashboard/invest/:userId/investment/:investmentId" element={<InvestmentDetails investment={activities}/>} />
            <Route path="admin/dashboard/userInfo/:userId" element={<UserInfo />} />
            <Route path="/admin/userTransactions/:userId" element={<UserTransactions />} />
            <Route path="/admin/dashboard/addproperties" element={<AddProperty />} />
            <Route path="/admin/dashboard/referrals" element={<Referrals users={users}/>} />
            <Route path="/admin/dashboard/addwallet" element={<UpdateWalletInfo />} />
            
          </Routes>
          
          <Footer />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Admindashboard;