import React, { useState } from 'react';
import '../User/Dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import History from './History';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

const Transaction = ({ activities, transactions = [], deposits, withdrawals }) => {
  const [activeLink, setActiveLink] = useState('history');
  const [visibleOptions, setVisibleOptions] = useState(null);
  const navigate = useNavigate()


  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const handleMoreClick = (id) => {
    setVisibleOptions(visibleOptions === id ? null : id);
  };

  const activeClass = 'text-green-500 border-b-2 font-semibold text-base border-green-500 pb-3';
  const normalClass = 'text-slate-500 pb-4 font-semibold text-base';

  return (
    <div className='bgh bg-bgColor-600 p-3'>
      <div className="lg:mx-5 mb-4">
        <div className='flex justify-between relative items-center text-start lg:p-4'>
          <div>
          <p className='text-base font-medium text-slate-400 my-2 capitalize'>history</p>
          <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>transactions</p>
          <p className='text-base font-medium text-slate-500 my-2'>Total {activeLink === 'withdrawals' && withdrawals.length}{activeLink === 'deposits' && deposits.length}{activeLink === 'history' && activities.length + transactions.length} transactions.</p>
          </div>
          <div>
          <button className='bg-blue-500  text-white py-2 px-3 rounded-md' onClick={() => handleMoreClick(transactions.id)}><AddIcon /> Add  <span className=''><ExpandMoreIcon /></span></button>
          </div>
          {visibleOptions === transactions.id && (
                      <div className="absolute right-10 -bottom-8   mt-2 z-10 w-48 bg-white border rounded-lg shadow-lg">
                        <div className="py-2">
                            <>
                              <button onClick={() => navigate('/admin/dashboard/add-deposit')} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Add Deposit</button>
                              <button onClick={() => navigate('/admin/dashboard/add-deposit')}  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"> Add Bonus</button>
                            </>                   
                        </div>
                      </div>
                    )}
        </div>

        <div className='flex justify-start border-b-2 lg:mx-4'>
          <Link
            to=''
            className={`inline-block text-start ${activeLink === 'history' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('history')}
          >
            History
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'deposits' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('deposits')}
          >
            Deposits
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'withdrawals' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('withdrawals')}
          >
            Withdrawals
          </Link>
        </div>

        <div className='lg:mx-4 mt-4 text-start'>
          {activeLink === 'history' && (
            <div className=''>
              <History transactions={transactions} activities={activities} />
            </div>
          )}
          {activeLink === 'deposits' && (
            <div className=''>
              <Deposit deposits={deposits} />
            </div>
          )}
          {activeLink === 'withdrawals' && (
            <div className=''>
              <Withdraw withdrawals={withdrawals} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transaction;
