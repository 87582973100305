import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import AccountList from './AccountList';
import 'tailwindcss/tailwind.css';
import { auth, db } from '../../firebase.config';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Helmet } from 'react-helmet-async';


const AccountPage = () => {
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data().withdrawalAccount;
          setAccounts(Array.isArray(data) ? data : []);
        } else {
          console.log('No such document!');
          setAccounts([]);
        }
      } else {
        setUser(null);
        setAccounts([]);
      }
    });
  }, []);

  return (
    <div className="bgh text-start px-5 py-3">
      <Helmet><title>Accounts</title></Helmet>
      {user ? (
        <>
          <h1 className="text-xl font-bold">Your Accounts</h1>
          <div className='flex flex-col justify-between items-start md:items-center text-start md:flex-row'>
            <p>Below accounts that you’d like to withdraw funds.</p>
            <button
              className="bg-green-500 block text-white px-4 mt-2 md:mt-0 py-2 rounded"
              onClick={() => navigate('/dashboard/user/account/add')}
            >
              Add Account
            </button>
          </div>
          <AccountList accounts={accounts} />
          <div>
            <p className='text-sm mb-1 font-medium text-slate-400'><span className='text-red-500 mr-2'><InfoIcon  style={{fontSize: 'small'}}/></span>Caution: Your updated information only effect on new withdraw request.</p>
            <p className='text-sm mb-1 font-medium text-slate-400'><span className='text-slate-500 mr-2'><InfoIcon  style={{fontSize: 'small'}}/></span>
            You should enter your correct information for receiving payment.</p>
          </div>

        </>
      ) : (
        <p>Please log in to manage your accounts.</p>
      )}
    </div>
  );
};

export default AccountPage;
