import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/login'); // Redirect to login if user is not authenticated
    }
  }, [auth, navigate]);

  const handleChangePassword = async () => {
    setIsProcessing(true);
    setError('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setIsProcessing(false);
      return;
    }

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    try {
      // Reauthenticate user
      await reauthenticateWithCredential(user, credential);

      // Update password
      await updatePassword(user, newPassword);
      toast.success('Password updated successfully');

      // Simulate a delay to show the spinner
      setTimeout(() => {
        setIsProcessing(false);
        navigate(-1);
      }, 6000);
    } catch (authError) {
      setError(authError.message);
      setIsProcessing(false);
    }
  };

  return (
    <div className="p-6 max-w-xl text-start mx-auto">
      <h2 className="text-2xl font-bold mb-4">Change Password</h2>
      
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Current Password</label>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          className="mt-1 p-3 border border-gray-300 rounded w-full"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">New Password</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="mt-1 p-3 border border-gray-300 rounded w-full"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="mt-1 p-3 border border-gray-300 rounded w-full"
        />
      </div>

      <div className="flex items-center">
        <button
          onClick={handleChangePassword}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400 transition-colors duration-300 flex items-center"
          disabled={isProcessing}
        >
          {isProcessing && (
            <svg
              className="animate-spin mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isProcessing ? 'Processing...' : 'Change Password'}
        </button>
        <button
          onClick={() => navigate(-1)}
          className="text-red-500 px-4 py-2 ml-4 hover:bg-gray-400 transition-colors duration-300"
          disabled={isProcessing}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
