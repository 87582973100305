import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='flex flex-col-reverse md:flex-row bg-bgColor-600 gap-y-3 justify-between items-center px-4 py-2'>
      <p className="text-base  text-slate-400 font-semibold">&copy; {currentYear} Hillview Housing. All Rights Reserved.</p>
      <div className='flex justify-between text-green-600 gap-3'>
        <Link to=''>FAQs</Link>
        <Link to=''>Terms And Condition</Link>
        <Link className='mr-4' to=''>Privacy Policy</Link>
      </div>
    </div>
  )
}

export default Footer
