import React from 'react'
import './Dashboard.css'
import ChartComponent from './Components/ChartComponent'
import SimpleBtn from '../Components/Buttons/SimpleBtn'
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import HistoryIcon from '@mui/icons-material/History';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import InvestmentsPage from './Components/InvestmentList';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const Investments = ({currentUser, totalReturn, totalPaymentAmount, dayReturn}) => {
  const navigate = useNavigate()
  
  return (
    <div className='min-h-screen bg-bgColor-600 p-1'>
      <Helmet><title>Investments</title></Helmet>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 lg:mx-5 mb-4 p-2">
        <div className='text-start '>
          <p className='text-base font-medium text-slate-400 my-2 capitalize'>Investment</p>
          <p className='text-4xl font-semibold capitalize text-blue-950 mb-3'>Invested Plans</p>
          <p className='text-base font-medium text-slate-500 my-2'>At a glance summary of your investment.</p>
        </div>
       
        <div className='flex items-center lg:justify-end justify-start'>
          <SimpleBtn text='invest & earn &rarr;' classname='bg-bgColor-200 text-slate-100 px-3 font-semibold text-sm capitalize py-2 rounded' link={`/dashboard/user/plans`}/>
          <SimpleBtn text='deposit &rarr;' classname='bg-green-400 text-white px-3 font-semibold text-sm capitalize py-2 rounded ml-4' link='/dashboard/user/deposit/wallet'/>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 lg:gap-x-10 lg:mx-5 p-2">
      <div className="border-l-4 border-y-2 border-r-2 bg-white rounded border-l-blue-400 p-4">
            <div className='flex'>
              <h3 className='mr-4 text-start text-xl  mb-3 font-bold text-slate-600'>Investment Account</h3>
              <div className="text-slate-400"><InfoIcon style={{fontSize: 'small'}}/></div>
            </div>
            <div className='flex'>
              <div className=''>
              <h3 className="text-2xl mb-1 text-slate-400 font-medium uppercase text-start">{Number(currentUser.profit).toFixed(2)} <span>USD</span></h3>
              <p className='text-start text-sm font-bold text-slate-400'>Available Funds</p>
              </div>
              <div className='text-slate-500 self-center mr-3'><AddIcon/></div>
              <div>
              <h3 className="text-lg my-1 text-slate-400 font-medium uppercase text-start">{Number(dayReturn).toFixed(2)} USD</h3>
              <p className='text-start text-sm font-bold text-slate-400'>Locked</p>
              </div>
            </div>
            <span className="text-sm mt-4 block text-start"><SimpleBtn  text='Transfer Funds &rarr;'  classname='bg-bgColor-200 text-slate-100 px-3 font-semibold text-sm capitalize py-2 rounded' link={`/dashboard/user/transfer`}/></span>
            <div className='mt-4'>
            </div>
        </div>
        <div className="border-2 bg-white rounded p-4 md:col-span-2">
           
           <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 border-b-2 pb-4">
           <div className=''>
           <div className='flex  '>
              <h3 className='mr-4 text-start text-lg mb-3 font-bold text-slate-600'>Amount in Invested</h3>
              <span className='text-slate-400'> <InfoIcon style={{fontSize: 'small'}}/></span>
            </div>
            <div className="flex gap-y-3">
            <div className='mr-4'>
              <h3 className="text-2xl mb-1 text-slate-400 font-medium uppercase text-start">{Number(totalPaymentAmount).toFixed(2)} <span>USD</span></h3>
              <p className='text-start text-sm font-bold text-slate-400'>Currently Invested</p>
              </div>
              <div className='text-slate-500 self-center mr-3'><AddIcon/></div>
              <div>
              <h3 className="text-lg my-1 text-slate-400 font-medium uppercase text-start">{Number(totalReturn).toFixed(2)} </h3>
              <p className='text-start text-sm font-bold text-slate-400'>Approx Profit</p>
              </div>
            </div>
            </div>
            <div className=''><ChartComponent /></div>
           </div>
           <div className='flex gap-4 items-center pt-6 text-green-500'>
              <div className='cursor-pointer' onClick={() => navigate('/dashboard/user/transactions')} ><span className='cursor-pointer'><LibraryBooksIcon style={{fontSize: 'small'}}/></span>  Transactions</div>
              <div className='cursor-pointer' onClick={() => navigate('/dashboard/user/transactions')} ><span><HistoryIcon style={{fontSize: 'small'}}/></span>  history</div>
           </div>
        </div>
      </div>
      <InvestmentsPage />
    </div>
  )
}

export default Investments
