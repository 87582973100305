import React, { useState, useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import './Dashboard.css'
import { Link } from 'react-router-dom'
import History from './Components/History';
import Deposit from './Components/Deposit';
import Withdrawal from './Components/Withdrawal';
import Scheduled from './Components/Scheduled';
import { Helmet } from 'react-helmet-async';


const Transaction = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const getData = async () => {
          try {
            const ref = doc(db, 'users', user.uid);
            const docSnap = await getDoc(ref);
            if (docSnap.exists()) {
              setCurrentUser(docSnap.data());
            }

            const transactionCol1 = collection(db, 'users', user.uid, 'deposit');
            const transactionCol2 = collection(db, 'users', user.uid, 'withdraw');
            const transactionCol3 = collection(db, 'users', user.uid, 'transfer');
            const [querySnapshot1, querySnapshot2, querySnapshot3] = await Promise.all([
              getDocs(transactionCol1),
              getDocs(transactionCol2),
              getDocs(transactionCol3),
            ]);

            const fetchedTransactions = [];


            querySnapshot1.forEach((doc) => {
              fetchedTransactions.push({
                id: doc.id,
                type: 'Deposit',
                ...doc.data(),
              });
            });

            querySnapshot2.forEach((doc) => {
              fetchedTransactions.push({
                id: doc.id,
                type: 'Withdraw',
                ...doc.data(),
              });
            });

            querySnapshot3.forEach((doc) => {
              fetchedTransactions.push({
                id: doc.id,
                type: 'Transfer',
                ...doc.data(),
              });
            });

            fetchedTransactions.sort((a, b) => b.date - a.date); // Sort by date in descending order

            setTransactions(fetchedTransactions);
            setLoading(false);
          } catch (error) {
            console.log(error.message);
            setLoading(false);
          }
        };
        getData();
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const [activeLink, setActiveLink] = useState('history');

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const activeClass = 'text-green-500 border-b-2 font-semibold text-base border-green-500 pb-3';
  const normalClass = 'text-slate-500 pb-4 font-semibold text-base';
  const depositTransactions = transactions.filter((data) => data.type === 'Deposit' && data.status === 'pending' )
  const withrawalTransactions = transactions.filter((data) => data.type === 'Withdraw' && data.status === 'pending' )

  return (
    <div className='bgh bg-bgColor-600 p-3'>
      <Helmet><title>Transactions</title></Helmet>
      <div className="lg:mx-5 mb-4">
        <div className='text-start lg:p-4'>
          <p className='text-base font-medium text-slate-400 my-2 capitalize'>history</p>
          <p className='text-3xl font-semibold capitalize text-blue-950 mb-3'>transactions</p>
          <p className='text-base font-medium text-slate-500 my-2'>list of transactions in your account</p>
        </div>

        <div className='flex justify-start border-b-2 lg:mx-4'>
          <Link
            to=''
            className={`inline-block text-start ${activeLink === 'history' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('history')}
          >
            History
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'deposits' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('deposits')}
          >
            Deposits
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'withdrawals' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('withdrawals')}
          >
            Withdrawals
          </Link>
          <Link
            to=''
            className={`ml-4 inline-block ${activeLink === 'scheduled' ? activeClass : normalClass}`}
            onClick={() => handleLinkClick('scheduled')}
          >
            <span className='relative'>
                Scheduled
                {depositTransactions.length + withrawalTransactions.length > 0 && (
                <span className='text-xs bg-green-300 text-white py-1 px-2 rounded-full -top-4 absolute -right-5'>
                  {depositTransactions.length + withrawalTransactions.length}
            </span>
  )}
</span>

          </Link>
        </div>

        <div className='lg:mx-4 mt-4 text-start'>
          {activeLink === 'history' && (
            <div className=''>
              <History transactions={transactions} loading={loading}/>
            </div>
          )}
          {activeLink === 'deposits' && (
            <div className=''>
              <Deposit transactions={transactions} loading={loading}/>
            </div>
          )}
          {activeLink === 'withdrawals' && (
            <div className=''>
              <Withdrawal transactions={transactions} loading={loading}/>
            </div>
          )}
          {activeLink === 'scheduled' && (
            <div className=''>
              <Scheduled transactions={transactions} loading={loading}/>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Transaction;
