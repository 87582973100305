import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase.config';
import AddWallet from './Components/AddWallet';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';



const Withdrawal = ({setAccounts}) => {
  const [user, setUser] = useState(null);
  const [withdrawalAccount, setWithdrawalAccount] = useState([]);
  const [balance, setBalance] = useState(0);
  const [isCryptoSelected, setIsCryptoSelected] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [cryptoWallet, setCryptoWallet] = useState('');
  const [cryptoType, setCryptoType] = useState('BTC');
  const [network, setNetwork] = useState('');
 

 

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        fetchUserData(user.uid);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleAddAccount = async () => {
    setLoading(true);
    if (user) {
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);
      let existingAccounts = [];
      if (docSnap.exists()) {
        existingAccounts = docSnap.data().withdrawalAccount || [];
      }
      const newAccount = { cryptoWallet, cryptoType, network };
      const updatedAccounts = [...existingAccounts, newAccount];

      await updateDoc(docRef, {
        withdrawalAccount: updatedAccounts
      });

      setAccounts(updatedAccounts);
      setLoading(false);
      setStep(2)
    }
  };

  const fetchUserData = async (userId) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const data = userDoc.data();
      setWithdrawalAccount(data.withdrawalAccount || []);
      setBalance(data.balance || 0);
      setSelectedWallet(data.withdrawalAccount ? data.withdrawalAccount[0] : null);
    }
    setLoading(false);
  };

  const handleContinue = async () => {
    if (!isCryptoSelected || balance <= 0) {
      setError('Please ensure you have a positive balance and have selected cryptocurrency.');
      return;
    }
    setProcessing(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setProcessing(false);
    setStep(2);
  };

  const handleStep2Continue = async () => {
    if (Number(withdrawAmount) <= 0) {
      setError('Withdraw amount must be greater than zero.');
      return;
    }
    setProcessing(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setProcessing(false);
    setStep(3);
  };
  const generateTransactionId = () => {
    return `TNX${Math.floor(10000000 + Math.random() * 90000000)}`;
  };

  const handleConfirm = async () => {
    setProcessing(true);
     
     try {

      const transactionId = generateTransactionId();
      const withdrawalTime = new Date();
      const withdrawalAmount = Number(withdrawAmount);
      const creditedInAccount = withdrawalAmount;
  
      const user = auth.currentUser;
  
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        const currentBalance = userData.balance || 0;
        // Calculate updated balance
        const updatedBalance = Number(currentBalance - withdrawalAmount).toFixed(2);
  
        const userDetails = {
          withdrawalTime,
          withdrawalAmount,
          creditedInAccount,
          details: `${selectedWallet.cryptoType} withdrawal`,
          paymentMethod: 'Crypto wallet',
          paymentTo: selectedWallet.cryptoWallet || '',
          transactionId,
          status: 'pending',
          date: new Date(),
          updatedBalance,
        };
  
        // Save withdrawal information to Firestore under the user's document
        await addDoc(collection(db, 'users', user.uid, 'withdraw'), userDetails);
  
        const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          body {
            font-family: Arial, sans-serif;
            font-size: 16px;
            color: #333;
          }
          .container {
            width: 100%;
            padding: 20px;
            background-color: #f7f7f7;
          }
          .content {
            max-width: 600px;
            margin: 0 auto;
            background-color: #fff;
            border: 1px solid #ddd;
          }
          .header, .footer {
            padding: 20px;
            text-align: center;
            background-color: #f7f7f7;
          }
          .body-content {
            padding: 20px;
          }
          h2 {
            color: darkblue;
            padding: 10px;
            text-align: center;
          }
          p {
            margin: 0 0 10px;
          }
        </style>
        </head>
        <body>
          <div class="container">
            <div class="content">
              <div class="header">
                <h2>Hillview Housing</h2>
              </div>
              <div class="body-content">
                <p>Hello ${userData.name},</p>
                <p>We received your request to withdraw funds from Hillview Housing. The funds will be deposited in your provided account and will be processed within 24-72 hours. You will be notified by email when we have completed your withdrawal.</p>
                <p><strong>Withdrawal Details:</strong></p>
                <p>
                  <strong>Payment Reference:</strong> ${transactionId}<br>
                  <strong>Withdraw Amount:</strong> ${withdrawalAmount} USD<br>
                  <strong>Payment Method:</strong> Crypto Wallet<br>
                  <strong>Transfer Amount:</strong> ${withdrawalAmount} USD<br>
                  <strong>Crypto Wallet:</strong> ${selectedWallet.cryptoWallet}
                </p>
                <p>Note: If you did not make this withdrawal request, please contact us immediately before it is authorized by our team.</p>
                <p>Feel free to contact us if you have any questions.</p>
              </div>
              <div class="footer">
                Hillview Housing &copy; 2024
              </div>
            </div>
          </div>
        </body>
        </html>
      `;
  
        const plainText = `
        Hello ${userData.name},
  
        We received your request to withdraw funds from Hillview Housing. The funds will be deposited in your provided account and will be processed within 24-72 hours. You will be notified by email when we have completed your withdrawal.
  
        Withdrawal Details:
  
        Payment Reference: ${transactionId}
        Withdraw Amount: ${withdrawalAmount} USD
        Payment Method: Crypto Wallet
        Transfer Amount: ${withdrawalAmount} USD
        Crypto Wallet: ${selectedWallet.cryptoWallet}
  
        Note: If you did not make this withdrawal request, please contact us immediately before it is authorized by our team.
  
        Feel free to contact us if you have any questions.
  
        Hillview Housing © 2024
        `;
  
        // Send email using Axios
        await axios.post("https://nodemailer-theta.vercel.app/send-email", {
          email: user.email,
          subject: `Your Withdrawal Request Has Been Received`,
          htmlContent: htmlContent,
          plainText: plainText,
        });
  
          
            
          } else {
            console.error("User is not authenticated.");
          }
        
      } catch (error) {
        console.error("Error adding document: ", error);
      } finally {
        setProcessing(false);
        setStep(4)
      }
  };

  if (loading) {
    return <div className='bgh'>Loading...</div>;
  }

  if (withdrawalAccount.length === 0) {
    return <AddWallet />;
  }

  return (
    <div className="max-w-xl mx-auto text-slate-500 p-4">
      <Helmet><title>Withdrawal</title></Helmet>
      {step === 1 && (
        <>
          <div className='flex justify-center items-center pb-4'>
            <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
          </div>
          <h2 className="text-xl font-bold mb-4">Withdraw Funds</h2>
          <p className='font-medium text-lg mb-1'>Select from withdrawal options below</p>
          <p className='text-sm'>Withdraw funds from your account directly</p>
          <div className='flex justify-between p-2 mt-4 bg-white rounded border-2 items-center'>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="crypto"
                checked={isCryptoSelected}
                onChange={(e) => setIsCryptoSelected(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="crypto">Cryptocurrency</label>
            </div>
            <div><CurrencyBitcoinIcon /></div>
          </div>
          <div className="mb-4 text-start mt-4 text-slate-400">
            <label className="block text-small mb-2">Withdraw From</label>
            <div className="border text-sm rounded p-2 bg-white">
              <p className='font-semibold text-neutral-700'>Main Account</p>
              <p className=''>Available Balance: <span className='font-medium'>({balance.toFixed(2)} USD)</span></p>
            </div>
          </div>
          <div>
            <button
              className="bg-green-500 w-full text-white py-2 px-4 rounded"
              onClick={handleContinue}
              disabled={processing}
            >
              {processing ? <CircularProgress size={24} /> : 'Withdraw Now'}
            </button>
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            className="text-red-400 py-2 px-4 rounded mt-2"
            onClick={() => navigate('/dashboard')}
          >
            Cancel
          </button>
        </>
      )}
      {step === 2 && (
        <>
          <div className='flex justify-center items-center pb-4'>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
          </div>
          <h2 className="text-xl font-bold mb-4">Withdraw Funds</h2>
          <p className='font-medium text-lg mb-1'>Via Crypto Wallet</p>
          <p className='text-sm'>Withdraw your funds to your crypto wallet</p>
          <div className="mb-4 text-start">
            <div className='flex justify-between items-center p-2'>
            <label className="block mb-2">Withdraw To</label>
            <button
              className=" block text-green-500 px-4 py-2 rounded"
              onClick={() => setStep(5)}
            >
              New CryptoWallet
            </button>
            </div>
            <div className="grid grid-cols-8 border-2 p-2">
              <div className='col-span-7'>
                <p className='text-base font-bold text-neutral-700'>{selectedWallet ? selectedWallet.cryptoType : ''}</p>
                <p className='text-sm'>{selectedWallet ? selectedWallet.cryptoWallet : 'Select Wallet'}</p>
              </div>
              <div className='col-span-1 flex justify-center items-center'>
                <ArrowDropDownIcon onClick={() => setShowDropdown(!showDropdown)} />
              </div>
            </div>
            {showDropdown && (
              <div className="mt-2">
                {withdrawalAccount.map((account, index) => (
                  <div
                    key={index}
                    className="py-2 pl-2 pr-4 flex justify-between items-center cursor-pointer shadow-sm border hover:bg-gray-100"
                    onClick={() => {
                      setSelectedWallet(account);
                      setShowDropdown(false);
                    }}
                  >
                    <div className='text-sm'>
                      <p>{account.cryptoType}</p>
                      <p>{account.cryptoWallet}</p>
                    </div>
                    {selectedWallet && selectedWallet.cryptoWallet === account.cryptoWallet && <div className='text-green-500'><CheckCircleIcon style={{ fontSize: 'small' }} /></div>}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-start">
            <div className="mb-4">
              <label className="block mb-2">Withdraw Amount</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
              />
              <span className='text-xs'>Current Balance: ({balance.toFixed(2)} USD)</span>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Amount to Receive</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={Number(withdrawAmount)}
                readOnly
              />
              <span className='text-xs'>1 USD = 1.00 USDT</span>
            </div>
          </div>
          <div className="mb-4 text-start">
            <label className="block mb-2">Description (Optional)</label>
            <input type="text" className="w-full px-2 py-3 border rounded" />
          </div>
          <div>
            <button
              className="bg-green-500 w-full text-white py-2 px-4 rounded mb-2"
              onClick={handleStep2Continue}
              disabled={processing}
            >
              {processing ? <CircularProgress size={24} /> : 'Continue to Withdraw'}
            </button>
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            className=" text-red-500 py-2 px-4 rounded"
            onClick={() => setStep(1)}
          >
            Back to prev page
          </button>
        </>
      )}
      {step === 3 && (
        <>
          <div className='flex justify-center items-center pb-4'>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
            <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
          </div>
          <h2 className="text-xl font-bold mb-4">Confirm Your Withdrawal</h2>
          <p className='text-base font-medium'>You are about to withdraw {withdrawAmount} USDT via Cryptocurrency ({selectedWallet.cryptoWallet}).</p>
          <p className="text-sm mb-4"> Please review the information and confirm.</p>
          <div className="bg-white rounded shadow py-4  mb-4">
            <div className="mb-2 grid grid-cols-3 p-2 border-b-2">
              <label className="block text-xs font-bold text-start">Withdraw Account (Cryptocurrency) </label>
              <p className="text-sm col-span-2 text-right font-semibold">{selectedWallet.cryptoWallet}</p>
            </div>
            <div className="mb-2 flex justify-between p-2 border-b-2 items-center">
              <label className="block font-semibold">Withdraw amount</label>
              <p className="text-sm">{withdrawAmount} USD</p>
            </div>
            <div className="mb-2 flex justify-between px-2 pt-2 items-center">
              <label className="block ">Equivalent to</label>
              <p className="text-sm font-semibold">{withdrawAmount} USDT</p>
            </div>
            <div className="mb-2 flex justify-between px-2 border-b-2 items-center">
              <label className="block ">Exchange rate</label>
              <p className="text-sm">1 USD = 1.00 USDT</p>
            </div>
            <div className=" flex justify-between px-2 items-center">
              <label className="block   font-semibold">Total amount to debit</label>
              <p className="text-sm font-semibold">{withdrawAmount} USD</p>
            </div>
          </div>
          <div className="mb-2 flex border-2 justify-between bg-white rounded-md mt-2 p-2 items-center">
              <label className="block   font-semibold">Amount transferred to Account</label>
              <p className="text-sm  font-semibold">{withdrawAmount} USDT</p>
            </div>
            <div>
            <button
              className="bg-green-500 w-full text-white py-2 px-4 rounded"
              onClick={handleConfirm}
              disabled={processing}
            >
              {processing ? <CircularProgress size={24} /> : 'Confirm Order'}
            </button>
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <button
            className=" text-red-500 py-2 px-4 rounded"
            onClick={() => setStep(2)}
          >
            Cancel Order
          </button>
        </>
      )}
      {
        step === 4 && (
          <>
            <div className=" flex p-6 flex-col items-center justify-center">
        <div className='w-20 h-20 rounded-full flex justify-center  bg-green-500 items-center text-white font-bold'><CheckIcon style={{}}/></div>
        <h2 className="text-xl mt-2 font-bold mb-3">Your funds are on the way!</h2>
        <p className="">We will send you a confirmation email shortly. Check the email for datails on when the funds will reach your account</p>
        <p className="mb-4">account from your profile (withdrawal accounts).</p>
        <button  onClick={() => setStep(1)} className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md font-bold text-sm hover:bg-green-600">Another Withdraw</button>
        <button  onClick={() => navigate('/dashboard/user/transactions')} className="mt-1 text-sm font-semibold px-6 py-2   text-green-400  hover:text-green-600 ml-2">Check status in Transaction</button>
        <p className="mt-4">Please feel free to contact us if you have any question.</p>
      </div>
          </>
        )
      }
      {
        step === 5 && (
          <>
                <div className="bgh">
      <div className="py-4 relative mt-5 max-w-xl mx-auto bg-white rounded shadow-sm  text-start text-slate-600">
      <button
          onClick={() => setStep(2)}
          className="absolute top-4 right-4 text-slate-500 hover:text-opacity-75"
        >
          <CloseIcon />
        </button>
      <div className="border-b-2 px-4 py-4">
      <h2 className="text-2xl font-semibold mb-1">Add Crypto Wallet Address</h2>
      <p className='text-base text-slate-400 font-semibold'>Add your Crypto wallet address to withdraw your funds.</p>
      </div>
     <div className="grid grid-cols-1 md:grid-cols-4 gap-2 px-4 pt-4 ">
     <div className="mb-2  col-span-3">
        <label className="block mb-1 text-slate-600 font-medium">Crypto Wallet Address <span className='text-red-500'>*</span></label>
        <input
          type="text"
          placeholder='Enter Crypto wallet Address'
          className="border px-2 py-2 w-full"
          value={cryptoWallet}
          onChange={(e) => setCryptoWallet(e.target.value)}
          disabled={loading}
        />
      </div>
      <div className="mb-2">
        <label className="block mb-1 text-slate-600 font-medium">Currency</label>
        <select
          className="border px-2 py-2 w-full"
          value={cryptoType}
          onChange={(e) => setCryptoType(e.target.value)}
          disabled={loading}
        >
          <option value="BTC">BTC</option>
          <option value="ETH">ETH</option>
          <option value="USDT">USDT</option>
          <option value="XRP">XRP</option>
        </select>
      </div>
      
     </div>
     <p className='text-sm mx-4 text-slate-400'>You will receive payment on this account in selected currency.</p>
      <div className="mb-4 p-4">
        <label className="block mb-1 text-slate-600 font-medium">Network type (Optional)</label>
        <input
          type="text"
          className="border px-2 py-2 w-full"
          value={network}
          placeholder='eg. Crypto Network adress'
          onChange={(e) => setNetwork(e.target.value)}
          disabled={loading}
        />
      </div>
      <div className="px-4">
      <button
        className={`bg-blue-500 text-white px-4 py-2 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleAddAccount}
        disabled={loading}
      >
        {loading ? (
          <div className="flex items-center justify-center">
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span className="ml-2">Adding...</span>
          </div>
        ) : (
          'Add Wallet'
        )}
      </button>
      </div>
    </div>
    </div>
          </>
        )
      }
    </div>
  );
};

export default Withdrawal;
