import React, { useState, useEffect } from 'react';
import PropertiesCard from './PropertiesCard';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';

const PropertyGrid = () => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      const querySnapshot = await getDocs(collection(db, 'properties'));
      const propertiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProperties(propertiesList);
    };

    fetchProperties();
  }, []);

  // Get unique country names in lowercase
  const countries = [...new Set(properties.map(p => p.country.toLowerCase()))];

  return (
    <div className="container mx-auto py-4">
      {countries.map(country => {
        // Filter properties based on the lowercase country name
        const countryProperties = properties.filter(p => p.country.toLowerCase() === country);
        return countryProperties.length > 0 ? (
          <div key={country} className="mb-8">
            <h2 className="text-2xl font-bold capitalize text-start mb-4">{country}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {countryProperties.map(property => (
                <PropertiesCard key={property.id} property={property} />
              ))}
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}

export default PropertyGrid;
