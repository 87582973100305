import React from 'react';
import { useNavigate } from 'react-router-dom';
import investmentPlans from '../Assets/Datas/investmentPlans';
import { Helmet } from 'react-helmet-async';

const Plans = ({data}) => {
  
  const navigate = useNavigate();

  const handleInvestClick = (plan) => {
    const minDeposit = parseFloat(plan.minDeposit);
    if (data.balance >= minDeposit) {
      navigate('/dashboard/user/plans/confirm', { state: { plan } });
    } else {
      navigate('/dashboard/user/plans/no-funds');
    }
  };

  return (
    <div className="text-center p-3 lg:m-4">
      <Helmet><title>plans</title></Helmet>
      <h1 className="text-3xl font-bold mb-4 text-slate-500">Investment Plans</h1>
      <p className="text-lg max-w-xl mx-auto mb-3 text-slate-500">Here are our several investment plans. You can invest daily, weekly, or monthly and get higher returns on your investment.</p>
      <p className="text-sm mb-4 font-semibold capitalize text-slate-500">Choose your favourite plan and start earning now. </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        {investmentPlans.map((plan, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-2">
            <div className='border-b-2 pb-2'>
            <h2 className="text-2xl font-semibold text-slate-600 mb-2">{plan.name}</h2>
            <p className='text-sm text-slate-500 mb-3'>{plan.stat}</p>
           <div className="flex justify-between text-slate-600">
           <div>
            <p className="text-lg "><strong>{plan.dailyInterest} %</strong></p>
            <p className="text-sm mb-2"> Daily Interest</p>
            </div>
           <div>
            <p className="text-lg "><strong>{plan.termDays}</strong></p>
            <p className="text-sm mb-2"> Term Days</p>
            </div>
      
           </div>
            </div>
            <div className='flex justify-between'>
            <p className="text-sm text-slate-500 mb-2">Min Deposit: </p>
            <p className='text-sm text-slate-500 mb-2'>{plan.minDeposit} USD</p>
            </div>
            <div className='flex justify-between'>
            <p className="text-sm text-slate-500 mb-2">Max Deposit: </p>
            <p className='text-sm text-slate-500 mb-2'>{plan.maxDeposit} USD</p>
            </div>
            <div className='flex justify-between'>
            <p className="text-sm text-slate-500 mb-2">Capital Return: </p>
            <p className='text-sm text-slate-500 mb-2'>{plan.capitalReturn}</p>
            </div>
            <div className='flex justify-between'>
            <p className="text-sm text-slate-500 mb-2">Total Return: </p>
            <p className='text-sm text-slate-500 mb-2'>{plan.totalReturn} %</p>
            </div>
           
            <button onClick={() => handleInvestClick(plan)} className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">{plan.buttonText}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
