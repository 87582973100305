const plansData = [
    {
      planName: 'Real Estate Plan',
      description: 'OUR REAL ESTATE PLAN IS FOR INVESTORS WHO PLAN TO MAKE PASSIVE INCOME THROUGH OUR REAL ESTATE SECTOR.',
      entryPrice: 2000,
      entryText: 'Minimum Entry',
      prices: [
        { price: "$2,000 - $14,999", roi: "18%" },
        { price: "$15,000 - $29,999", roi: "26%" },
        { price: "$30,000 - $59,999", roi: "31%" },
        { price: "$60,000 - $99,999", roi: "37%" },
        { price: "$100,000 - $1,000,000", roi: "45%" },
      ]
    },
    {
      planName: 'Cryptocurrency Plan',
      description: 'OUR CRYPTO PLAN IS FOR INVESTORS WHO PLAN TO MAKE PASSIVE INCOME THROUGH OUR CRYPTO SECTOR.',
      entryPrice: 200,
      entryText: 'Minimum Entry',
      classname: 'bg-bgColor-300',
      prices: [
        { price: "$200 - $4,999", roi: "10.5%" },
        { price: "$5,000 - $14,999", roi: "14%" },
        { price: "$15,000 - $29,999", roi: "17.5%" },
        { price: "$30,000 - $59,999", roi: "21%" },
        { price: "$60,000 - $99,999", roi: "24.5%" },
        { price: "$100,000 - $1,000,000", roi: "28%" },
      ]
    }
  ];
  

  export default plansData