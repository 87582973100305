import React, { useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const EnterAmount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { wallet } = location.state;
  const [amount, setAmount] = useState('');
  const [cryptoType, setCryptoType] = useState('BTC');
  const [isLoading, setIsLoading] = useState(false);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleCryptoTypeChange = (e) => {
    setCryptoType(e.target.value);
  };

  const handleConfirm = () => {
    if (amount < 200) {
      alert('Please enter an amount of at least 200 USD to deposit.');
      return;
    }

    const equivalentUSD = amount * 27027.02; // This will vary based on the cryptoType
    setIsLoading(true);
    setTimeout(() => {
      navigate('/dashboard/user/deposit/confirm', { state: { wallet, amount, cryptoType, equivalentUSD, } });
    }, 2000);
  };

  return (
    <div className='mx-auto max-w-lg text-slate-600 bgh p-3'>
      <Helmet><title>Deposit-amount</title></Helmet>
      <div className='flex justify-center items-center py-4'>
        <div className='text-gray-300 '><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
      </div>
      <h2 className="text-2xl font-bold mb-4">Deposit Funds</h2>
      <p className="text-lg font-medium"><span className='font-normal'>via</span> {wallet}</p>
      <p className="text-sm mt-1">Send your payment direct to our wallet.</p>
      <label className="block text-start mt-4 mb-2 font-semibold text-slate-700">Amount to Deposit</label>
      <div className="flex justify-between rounded text-lg font-semibold">
        <input
          type="text"
          value={amount}
          placeholder='0.00'
          onChange={handleAmountChange}
          className="w-full outline-none py-3 px-2"
        />
        <select
          value={cryptoType}
          onChange={handleCryptoTypeChange}
          className="w-20 text-slate-500 outline-none py-3 px-2"
        >
          <option value="BTC">BTC</option>
          <option value="ETH">ETH</option>
          <option value="USDT(TRC20)">USDT</option>
          <option value="XRP">XRP</option>
        </select>
      </div>
      <div className="flex justify-between">
        <p className='text-xs mt-2 font-bold text-slate-400 text-start'>Minimum 200.00 USD</p>
        <p className='text-xs mt-2 font-bold text-slate-400 text-start'>{amount} USD worth of {cryptoType}</p>
      </div>
      <button
        className={`w-full py-3 px-4 mt-3 text-base rounded ${amount >= 200 ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
        onClick={handleConfirm}
        disabled={amount < 200 || isLoading}
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </div>
        ) : (
          'Continue to Deposit'
        )}
      </button>
      <button
        onClick={() => navigate(-1)}
        className="mt-3 rounded text-red-500 p-2 hover:border-2 hover:border-red-300 text-sm hover:text-opacity-75"
      >
        Back to previous 
      </button>
    </div>
  );
};

export default EnterAmount;
