import React from 'react'
import SimpleBtn from '../Components/Buttons/SimpleBtn'
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CopyInput from '../Components/CopyText';
import { Helmet } from 'react-helmet-async';


const Home = ({data}) => {
  const withdrawalAccount = data.withdrawalAccount ?? [];
  const accountLength = withdrawalAccount.length;  
  
  return (
    <div className='p-3 bg-bgColor-600'>
      <Helmet><title>Dashboard</title></Helmet>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 lg:mx-5 mb-4">
        <div className='text-start '>
          <p className='text-base font-medium text-slate-400 my-2 capitalize'>welcome!</p>
          <p className='text-4xl font-semibold capitalize text-blue-950 mb-3'>{data.name}</p>
          <p className='text-base font-medium text-slate-500 my-2'>Here's a summary of your account. Have fun!</p>
        </div>
        <div className='flex items-center lg:justify-end justify-start'>
          <SimpleBtn text='invest & earn &rarr;' classname='bg-bgColor-200 text-slate-100 px-3 font-semibold text-sm capitalize py-2 rounded' link='/dashboard/user/plans'/>
          <SimpleBtn text='deposit &rarr;' classname='bg-green-400 text-white px-3 font-semibold text-sm capitalize py-2 rounded ml-4' link='/dashboard/user/deposit/wallet'/>
        </div>
      </div>
      <div className="lg:mx-5">
      {
        accountLength === 0 ? (
                <div className='flex flex-col bg-white md:flex-row justify-between items-center p-2 my-2 border-2 rounded border-yellow-400'>
        <div className='flex'>
          <p className='text-yellow-400 inline-block'><WarningIcon /></p>
          <p className='ml-4 text-slate-400 text-base text-start inline-block'>Add an account that you did like to receive payment or withdraw fund.</p>
        </div>
        <SimpleBtn text='add account' classname='bg-yellow-400 mt-1 rounded py-2 px-3 capitalize text-white font-semibold text-sm ' link={`/dashboard/user/profile/account`}/>
      </div>
        ) : (
          <div></div>
        )
      }
      {
        data.country && data.phone !== '' ? '' : (
          <div className='flex flex-col bg-white md:flex-row justify-between items-center p-2 my-2 border-2 rounded border-textColor-400'>
        <div className='flex'>
          <p className='text-green-400 inline-block'><InfoIcon /></p>
          <p className='ml-4 text-slate-400 inline-block text-base text-start'>Update your account information from your profile to complete account setup.</p>
        </div>
        <SimpleBtn text='Update Profile' classname='text-green-400 rounded py-2 px-3 capitalize font-semibold text-sm ' link={`/dashboard/user/profile`}/>
      </div>
        )
      }
      </div>
      <div className="grid grid-cols-1 my-5 bg-white md:grid-cols-2 lg:grid-cols-3 gap-4 lg:mx-5 ">
        <div className="border-b-4 border-t-2 border-x-2 rounded border-b-green-400 p-4">
            <div className='flex justify-between'>
              <h3 className='text-start text-base font-bold text-slate-600'>Available balance</h3>
              <InfoIcon style={{fontSize: 'small'}}/>
            </div>
            <h3 className="text-2xl my-3 text-neutral-800 font-medium uppercase text-start">{Number(data.balance).toFixed(2)} <span>USD</span></h3>
            <div className='mt-4'>
              <p className='text-start text-xs font-bold text-slate-400'>INVESTMENT ACCOUNT</p>
              <p className="text-base my-2 text-neutral-800 font-medium uppercase text-start">{Number(data.profit).toFixed(2)} <span>USD</span></p>
            </div>
        </div>
        <div className="border-b-4 border-t-2 border-x-2 rounded border-b-blue-400 p-4">
            <div className='flex justify-between'>
              <h3 className='text-start text-base bg-white font-bold text-slate-600'>Total Deposit</h3>
              <InfoIcon style={{fontSize: 'small'}}/>
            </div>
            <h3 className="text-2xl my-3 text-neutral-800 font-medium uppercase text-start">{Number(data.totalDep).toFixed(2)} <span>USD</span></h3>
            <div className='mt-4'>
              <p className='text-start text-xs font-bold text-slate-400'>This Month</p>
              <p className="text-base my-2 text-neutral-800 font-medium uppercase text-start">0 <span>USD</span></p>
            </div>
        </div>
        <div className="border-b-4 border-t-2 md:col-span-2 lg:col-span-1 border-x-2 rounded border-b-yellow-400 bg-white p-4">
            <div className='flex justify-between'>
              <h3 className='text-start text-base font-bold text-slate-600'>Total Withdraw</h3>
              <InfoIcon style={{fontSize: 'small'}}/>
            </div>
            <h3 className="text-2xl my-3 text-neutral-800 font-medium uppercase text-start">{Number(data.totalWit).toFixed(2)} <span>USD</span></h3>
            <div className='mt-4'>
              <p className='text-start text-xs font-bold text-slate-400'>This Month</p>
              <p className="text-base my-2 text-neutral-800 font-medium uppercase text-start">0 <span>USD</span></p>
            </div>
        </div>
      </div>

      <div className='bg-white p-4 lg:mx-4 border-2'>
        <h3 className='text-xl capitalize font-semibold text-start text-neutral-600'>refer us and earn</h3>
        <p className='text-start text-base my-2 font-semibold text-slate-500'>Use the below link to invite your friends.</p>

        <CopyInput />
      </div>
    </div>
  )
}

export default Home
