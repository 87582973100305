import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


const PaymentCancelled = () => {
  const location = useLocation();
  const { transactionId } = location.state || {};

  return (
    <div className="flex flex-col items-center justify-center bgh p-4">
      <div className="  max-w-lg w-full text-center">
        <div className="text-red-500 mb-4">
          <span className="bg-red-500 rounded-full text-white p-4"><CloseIcon /></span>
        </div>
        <h1 className="text-2xl font-semibold mb-4">Oops, payment cancelled!</h1>
        <p className="text-gray-700 mb-4">
          You have cancelled your payment via Crypto Wallet. Don't worry, you can make payment any time to deposit your account.
        </p>
        {transactionId && (
          <p className="text-gray-500 mb-6">Transaction ID {transactionId}</p>
        )}
        <div className="flex flex-col items-center space-y-4">
          <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"><Link to={`/dashboard/user/deposit/wallet`}>Deposit More</Link></button>
          <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"><Link to='/dashboard'>Go back to Dashboard</Link></button>
        </div>
        <p className="mt-4 text-gray-600">
          Please do not hesitate to <Link to="/contact" className="text-blue-500 hover:underline">contact us</Link> if you have any questions.
        </p>
      </div>
    </div>
  );
};

export default PaymentCancelled;
