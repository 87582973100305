import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { collection, getDocs, addDoc, getDoc,doc } from 'firebase/firestore';
import { db, auth } from '../firebase.config';
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

const ConfirmDeposit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount, cryptoType, wallet } = location.state;
  const [address, setAddress] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [walletInfo, setWalletInfo] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const walletCollection = collection(db, 'wallet');
      const walletSnapshot = await getDocs(walletCollection);
      const walletData = {};
      walletSnapshot.forEach(doc => {
        walletData[doc.id] = doc.data();
      });
      setWalletInfo(walletData);
      setAddress(walletData[cryptoType]?.address || '');  // Fetch the wallet address based on the crypto type
      setImageUrl(walletData[cryptoType]?.imageUrl || '');  // Fetch the wallet address based on the crypto type
    };

    fetchWalletInfo();
  }, [cryptoType]);

  const generateTransactionId = () => {
    return `TNX${Math.floor(10000000 + Math.random() * 90000000)}`;
  };

  const handleConfirm = async () => {
    setIsProcessing(true);
    setIsLoading(true);
  
    try {
      const transactionId = generateTransactionId();
      const depositTime = new Date();
      const paymentAmount = Number(amount);
      const creditedInAccount = paymentAmount;
    
      const user = auth.currentUser;
  
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        const currentBalance = userData.balance || 0;
        // Calculate updated balance
        const updatedBalance = Number(currentBalance + paymentAmount).toFixed(2);

        const userDetails = {
          depositTime,
          paymentAmount,
          creditedInAccount,
          details: `Deposit via ${cryptoType}`,
          paymentMethod: wallet,
          paymentTo: walletInfo[cryptoType]?.address || '',
          transactionId,
          status: 'pending',
          date: depositTime,
          updatedBalance
        };
    
        
  
        // Save deposit information to Firestore under the user's document
        await addDoc(collection(db, 'users', user.uid, 'deposit'), userDetails);
  
        const htmlContent = `
<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
      body {
        font-family: Arial, sans-serif;
        font-size: 16px;
        color: #333;
      }
      .container {
        width: 100%;
        padding: 20px;
        background-color: #f7f7f7;
      }
      .content {
        max-width: 600px;
        margin: 0 auto;
        background-color: #fff;
        border: 1px solid #ddd;
      }
      .header, .footer {
        padding: 20px;
        text-align: center;
        background-color: #f7f7f7;
      }
      .body-content {
        padding: 20px;
      }
      h2 {
        color: darkblue;
        padding: 10px;
        text-align: center;
      }
      p {
        margin: 0 0 10px;
      }
    </style>
    </head>
    <body>
      <div class="container">
        <div class="content">
          <div class="header">
            <h2>Hillview Housing</h2>
          </div>
          <div class="body-content">
            <p>Hello ${userData.name},</p>
            <p>Your deposit order has been placed and is now being processed. Your deposit details are shown below for your reference:</p>
            <p>
              <strong>Payment Reference:</strong> ${transactionId}<br>
              <strong>Payment Amount:</strong> $${amount} ${cryptoType}<br>
              <strong>Payment Method:</strong> ${wallet}<br>
              <strong>Amount To Credit:</strong> ${amount} USD
            </p>
            <p>If you have not made the payment yet, please send the amount of ${amount} USD worth of ${cryptoType} to the following address: ${address}</p>
            <p>Your funds will be added to your account once payment is confirmed.</p>
            <p>Feel free to contact us if you have any questions.</p>
          </div>
          <div class="footer">
            Hillview Housing &copy; 2024
          </div>
        </div>
      </div>
    </body>
    </html>

      `;

      const plainText = `
      Hello ${userData.name},
      
      Your deposit order has been placed and is now being processed. Your deposit details are shown below for your reference:
      
      Payment Reference: ${transactionId}
      Payment Amount: $${amount} ${cryptoType}
      Payment Method: ${wallet}
      Amount To Credit: ${amount} USD
      
      If you have not made the payment yet, please send the amount of ${amount} USD worth of ${cryptoType} to the following address: ${address}
      
      Your funds will be added to your account once payment is confirmed.
      
      Feel free to contact us if you have any questions.
      
      Hillview Housing © 2024
      `;
      
      // Navigate to the dashboard with state
      navigate('/dashboard/user/deposit/details', {
        state: {
          wallet,
          address: walletInfo[cryptoType]?.address || '',
          imageUrl,
          amount,
          cryptoType,
          transactionId
        }
      });
      
      // Send email using Axios
      await axios.post("https://nodemailer-theta.vercel.app/send-email", {
        email: user.email,
        subject: `New Deposit ${transactionId}`,
        htmlContent: htmlContent,
        plainText: plainText
      });
      
  
        // Navigate to the deposit details page
        
      } else {
        console.error("User is not authenticated.");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      // Always set loading and processing states to false
      setIsLoading(false);
      setIsProcessing(false);
    }
  };
  

  const handleCancel = () => {
    const transactionId = generateTransactionId();
    setIsLoading(true);
    setTimeout(() => {
      navigate('/dashboard/user/deposit/cancel', {
        state: { transactionId }
      });
    }, 3000);
  };

  return (
    <div className='mx-auto max-w-xl text-slate-600 bgh p-3'>
      <Helmet><title>Confirm-deposit</title></Helmet>
      <div className='flex justify-center items-center py-4'>
        <div className='text-gray-300 '><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-green-500 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
        <div className='text-gray-300 ml-2'><FiberManualRecordIcon style={{ fontSize: 'small' }} /></div>
      </div>
      <h2 className="text-3xl font-semibold mb-4">Confirm Your Deposit</h2>
      <p className="text-base">You are about to deposit <span className='font-semibold'>{amount} USD worth of {cryptoType}</span> in your account.</p>
      <p className='text-xs mb-4 text-slate-500 mt-1'>Please review the information and confirm.</p>
      <div className="border-2 bg-white rounded">
        <div className="flex justify-between px-3 py-2 border-b-2">
          <span>Payment method:</span>
          <span className='font-semibold'><AccountBalanceWalletIcon /> {wallet}</span>
        </div>
        <div className="flex justify-between px-3 py-2">
          <span>You will send:</span>
          <span className='font-semibold'>{Number(amount).toFixed(2)} USD</span>
        </div>
        <div className="flex justify-between text-sm pb-2 px-3">
          <span>Charge:</span>
          <span>{Number(0).toFixed(2)} USD</span>
        </div>
      </div>
      <div className="flex justify-between bg-white rounded font-bold p-3 mt-3 border-2">
        <span>Amount to deposit</span>
        <span className='font-bold'>{Number(amount).toFixed(2)} USD</span>
      </div>
      <p className='text-xs mt-2 mb-4 text-slate-500 text-start font-medium'>* Payment info ({cryptoType} wallet) will be available once you proceed.</p>
      
      <button
        className={`w-full py-3 px-4 mt-3 text-base rounded ${amount >= 200 ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
        onClick={handleConfirm}
        disabled={amount < 200 || isLoading || isProcessing}  // Disable when processing or loading
      >
        {isLoading || isProcessing ? (
          <div className="flex items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </div>
        ) : (
          'Continue to Deposit'
        )}
      </button>

      <button
        onClick={handleCancel}
        className="mt-3 rounded text-red-500 p-2 hover:border-2 hover:border-red-300 text-sm hover:text-opacity-75"
      >
        Cancel Order
      </button>
    </div>
  );
};

export default ConfirmDeposit;
