import React from 'react';

const AccountList = ({ accounts }) => {
  return (
    <div className="my-4 text-start">
      {accounts.map((account, index) => (
        <div key={index} className="grid grid-cols-1 md:grid-cols-3 px-3 py-4 border-2 bg-white rounded-lg">
          <p className='text-sm text-slate-400 font-semibold'>Crypto Wallet (<span>{account.cryptoType}</span>)</p>
          <span className='md:col-span-2'>{account.cryptoWallet}</span>
        </div>
      ))}
    </div>
  );
};

export default AccountList;
