import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
  const navigate = useNavigate();

  const handleReturnToLogin = () => {
    navigate('/auth/login');
  };

  return (
    <div className="flex flex-col max-w-lg mx-auto items-center justify-center min-h-screen p-4">
       <Helmet><title>welcome</title></Helmet>
      <h1 className="text-3xl font-bold mb-4">Registration Completed Successfully</h1>
      <p className="mb-2">Thank you for signing up!</p>
      <p className="mb-6">
        We just need you to verify your email. Please check your inbox, including the spam folder for a verification mail.
      </p>
      <button 
        onClick={handleReturnToLogin}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      >
        Return to Login
      </button>
    </div>
  );
};

export default WelcomePage;
