import React from 'react';
import { Link } from 'react-router-dom';

const PropertiesCard = ({ property }) => {
  return (
    <div className="relative group overflow-hidden max-h-72">
      <img src={property.images[0]} alt={property.name} className="w-full h-full object-cover" />

      <div className="absolute text-white bottom-0 flex items-center flex-col justify-center left-0 w-full h-full bg-black bg-opacity-50 transition-transform duration-700 group-hover:translate-y-[-20%] p-2">
        <h2 className="text-2xl md:text-3xl text-start self-start font-semibold">{property.name}</h2>
        <span className="mt-2 border-1 self-start border-accent1 rounded-full text-white py-2 px-2 cursor-default">{property.price} - Rent</span>
        <Link to={`/property/${property.id}`} className="block self-start mt-2">Click here to view</Link>
      </div>

      <div className="absolute bottom-0 left-0 w-full bg-accent1 bg-opacity-100 transform translate-y-full group-hover:translate-y-0 transition-transform duration-700 ease-in-out flex flex-col justify-center items-center text-black">
        <div className="grid grid-cols-3 w-full p-2">
          <div className="flex flex-col">
            <span>Beds</span>
            <span>{property.beds}</span>
          </div>
          <div className="flex flex-col">
            <span>Baths</span>
            <span>{property.baths}</span>
          </div>
          <div className="flex flex-col">
            <span>Location</span>
            <span>{property.location}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertiesCard;
