import LockIcon from '@mui/icons-material/Lock';
import PsychologyIcon from '@mui/icons-material/Psychology';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ConstructionIcon from '@mui/icons-material/Construction';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';



const quality = [
    {
        text: 'We respect and keep our users data private. We guarantee data privacy.',
        header: 'Data Privacy',
        icon: <LockIcon   className='transform -rotate-45'/>
    },
    {
        text: 'Discipline is the key to success in investment. We employ discipline in our working process.',
        header: 'Discipline',
        icon: <PsychologyIcon   className='transform -rotate-45'/>
    },
    {
        text: 'Growth defines success. We improve our process and methods to enhance growth.',
        header: 'Steady Growth',
        icon: <MilitaryTechIcon   className='transform -rotate-45'/>
    },
    {
        text: 'A flexible working system is a balanced system. In order to maximise profit, we use a flexible working process to achieve success.',
        header: 'Flexibility',
        icon: <ConstructionIcon   className='transform -rotate-45'/>
    },
    {
        text: 'We employ a dynamic working process to maximise profit.',
        header: 'Dynamic',
        icon: < MoneyOffCsredIcon  className='transform -rotate-45'/>
    },
]

export default quality