import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate} from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import logo from '../Assets/Images/brand.png';
import GridViewIcon from '@mui/icons-material/GridView';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShareIcon from '@mui/icons-material/Share';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import './Dashboard.css'; 
import '../Components/Header/Header.css';
import Investments from './Investments';
import Profile from './Profile';
import Home from './Home'; // Assuming you have a Home component
import Transaction from './Transaction';
import Referrals from './Referrals';
import Plans from './Plans';
import SimpleBtn from '../Components/Buttons/SimpleBtn';
import Header from './Header';
import Footer from './Footer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import NoFundsMessage from './Components/NoFundMessage';
import ConfirmationModal from './Components/ConfirmationModel';
import ConfirmProceed from './Components/ConfirmProceed';
import InvestmentDetails from './Components/InvestmentDetails';
import EnterAmount from './Components/EnterAmount';
import WalletSelection from './Components/WalletSelection';
import ConfirmDeposit from './ConfirmDeposit';
import PaymentCancelled from './Components/CancelOrder';
import PaymentDetails from './Components/PaymentDetails';
import DepositSlip from './Components/DepositSlip';
import PendingSlip from './Components/PendingSlip';
import AddAccountForm from './Components/AddAccountForm';
import ChangeEmail from './Components/ChangeEmail';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChangePassword from './Components/ChangePassword';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ConstructionIcon from '@mui/icons-material/Construction';
import Withdrawal from './Withdrawal';
import WithdrawalSlip from './Components/WithdrawalSlip';
import Transfer from './Transfer';
import UpdateProfile from './Components/Updateprofile';
import TermsAndConditions from './TermsAndConditions';
import FAQ from './Faq';
import { Helmet } from 'react-helmet-async';
import Accounts from './Components/Accounts'


const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [activeLink, setActiveLink] = useState('/dashboard');
  const [accounts, setAccounts] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});
  const [deposit, setDeposit] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [investment, setInvestment] = useState([]);
  const [loading, setLoading] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState()
  const [totalReturn, setTotalReturn] = useState()
  const [dayReturn, setDayReturn] = useState()
  

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  };


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleNavigation = (path, section) => {
    closeDropdown();
    navigate(`${path}?section=${section}`);
  };

  useEffect(() => {
    setLoading(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const getData = async () => {
          try {
            const ref = doc(db, 'users', user.uid);
            const docSnap = await getDoc(ref);
            if (docSnap.exists()) {
              setCurrentUser(docSnap.data());
            }
  
            const depositCol = collection(db, 'users', user.uid, 'deposit');
            const depositSnapshot = await getDocs(depositCol);
            const fetcheDeposit = [];
  
            const withdrawCol = collection(db, 'users', user.uid, 'withdraw');
            const withdrawSnapshot = await getDocs(withdrawCol);
            const fetcheWithdraw = [];
  
            const investmentCol = collection(db, 'users', user.uid, 'investment');
            const investmentSnapshot = await getDocs(investmentCol);
            const fetcheinvestment = [];
  
            depositSnapshot.forEach((doc) => {
              fetcheDeposit.push({
                id: doc.id,
                ...doc.data()
              });
            });
            withdrawSnapshot.forEach((doc) => {
              fetcheWithdraw.push({
                id: doc.id,
                ...doc.data()
              });
            });
            investmentSnapshot.forEach((doc) => {
              fetcheinvestment.push({
                id: doc.id,
                ...doc.data()
              });
            });
  
            setDeposit(fetcheDeposit);
            setInvestment(fetcheinvestment);
            setWithdraw(fetcheWithdraw);
  
            // Filter investments with status "running" and sum up the investment.paymentAmount
            const runningInvestments = fetcheinvestment.filter(investment => investment.status === 'running');
            const totalPaymentAmount = runningInvestments.reduce((sum, investment) => {
              const paymentAmount = investment.investmentAmount || 0;
              console.log(`Payment Amount: ${paymentAmount}`);
              return sum + paymentAmount;
            }, 0);
            
            // Calculate total return for running investments
            const totalReturn = runningInvestments.reduce((sum, investment) => {
              const investmentAmount = investment.investmentAmount || 0;
              const dailyInterest = investment.dailyInterest || 0;
              const termDays = investment.termDays || 0;
  
              const dailyProfit = investmentAmount * (dailyInterest / 100);
              const totalProfit = dailyProfit * termDays;
  
  
              return sum + totalProfit;
            }, 0);

            const Return = runningInvestments.reduce((sum, investment) => {
              const investmentAmount = investment.investmentAmount || 0;
              const dailyInterest = investment.dailyInterest || 0;
            
              const dailyProfit = investmentAmount * (dailyInterest / 100);
              const totalProfit = dailyProfit * investment.adjustProfit.daysPassed;
              const totalReturn = Number((totalProfit).toFixed(2));
            
              return sum + totalReturn;
            }, 0);
            

            
  
            setTotalPaymentAmount(totalPaymentAmount);
            setTotalReturn(totalReturn);
            setDayReturn(Return);
  
            setLoading(false);
          } catch (error) {
            console.log(error.message);
          }
        };
        getData();
      }
    });
  }, []);
  
  

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setShow(false);
  };

  useEffect(() => {
    // Set initial active link on page load
    setActiveLink(window.location.pathname);
  }, []);

  const activeClass = 'text-left rounded-full py-2 px-3 hover:bg-bgColor-500 bg-bgColor-500 transition text-textColor-400 text-sm font-semibold capitalize w-100 my-2';
  const normalClass = 'text-left rounded-full py-2 pl-3 hover:bg-bgColor-500 transition text-slate-300 text-sm font-medium capitalize w-100 hover:text-textColor-400';

  return (
    <Container fluid>
      <Helmet><title>Dashboard</title></Helmet>
      <Row className="flex-nowrap ">
        <Col as="aside" className="sidebar w-80 col-auto col-md-3  col-xl-3 px-sm-4 bg-bgColor-200 d-none d-lg-flex flex-column pb-4 align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 fixed top-0 left-0 overflow-y-scroll ">
          <Link to="/" className="d-flex align-items-center justify-center pb-3 mb-md-0 me-md-auto text-decoration-none">
            <img src={logo} alt="dashboard" className="w-20 rounded mt-3 ml-4 bg-slate-200" />
          </Link>
          <Nav className="flex-column w-full mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <div className="wallet my-3 w-full">
              <h2 className='text-slate-400 font-semibold pl-4 text-xs text-start uppercase'>Main account balance</h2>
              <p className="text-green-500 pl-4 my-1 text-start font-semibold text-2xl">{currentUser.balance} <span className='text-xl font-normal'>USD</span></p>
              <p className="text-textColor-400 pl-4 text-start font-semibold text-base">0.00 <span className='text-sm font-normal'>EUR</span></p>
              <div className="flex text-xs text-slate-300 pe-5 font-semibold w-full justify-between items-center mt-3 pl-4">
                <p>profit <span>(7days)</span></p>
                <p className='text-slate-200 font-semibold text-lg'>{currentUser.profit} <span className='text-sm text-slate-300 uppercase font-medium'>usd</span></p>
              </div>
              <div className="flex pl-4 my-2 uppercase font-semibold ">
                <SimpleBtn text='Deposit' link='/dashboard/user/deposit/wallet' classname='px-4 py-1 bg-green-400 rounded-lg'/>
                <SimpleBtn text='Withdraw' link='/dashboard/user/withdraw' classname='px-4 py-1 bg-yellow-400 rounded-lg ml-4'/>
              </div>
            </div>
            <h4 className='text-sm font-semibold pl-4 text-slate-300'>Menu</h4>
            <Nav.Item className='w-full'>
              <Link to="/dashboard" className="nav-link" onClick={() => handleNavLinkClick('/dashboard')}>
                <button className={activeLink === '/dashboard' ? activeClass : normalClass}>
                  <span><GridViewIcon /></span> <span className="ms-1 d-none d-sm-inline">Dashboard</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item className='w-full '>
              <Link to="/dashboard/user/transactions" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/transactions')}>
                <button className={activeLink === '/dashboard/user/transactions' ? activeClass : normalClass}>
                  <span><ImportExportIcon /></span> <span className="ms-1 d-none d-sm-inline">Transactions</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item className='w-full'>
              <Link to="/dashboard/user/investments" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/investments')}>
                <button className={activeLink === '/dashboard/user/investments' ? activeClass : normalClass}>
                <span><CurrencyExchangeIcon /></span> <span className="ms-1 d-none d-sm-inline">Investments</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/dashboard/user/plans" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/plans')}>
                <button className={activeLink === '/dashboard/user/plans' ? activeClass : normalClass}>
                <span><ReceiptLongIcon /></span> <span className="ms-1 d-none d-sm-inline">our plans</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/dashboard/user/profile" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/profile')}>
                <button className={activeLink === '/dashboard/user/profile' ? activeClass : normalClass}>
                <span><ManageAccountsIcon /></span> <span className="ms-1 d-none d-sm-inline">my profile</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/dashboard/user/referrals" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/referrals')}>
                <button className={activeLink === '/dashboard/user/referrals' ? activeClass : normalClass}>
                <span><ShareIcon /></span> <span className="ms-1 d-none d-sm-inline">Referrals</span>
                </button>
              </Link>
            </Nav.Item>
          </Nav>
          <hr className="text-slate-300 w-100" />
          <div className="dropdown pb-4 pl-4 mb-3">
            <h4 className='text-start text-xs my-2 uppercase font-bold pt-2 text-slate-300'>Additional</h4>
            <Link to={`/`} className='text-start text-sm capitalize font-bold pt-2 text-slate-400' >go to home</Link>
          
          </div>
        </Col>
        <Col className="pb-3 px-0">
          <Navbar  expand={false} className="mb-3 w-full d-lg-none">
            <Container fluid className="d-flex justify-content-between px-4">
              <div className='flex items-center justify-start'>
              <div onClick={handleShow} className={`navbar-toggler ${show ? '' : 'collapsed'}`}>
                <span className='toggler-icon top-bar'></span>
                <span className='toggler-icon mid-bar'></span>
                <span className='toggler-icon bottom-bar'></span>
              </div>
              <Link to="/">
                <img className='w-20 h-10 object-contain ' src={logo} alt="dashboard" />
              </Link>
              </div>
              <div className=' flex justify-end items-center  '>
              <span className='text-green-400 cursor-pointer' onClick={toggleDropdown}><AccountCircleIcon   /></span>
      <div className='flex items-baseline' onClick={toggleDropdown}>
      <div className='ml-3 relative text-slate-500 cursor-pointer' onClick={toggleDropdown}>
        <p className='text-green-400 font-semibold text-xs text-start'>verified</p>
        <p className='text-slate-500 font-bold capitalize text-start text-sm'>{currentUser.name} <span className=""><KeyboardArrowDownIcon  style={{fontSize: 'medium'}}/></span></p>
      </div>
      
      </div>
      {isOpen && (
        <div
          className=" absolute right-4 top-16 mt-2 min-w-72 border-t-4 border-green-500  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="" role="none">
          <div className="px-4 py-3 text-sm border-b-2 text-white flex bg-slate-100">
              <div className="font-bold text-white mr-3 bg-red-400 p-3 rounded-full">{getInitials(currentUser.name)}</div>
              <div>
              <div className="text-base font-semibold text-green-500">{currentUser.name}</div>
              <div className="text-sm text-gray-500">{currentUser.email}</div>
              </div>
            </div>
            <div className="px-4 py-2 text-sm text-gray-700" role="menuitem">
              <div className="font-bold">Account Balance</div>
              <div className="text-lg text-green-500">{currentUser.balance} USD</div>
              <div className="text-sm text-gray-500">0.00 EUR</div>
            </div>
            <div className="border-t border-gray-100"></div>
            <Link
              to='/dashboard/user/deposit/wallet'
              className="block px-4 py-1 text-sm text-green-500 hover:bg-green-300"
              role="menuitem"
              onClick={closeDropdown}
            >
              Deposit Funds <span className='text-green-500 ml-2'><CreditCardIcon style={{fontSize: 'medium'}}/></span>
            </Link>
            <Link
              to='/dashboard/user/withdraw/'
              className="block mb-2 px-4 py-1 text-sm text-green-500 hover:bg-green-300"
              role="menuitem"
              onClick={closeDropdown}
            >
              Withdraw Funds <span className='text-green-500 ml-2'><CreditScoreIcon style={{fontSize: 'medium'}}/></span>
            </Link>
            <div className="border-t mb-3 border-gray-100"></div>
            <div
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => handleNavigation('/dashboard/user/profile', 'profile')}
            >
              <span className="text-slate-400 mr-2"><PersonOutlineIcon style={{ fontSize: 'medium' }} /></span> View Profile
            </div>
            <div
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => handleNavigation('/dashboard/user/profile', 'security')}
            >
              <span className="text-slate-400 mr-2"><SettingsIcon style={{ fontSize: 'medium' }} /></span> Security Settings
            </div>
            <div
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              role="menuitem"
              onClick={() => handleNavigation('/dashboard/user/profile', 'activity')}
            >
              <span className="text-slate-400 mr-2"><ConstructionIcon style={{ fontSize: 'medium' }} /></span> Login Activity
            </div>
            <Link
              to='/logout'
              className="block px-4 mb-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={closeDropdown}
            >
              <span className="text-slate-400 mr-2"><LogoutIcon style={{ fontSize: 'medium' }} /></span> Sign out
            </Link>
          </div>
        </div>
      )}
              </div>
            </Container>
          </Navbar>

          <Offcanvas show={show} onHide={handleClose} className="d-lg-none custom-offcanvas">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <Link to='/' onClick={handleNavLinkClick}>
                  <img className="w-20 rounded mt-3 ml-4 bg-slate-200" src={logo} alt="dashboard" />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="flex-column">
              <Nav.Item className='w-full'>
              <Link to="/dashboard" className="nav-link" onClick={() => handleNavLinkClick('/dashboard')}>
                <button className={activeLink === '/dashboard' ? activeClass : normalClass}>
                  <span><GridViewIcon /></span> <span className="">Dashboard</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item className='w-full '>
              <Link to="/dashboard/user/transactions" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/transactions')}>
                <button className={activeLink === '/dashboard/user/transactions' ? activeClass : normalClass}>
                  <span><ImportExportIcon /></span> <span className="ms-1">Transactions</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item className='w-full'>
              <Link to="/dashboard/user/investments" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/investments')}>
                <button className={activeLink === '/dashboard/user/investments' ? activeClass : normalClass}>
                <span><CurrencyExchangeIcon /></span> <span className="ms-1 ">Investments</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/dashboard/user/plans" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/plans')}>
                <button className={activeLink === '/dashboard/user/plans' ? activeClass : normalClass}>
                <span><ReceiptLongIcon /></span> <span className="ms-1 ">our plans</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/dashboard/user/profile" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/profile')}>
                <button className={activeLink === '/dashboard/user/profile' ? activeClass : normalClass}>
                <span><ManageAccountsIcon /></span> <span className="ms-1">my profile</span>
                </button>
              </Link>
            </Nav.Item>
            <Nav.Item  className='w-full'>
              <Link to="/dashboard/user/referrals" className="nav-link" onClick={() => handleNavLinkClick('/dashboard/user/referrals')}>
                <button className={activeLink === '/dashboard/user/referrals' ? activeClass : normalClass}>
                <span><ShareIcon /></span> <span className="ms-1">Referrals</span>
                </button>
              </Link>
            </Nav.Item>
            <div className="dropdown pb-4 pl-4 mb-3">
            <h4 className='text-start text-xs my-2 uppercase font-bold pt-2 text-slate-300'>Additional</h4>
            <Link className='text-start text-sm capitalize font-bold pt-2 text-slate-400' >go to home</Link>
          
          </div>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
          
          <div className='bg-bgColor-600 h-full md:ml-80 min-h-screen'>
          <Header currentUser={currentUser}/>
          <Routes>
            <Route path="/dashboard" element={<Home  data={currentUser}/>} />
            <Route path="/dashboard/user/investments" element={<Investments currentUser={currentUser} totalReturn={totalReturn} dayReturn={dayReturn} totalPaymentAmount={totalPaymentAmount}/>} />
            <Route path="/dashboard/user/profile" element={<Profile />} />
            <Route path="/dashboard/user/profile/account" element={<Accounts />} />
            <Route path="/dashboard/user/withdraw" element={<Withdrawal accounts={accounts} setAccounts={setAccounts}/>} />
            <Route path="/dashboard/user/transfer" element={<Transfer />} />
            <Route path="/dashboard/user/update" element={<UpdateProfile />} />
            <Route path="/dashboard/user/transactions" element={<Transaction />} />
            <Route path="/dashboard/user/referrals" element={<Referrals data={currentUser}/>} />
            <Route path="/dashboard/user/plans" element={<Plans  data={currentUser}/>} />
            <Route path="/dashboard/user/deposit/amount" element={<EnterAmount />} />
            <Route path="/dashboard/user/deposit/wallet" element={<WalletSelection />} />
            <Route path="/dashboard/user/deposit/confirm" element={<ConfirmDeposit/>} />
            <Route path="/dashboard/user/deposit/details" element={<PaymentDetails />} />
            <Route path="/dashboard/user/plans/no-funds" element={<NoFundsMessage />} />
            <Route path="/dashboard/user/deposit/cancel" element={<PaymentCancelled />} />
            <Route path="/dashboard/user/plans/confirm" element={<ConfirmationModal  data={currentUser}/>} />
            <Route path="/dashboard/user/plans/proceed" element={<ConfirmProceed /> } />
            <Route path="/dashboard/user/change-email" element={<ChangeEmail/> } />
            <Route path="/dashboard/user/change-password" element={<ChangePassword/> } />
            <Route path="/dashboard/user/account/add" element={<AddAccountForm accounts={accounts} setAccounts={setAccounts} />} />
            <Route path="/dashboard/user/investment/:investmentId" element={<InvestmentDetails />} />
            <Route path="/dashboard/user/deposit/:depositId" element={<DepositSlip />} />
            <Route path="/dashboard/user/withdraw/:withdrawId" element={<WithdrawalSlip />} />
            <Route path="/dashboard/user/deposit/pending/:depositId" element={<PendingSlip />} />
            <Route path="/dashboard/user/terms" element={<TermsAndConditions />} />
            <Route path="/dashboard/user/faq" element={<FAQ />} />

          </Routes>
          <Footer />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;