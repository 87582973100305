import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase.config';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const AddAccountForm = ({ accounts, setAccounts }) => {
  const [cryptoWallet, setCryptoWallet] = useState('');
  const [cryptoType, setCryptoType] = useState('BTC');
  const [network, setNetwork] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleAddAccount = async () => {
    setLoading(true);
    if (user) {
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);
      let existingAccounts = [];
      if (docSnap.exists()) {
        existingAccounts = docSnap.data().withdrawalAccount || [];
      }
      const newAccount = { cryptoWallet, cryptoType, network };
      const updatedAccounts = [...existingAccounts, newAccount];

      await updateDoc(docRef, {
        withdrawalAccount: updatedAccounts
      });

      setAccounts(updatedAccounts);
      setLoading(false);
      navigate(-1);
    }
  };

  return (
    <div className="bgh">
      <div className="py-4 relative mt-5 max-w-xl mx-auto bg-white rounded shadow-sm  text-start text-slate-600">
      <button
          onClick={() => navigate(-1)}
          className="absolute top-4 right-4 text-slate-500 hover:text-opacity-75"
        >
          <CloseIcon />
        </button>
      <div className="border-b-2 px-4 py-4">
      <h2 className="text-2xl font-semibold mb-1">Add Crypto Wallet Address</h2>
      <p className='text-base text-slate-400 font-semibold'>Add your Crypto wallet address to withdraw your funds.</p>
      </div>
     <div className="grid grid-cols-1 md:grid-cols-4 gap-2 px-4 pt-4 ">
     <div className="mb-2  col-span-3">
        <label className="block mb-1 text-slate-600 font-medium">Crypto Wallet Address <span className='text-red-500'>*</span></label>
        <input
          type="text"
          placeholder='Enter Crypto wallet Address'
          className="border px-2 py-2 w-full"
          value={cryptoWallet}
          onChange={(e) => setCryptoWallet(e.target.value)}
          disabled={loading}
        />
      </div>
      <div className="mb-2">
        <label className="block mb-1 text-slate-600 font-medium">Currency</label>
        <select
          className="border px-2 py-2 w-full"
          value={cryptoType}
          onChange={(e) => setCryptoType(e.target.value)}
          disabled={loading}
        >
          <option value="BTC">BTC</option>
          <option value="ETH">ETH</option>
          <option value="USDT">USDT</option>
          <option value="XRP">XRP</option>
        </select>
      </div>
      
     </div>
     <p className='text-sm mx-4 text-slate-400'>You will receive payment on this account in selected currency.</p>
      <div className="mb-4 p-4">
        <label className="block mb-1 text-slate-600 font-medium">Network type (Optional)</label>
        <input
          type="text"
          className="border px-2 py-2 w-full"
          value={network}
          placeholder='eg. Crypto Network adress'
          onChange={(e) => setNetwork(e.target.value)}
          disabled={loading}
        />
      </div>
      <div className="px-4">
      <button
        className={`bg-blue-500 text-white px-4 py-2 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleAddAccount}
        disabled={loading}
      >
        {loading ? (
          <div className="flex items-center justify-center">
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span className="ml-2">Adding...</span>
          </div>
        ) : (
          'Add Wallet'
        )}
      </button>
      </div>
    </div>
    </div>
  );
};

export default AddAccountForm;
