import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const SecuritySettings = () => {
  const navigate = useNavigate();
  const [saveActivityLogs, setSaveActivityLogs] = useState(true);
  const [emailUnusualActivity, setEmailUnusualActivity] = useState(true);

  const handleToggle = (setter, value) => {
    setter(!value);
  };

  return (
    <div className="  text-slate-400">
      <Helmet><title>security</title></Helmet>
      <h2 className="text-xl text-slate-600 font-bold mb-1">Settings</h2>
      <p className='text-sm mb-4'>These settings will helps you keep your account secure.</p>
      <div className="bg-white p-4 border-2">
        <div className="flex items-center justify-between mb-2">
          <span className="text-lg font-medium">Save my Activity Logs</span>
          <label className="inline-flex items-center cursor-pointer">
            <input 
              type="checkbox" 
              className="sr-only peer" 
              checked={saveActivityLogs} 
              onChange={() => handleToggle(setSaveActivityLogs, saveActivityLogs)} 
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
          </label>
        </div>
        <p className="text-gray-500 text-sm">Save all activity logs including unusual activity detected.</p>
      </div>

      <div className="bg-white p-4 border-2">
        <div className="flex items-center justify-between mb-2">
          <span className="text-lg font-medium">Email me if encounter unusual activity</span>
          <label className="inline-flex items-center cursor-pointer">
            <input 
              type="checkbox" 
              className="sr-only peer" 
              checked={emailUnusualActivity} 
              onChange={() => handleToggle(setEmailUnusualActivity, emailUnusualActivity)} 
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
          </label>
        </div>
        <p className="text-gray-500 text-sm">Get email notifications whenever unusual login activity is detected.</p>
      </div>

      <div className="bg-white p-4 border-2">
        <div className="flex justify-between items-center mb-2">
          <span className="text-lg font-medium">Change Email Address</span>
          <button 
            className="bg-green-500 text-white px-4 py-2 rounded cursor-not-allowed opacity-50"
            disabled
          >
            Change Email
          </button>
        </div>
        <p className="text-gray-500 text-sm">Update your current email address to a new email address.</p>
      </div>

      <div className="bg-white p-4 border-2">
        <div className="flex justify-between items-center mb-2">
          <span className="text-lg font-medium">Change Password</span>
          <button 
            onClick={() => navigate('/dashboard/user/change-password')} 
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400 transition-colors duration-300"
          >
            Change Password
          </button>
        </div>
        <p className="text-gray-500 text-sm">Set a unique password to protect your account.</p>
      </div>

      <div className="bg-white p-4 border-2">
        <div className="flex justify-between items-center mb-2">
          <span className="text-lg font-medium">2FA Authentication</span>
          <button 
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-400 transition-colors duration-300 cursor-not-allowed opacity-50" disabled
          >
            Disabled
          </button>
        </div>
        <p className="text-gray-500 text-sm">Secure your account with 2FA security. Once activated, you'll need to enter a special code in addition to your password.</p>
      </div>
    </div>
  );
};

export default SecuritySettings;
